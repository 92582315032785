<div class="table-row-primary-menu-button" [class.expanded]="isMenuExpanded">

  <div class="menu-button">
    <button (click)="onExpandMenuClicked($event)">
      <span>{{title}}</span>
      <mat-icon *ngIf="!isMenuExpanded">expand_more</mat-icon>
      <mat-icon *ngIf="isMenuExpanded">expand_less</mat-icon>
    </button>

    <ul>
      <li *ngFor="let i of options" (click)="onButtonClicked($event, i.id)">
        <p>{{i.text}}</p>
      </li>
    </ul>
  </div>

</div>
