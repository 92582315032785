export class RaetTemplateOm {
  id: number;
  raetName: string;
  name: string;
  description: string;
  isActive: boolean;
  creationDate: Date;
  createdBy: string;
  lastModificationDate?: Date;
  lastModifiedBy?: string;
  thumbnail: string;
}
