import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateATemplateCmdlet4Content } from 'src/app/base-templates/cmdlets/UpdateATemplateCmdlet4Content';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { AlertService } from 'src/app/common/services/AlertService';
import { ProcessingStorage } from 'src/app/common/services/ProcessingStorage';
import { AlertTypeEnum } from 'src/app/common/types/enums/alertType.enum';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { GetTemplateBlobCmdlet2 } from 'src/app/templates/cmdlets/GetTemplateBlobCmdlet2';
import { GetTemplateContentCmdlet } from 'src/app/templates/cmdlets/GetTemplateContentCmdlet';
import TemplateEditorClient from 'src/app/templates/services/TemplateEditorClient';
import { DataDefinitionDm } from 'src/app/templates/types/DataDefinitionDm';
import { GetRaetTemplateMetaCmdlet } from '../../cmdlets/GetRaetTemplateMetaCmdlet';
import { SessionService } from 'src/app/common/api-clients/session.service';

declare const TXTextControl: any;
declare const loadDocument: any;

@Component({
  selector: 'app-raet-template-editor',
  templateUrl: './raet-template-editor.component.html',
  styleUrl: './raet-template-editor.component.scss'
})
export class RaetTemplateEditorComponent implements OnInit, AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private getTemplateBlobCmdlet2: GetTemplateBlobCmdlet2,
    private localStorage: ProcessingStorage,
    private sessionService: SessionService,
    private templateEditorClient: TemplateEditorClient,
    private getTemplateFileCmdlet: GetTemplateContentCmdlet,
    private getRaetTemplateMetaCmdlet: GetRaetTemplateMetaCmdlet,  
    private updateATemplateCmdlet4Content: UpdateATemplateCmdlet4Content,
    public componentContext: ComponentContext,
    private httpClient: HttpClient,
    private router: Router,
    private alertService: AlertService) { }

  templateContentDto: TemplateEditorFileModel;
  templateBlob: Blob;
  isLoaded: boolean = false;
  showCustomDialog: boolean = false;
  mergeDataSpecification: DataDefinitionDm;
  content: any;
  templateName: string;
  raetTemplateId: number;
  myCulture: string;
  ribbonsLoaded: boolean = false;

  @ViewChild('documentEditorContainer', { static: false }) documentEditorContainer!: ElementRef;

  @HostListener('document:txDocumentEditorLoaded', ['$event'])
  onTxDocumentEditorLoaded() {
    var self = this;

    TXTextControl.addEventListener("ribbonTabsLoaded", function () {
      self.setStyling();
      self.ribbonsLoaded = true;
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeDocumentEditor();
  }

  async ngOnInit(): Promise<void> {

    this.myCulture = this.sessionService.session.culture;
    
    this.route.paramMap.subscribe(async params => {
      
      this.raetTemplateId = Number(params.get('raetTemplateId'));
      this.templateBlob = await this.getTemplateBlobCmdlet2.execute(this.raetTemplateId);
      const raetTemplate = await this.getRaetTemplateMetaCmdlet.execute(this.raetTemplateId);      
      this.templateName = raetTemplate.raetName;

      this.loadDocument(this.templateBlob);
    });    
  }

  ngAfterViewInit() {        
    this.resizeDocumentEditor();    
  }

  blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      callback((<string>reader.result).split(',')[1]);
    };
    reader.readAsDataURL(blob);
  }

  loadDocument(document) {
    TXTextControl.addEventListener("textControlLoaded", () => {
      this.blobToBase64(document, (encoded) => {
        TXTextControl.loadDocument(TXTextControl.StreamType.InternalUnicodeFormat, encoded);
      });
    });
  }

  showAlert(type: AlertTypeEnum) {
    this.alertService.setAlert({
      type,
      text: 'Template succesvol opgeslagen!',
    });
  }

  onCompleteTemplateClicked($event) {
    this.onSaveTemplateClicked($event);
    this.router.navigate(['/raet-template-details']);
  }

  async onSaveTemplateClicked($event) {
    console.log('onsaveTemplateClicked');
    const self = this;
    let templateId = this.localStorage.raetTemplate.id;

    return new Promise((resolve, reject) => {
      TXTextControl.saveDocument(TXTextControl.StreamType.InternalUnicodeFormat, async function (e) {
        if (e) {

          resolve(e.data);
          let templateEditorFileModel = new TemplateEditorFileModel();
          templateEditorFileModel.id = templateId;
          templateEditorFileModel.content = e.data
          templateEditorFileModel.name = 'test';
          templateEditorFileModel.description = 'testDesc';
          console.log(templateEditorFileModel);
          await self.saveDocument(templateEditorFileModel)

          //alert('Document succesvol opgeslagen');
        } else {
          reject('Error');
        }
      });
    });

  }

  async saveDocument(templateEditorFileModel) {
    await this.updateATemplateCmdlet4Content.execute(templateEditorFileModel);
    this.showAlert(AlertTypeEnum.success)
  }

  onCancelTemplateClicked($event) {
    this.router.navigate(['/raet-template-details']);
  }

  async onClearTemplateClicked($event) {
    window.location.reload();
  }

  redirect() {
    this.router.navigate(['/raet-template-details']);
  }

  setStyling() {
    let tabProofing = document.getElementById('tabProofing') as HTMLElement;
    tabProofing?.style.setProperty('display', 'none')

    let tabPermissions = document.getElementById('tabPermissions') as HTMLElement;
    tabPermissions?.style.setProperty('display', 'none')

    let tabReferences = document.getElementById('tabReferences') as HTMLElement;
    tabReferences?.style.setProperty('display', 'none')

    let tabFormFields = document.getElementById('tabFormFields') as HTMLElement;
    tabFormFields?.style.setProperty('display', 'none')

    let txTglBtnSideBars = document.getElementById('txTglBtnSideBars') as HTMLElement;
    txTglBtnSideBars?.style.setProperty('visibility', 'hidden')

    let ribbonTabInsert_drpDnBtnInsertChart = document.getElementById('ribbonTabInsert_drpDnBtnInsertChart') as HTMLElement;
    ribbonTabInsert_drpDnBtnInsertChart?.style.setProperty('display', 'none')

    let ribbonTabInsert_drpDnBtnInsertBarcode = document.getElementById('ribbonTabInsert_drpDnBtnInsertBarcode') as HTMLElement;
    ribbonTabInsert_drpDnBtnInsertBarcode?.style.setProperty('display', 'none')

    let ribbonTabInsert_btnInsertSignature = document.getElementById('ribbonTabInsert_btnInsertSignature') as HTMLElement;
    ribbonTabInsert_btnInsertSignature?.style.setProperty('display', 'none')

    let ribbonGroupDataSource = document.getElementById('ribbonGroupDataSource') as HTMLElement;
    ribbonGroupDataSource?.style.setProperty('display', 'none')

    let ribbonGroupView = document.getElementById('ribbonGroupView') as HTMLElement;
    ribbonGroupView?.style.setProperty('display', 'none')

    let ribbonGroupMailMergeFinish = document.getElementById('ribbonGroupMailMergeFinish') as HTMLElement;
    ribbonGroupMailMergeFinish?.style.setProperty('display', 'none')

    let includeText = document.getElementById('txui-id-707') as HTMLElement;
    includeText?.style.setProperty('display', 'none')

    let next = document.getElementById('txui-id-709') as HTMLElement;
    next?.style.setProperty('display', 'none')

    let nextIf = document.getElementById('txui-id-710') as HTMLElement;
    nextIf?.style.setProperty('display', 'none')

    // let tabTableLayout = document.getElementById('tabTableLayout') as HTMLElement;
    // tabTableLayout?.style.setProperty('display', 'none')
  }

  private resizeDocumentEditor() {    
    if (this.documentEditorContainer) {
      const container = this.documentEditorContainer.nativeElement.parentElement;
      
      // Get the position and size of the container
      const containerRect = container.getBoundingClientRect();
      
      // Calculate available width and height
      const availableWidth = window.innerWidth - containerRect.left;
      const availableHeight = window.innerHeight - containerRect.top;
      
      // Set the size of the document editor
      this.documentEditorContainer.nativeElement.style.width = `${availableWidth - 20}px`;
      this.documentEditorContainer.nativeElement.style.height = `${availableHeight - 20}px`;
    }
  }
}
