import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidArg } from "src/app/types/helpers/VoidArg";
import { GetRaetTemplatesJob } from "../jobs/GetRaetTemplatesJob";
import { RaetTemplateOm } from "../types/RaetTemplateOm";

@Injectable({ providedIn: 'root' })
export class GetRaetTemplatesCmdlet extends CmdletBase<VoidArg, RaetTemplateOm[]> {

  constructor(private jobService: JobService, private job: GetRaetTemplatesJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: VoidArg): Promise<RaetTemplateOm[]> {
    let result = await this.jobService.runJob<GetRaetTemplatesJob, VoidArg, RaetTemplateOm[]>(this.job, arg);
    return result;
  }
}
