import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidArg } from "src/app/types/helpers/VoidArg";
import { ModulesApiClient } from "../api-clients/ModulesApiClient";
import { ModuleOvm } from "src/app/types/viewModels/ModuleOvm";
import { FileTypeEnum } from "src/app/common/types/enums/FileTypeEnum";

@Injectable({ providedIn: 'root' })
export class GetModulesJob implements IJob<VoidArg, ModuleOvm[]> {

  constructor(private modulesApiClient: ModulesApiClient) { }

  async execute(forceRefresh: boolean = false): Promise<ModuleOvm[]> {

    const modulesOdm = await this.modulesApiClient.getModules(forceRefresh);
    const moduleOvms = new Array<ModuleOvm>();
    modulesOdm.forEach(element => {
      const x = new ModuleOvm();
      Object.assign(x, element);
      moduleOvms.push(x);
    });
    return moduleOvms;
  }
}
