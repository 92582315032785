<div class="right-content-container">
  <div class="page-toolbar" (click)="navigateToBaseTemplates()">{{ (globalMode ? 'headers.mergefunctions_global' : 'headers.mergefunctions') | translate: { moduleName: moduleName } }} ({{ 'headers.module' | translate: { moduleName: moduleName } }})</div>

  <alert></alert>

  <div class="table" style="padding-bottom: 5rem;">

    <div class="table-header">
      <ul>
        <li>
          <input type="checkbox" [(ngModel)]="showOnlyActive" name="numberActiveTemplates" id="numberActiveTemplates"
            (change)="onshowOnlyActiveChanged($event)">
          <label for="numberActiveTemplates"> &nbsp; {{'labels.active_templates_only' | translate}}</label>
        </li>
        <li class="separator">&nbsp;</li>
        <li>
          <app-search-field (textChanged)="onSearchTextChanged($event)"></app-search-field>
        </li>
      </ul>
    </div>

    <div class="table-body" id="moduleOverview">
      <div class="table-header-row mergefunctiontableheader">
        <p>{{globalMode ? ('headers.mergefunctions_global' | translate) : ('headers.mergefunctions' | translate)}}</p>
        <app-refresh-button (refreshRequest)="onRefreshClicked()" [refreshTrigger]="refreshTrigger" [refreshDoneTrigger]="refreshDoneTrigger"></app-refresh-button>
      </div>
      <table [class.displaynone]="dataPage.rows.length == 0 || !isLoaded" mat-table matSort [dataSource]="dataPage.rows" class="list-items">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'table_headers.merge_function' | translate}}</th>
          <td class="text-max-100" mat-cell *matCellDef="let element"> {{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'table_headers.description' | translate}}</th>
          <td class="text-max-100" mat-cell *matCellDef="let element"> {{element.description}}</td>
        </ng-container>

        <ng-container matColumnDef="numberActiveTemplates">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'table_headers.active_templates' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.numberActiveTemplates}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected]='row.isSelected'
          (click)="onSelectRowClicked(row)"></tr>

      </table>

      <div class="empty-table" [class.displaynone]="dataPage.rows.length > 0" *ngIf="isLoaded && !dataError">
        {{'messages.no_merge_functions_found' | translate}}
      </div>

      <div class="empty-table" [class.displaynone]="dataPage.rows.length > 0" *ngIf="isLoaded && dataError">
        {{'errors.retrieving_data' | translate}}
      </div>

      <div class="table-skeleton" *ngIf="!isLoaded">
        <ngx-skeleton-loader appearance="line" count="10" [theme]="{height: '32px'}"></ngx-skeleton-loader>
      </div>
      <app-paginator [class.displaynone]="!dataPage?.rows?.length || !isLoaded" [totalPages]="dataPage.totalPages" [pageIndex]="pageIndex"
        (pageIndexChanged)="onPageIndexChanged($event)" class="paginator">
      </app-paginator>

    </div>
  </div>
</div>