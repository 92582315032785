<div class="dialog-layout" *ngIf="isLoaded">

  <!-- <div class="left-panel">

    <div class="main-text">
      <p>New Base Template</p>
    </div>

  </div> -->

  <div class="right-panel form">

    <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onFormSubmitted()" disabled="success">

      <div class="close-button">
        <button class="dialog-close-button" type="button" title="Sluiten" (click)="onCloseClicked($event)">x</button>
      </div>

      <div class="wizard">

        <div class="step" id="start-step" *ngIf="currentStepId == 'start-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>Nieuwe Raet Template</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row radio-row">
                <label for="use-local-template">&nbsp; Gebruik een lokale template</label>
                <div class="form-control">
                  <input id="use-local-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="use-local-template">
                </div>
              </div>

              <div class="form-row radio-row">
                <label for="create-empty-template">&nbsp; Maak een leeg template</label>
                <div class="form-control">
                  <input id="create-empty-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="create-empty-template">
                </div>
              </div>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>Annuleren</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">Volgende</button>

          </div>

        </div>

        <div class="step" id="module-step" *ngIf="currentStepId == 'module-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>Module</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row radio-row">
                <label for="use-local-template">&nbsp; Select module</label>
                <div class="form-row">
                  <label for="file-content">Geselecteerde module</label>
                  <div class="file-form-control">

                    <select matNativeControl class="dropdownlist" (change)="onModuleChange($event)" class="dropDown">
                      <option *ngFor="let module of modules" value="{{module.id}}">
                        {{module.name}}
                      </option>
                    </select>

                  </div>
                </div>
              </div>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>Annuleren</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">Volgende</button>

          </div>

        </div>


        <div class="step" id="mergeFunction-step" *ngIf="currentStepId == 'mergeFunction-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>MergeFunction</p>
          </div>

          <div class="body">

            <div class="form">

              <select matNativeControl class="dropdownlist" (change)="onMergeFunctionChange($event)" class="dropDown">
                <option *ngFor="let mf of mergeFunctions" value="{{mf.id}}">
                  {{mf.name}}
                </option>
              </select>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>Annuleren</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">Volgende</button>

          </div>

        </div>




        <div class="step" id="select-file-step" *ngIf="currentStepId == 'select-file-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>Sjabloonbron</p>
            <!-- <p>Please select file you would like to use as a source for your base template</p> -->
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <label for="file-content">Geselecteerd bestand</label>
                <div class="file-form-control">

                  <span></span>
                  <button type="button" (click)="onUploadFileClicked($event)">Openen</button>

                  <input id="select-file" style="display: none;" type="file" accept=".doc,.docx,.bin"
                    (change)="onFileChange($event)">
                </div>
              </div>
            </div>
          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)="onPrevClicked($event)">Voorgaand</button>

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>Annuleren</button>

            <button type="button" class="primary-button" [disabled]="isLoading || !templateFile"
              (click)="onNextClicked($event)">Volgende</button>

          </div>
        </div>

        <div class="step" id="name-description-step" *ngIf="currentStepId == 'name-description-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>Template naam</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <label for="name">Naam<span class="required-field-marker">*</span></label>
                <div class="form-control">
                  <input id="name" formControlName="name" type="text">
                </div>
                <div *ngIf="f.name.errors && f.name.errors.required" class="form-field-error-text">
                  Dit is een verplicht veld
                </div>
              </div>

              <!-- <div class="form-row">
                <label for="description">Description<span class="required-field-marker">*</span></label>
                <div class="form-control">
                  <textarea id="description" formControlName="description" rows="3"></textarea>
                </div>
                <div *ngIf="f.description.errors && f.description.errors.required" class="form-field-error-text">
                  This is a required field
                </div>
              </div> -->


            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)="onPrevClicked($event)">Voorgaand</button>
            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>Annuleren</button>
            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">Volgende</button>

          </div>
        </div>
      </div>
    </form>
  </div>
</div>