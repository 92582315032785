import { Injectable } from "@angular/core";
import { SessionStorageClient } from '../services/SessionStorageClient';
import { CurrentUserAccountModel } from "../types/dataModels/CurrentUserAccountModel";
import { AuthContextModel } from "../types/dataModels/AuthContextModel";

@Injectable({ providedIn: "root" })
class AuthenticationStorageProxy {
    constructor(private appStorage: SessionStorageClient) { }

    get currentUserAccount(): CurrentUserAccountModel {
        return this.appStorage.getObject<CurrentUserAccountModel>("current-user-account", CurrentUserAccountModel);
    }

    set currentUserAccount(value: CurrentUserAccountModel) {
        if (!value) {
            this.appStorage.removeObject("current-user-account");
        } else {
            this.appStorage.saveObject("current-user-account", value);
        }
    }

    get authToken(): AuthContextModel {
        return this.appStorage.getObject<AuthContextModel>("auth-token", AuthContextModel);
    }

    set authToken(value: AuthContextModel) {
        if (!value) {
            this.appStorage.removeObject("auth-token");
        } else {
            this.appStorage.saveObject("auth-token", value);
        }
    }
}

@Injectable({ providedIn: "root" })
export class AuthenticationStorage {
    constructor(private authenticationStorage: AuthenticationStorageProxy) { }

    get currentUserAccount(): CurrentUserAccountModel {
        return this.authenticationStorage.currentUserAccount;
    }

    set currentUserAccount(model: CurrentUserAccountModel) {
        this.authenticationStorage.currentUserAccount = null;
        this.authenticationStorage.currentUserAccount = model;
    }

    get authToken(): AuthContextModel {
        return this.authenticationStorage.authToken;
    }

    set authToken(model: AuthContextModel) {
        this.authenticationStorage.authToken = null;
        this.authenticationStorage.authToken = model;
    }
}
