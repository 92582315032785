import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateATemplateCmdlet4Content } from 'src/app/base-templates/cmdlets/UpdateATemplateCmdlet4Content';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { GetTemplateBlobCmdlet2 } from 'src/app/templates/cmdlets/GetTemplateBlobCmdlet2';
import { DataDefinitionDm } from 'src/app/templates/types/DataDefinitionDm';
import { GetBaseTemplateMetaCmdlet } from 'src/app/base-templates/cmdlets/GetBaseTemplateMetaCmdlet';
import { AlertTypeEnum } from '../../types/enums/alertType.enum';
import { AlertService } from 'src/app/common/services/AlertService';
import { SessionService } from '../../api-clients/session.service';
import { TranslateService } from '@ngx-translate/core';
import { Breadcrumb } from '../bread-crumbs/bread-crumbs.component';
import { CanComponentDeactivate } from '../../guards/unsaved-changes.guard';
import { AppInsightsLoggerService } from 'src/app/services/logging.service';
import { Title } from '@angular/platform-browser';
declare const TXTextControl: any;
declare const loadDocument: any;

@Component({
  selector: 'app-empty-template-editor',
  templateUrl: './empty-template-editor.component.html',
  styleUrl: './empty-template-editor.component.scss'
})
export class EmptyTemplateEditorComponent implements OnInit, AfterViewInit, CanComponentDeactivate {
  constructor(
    private getTemplateBlobCmdlet2: GetTemplateBlobCmdlet2,
    private route: ActivatedRoute,    
    private titleService: Title,
    private translate: TranslateService,
    private sessionService: SessionService,
    private getBaseTemplateMetaCmdlet: GetBaseTemplateMetaCmdlet,  
    private updateATemplateCmdlet4Content: UpdateATemplateCmdlet4Content,
    public componentContext: ComponentContext,    
    private alertService: AlertService,
    private logger: AppInsightsLoggerService,
    private router: Router) { }

  templateContentDto: TemplateEditorFileModel;
  templateBlob: Blob;
  isLoaded: boolean = false;
  loadError: boolean = false;
  showCustomDialog: boolean = false;
  mergeDataSpecification: DataDefinitionDm;
  content: any;
  templateName: string;
  baseTemplateId: number;
  actionBusy: boolean = false;
  myCulture: string;
  breadcrumbs: Breadcrumb[];
  contentChanged: boolean = false;  
  ribbonsLoaded: boolean = false;

  private text_template_saved: string;
  private text_template_not_saved: string;  
  private text_error_retrieving_data: string;
  private text_leave_confirmation: string;
  private text_base_templates: string;
  private text_edit: string;

  private documentLoadedHandler = this.onDocumentLoaded.bind(this);
  private changedHandler = this.onChanged.bind(this);

  @ViewChild('documentEditorContainer', { static: false }) documentEditorContainer!: ElementRef;

  @HostListener('document:txDocumentEditorLoaded', ['$event'])
  onTxDocumentEditorLoaded() {
    var self = this;

    TXTextControl.addEventListener("ribbonTabsLoaded", function () {
      self.setStyling()
    });

    TXTextControl.addEventListener("ribbonTabsLoaded", function () { self.setStyling(); self.ribbonsLoaded = true; });
    TXTextControl.addEventListener("documentLoaded", this.documentLoadedHandler);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.resizeDocumentEditor();
  }

  async ngOnInit(): Promise<void> {

    this.myCulture = this.sessionService.session.culture;
    this.isLoaded = false;

    this.translate.get([     
      'messages.template_saved',
      'errors.saving_template',      
      'errors.retrieving_data',
      'messages.leave_confirmation',
      'headers.base_templates',
      'headers.edit'
    ]).subscribe(translations => {           
      this.text_template_saved = translations['messages.template_saved'];
      this.text_template_not_saved = translations['errors.saving_template'];      
      this.text_error_retrieving_data = translations['errors.retrieving_data'];
      this.text_leave_confirmation = translations['messages.leave_confirmation'];
      this.text_base_templates = translations['headers.base_templates'];
      this.text_edit = translations['headers.edit'];
    });

    this.route.paramMap.subscribe(async params => {
      
      try {
        this.baseTemplateId = Number(params.get('baseTemplateId'));
        this.templateBlob = await this.getTemplateBlobCmdlet2.execute(this.baseTemplateId);
        const baseTemplate = await this.getBaseTemplateMetaCmdlet.execute(this.baseTemplateId);      
        this.templateName = baseTemplate.raetName;

        this.translate.get('pageTitles.base_template_edit_new', { templateName: this.templateName }).subscribe((title: string) => {
          this.titleService.setTitle(title);
        });

        this.breadcrumbs = [
          { label: this.text_base_templates, url: ['/base-templates', 'details'] },
          { label: this.templateName, url: ['/base-templates', this.baseTemplateId, 'details'] },       
          { label: this.text_edit }
        ];
        
        this.isLoaded = true;
        this.loadError = false;
      } catch (error) {
        console.error('Error getting template', error);
        this.logger.error('Error getting template', error);
        this.isLoaded = true;
        this.loadError = true;
        this.showAlert(AlertTypeEnum.warning, this.text_error_retrieving_data);
      }
    });
  }

  ngAfterViewInit() {    
    // Resize editor with a timeout
    this.resizeDocumentEditor();    
  }

  canDeactivate(): boolean {
    return !this.contentChanged;
  }

  get deactivationMessage(): string {
    return this.text_leave_confirmation;
  }
  
  blobToBase64(blob, callback) {
    var reader = new FileReader();
    reader.onload = function () {
      callback((<string>reader.result).split(',')[1]);
    };
    reader.readAsDataURL(blob);
  }

  async onCompleteTemplateClicked($event) {
    try {
      await this.onSaveTemplateClicked($event);
      // Navigatie alleen als het opslaan succesvol is
      this.router.navigate(['/base-template-details'], { queryParamsHandling: 'merge' });
    } catch (error) {
      // Fout is al afgehandeld in onSaveTemplateClicked, hier kun je extra foutafhandeling toevoegen als je wilt
      console.error('Opslaan mislukt, navigatie geannuleerd:', error);
      this.logger.error('Error saving base template', error);
    }
  }

  async onSaveTemplateClicked($event): Promise<void> {
    const self = this;
    this.actionBusy = true;
    this.actionBusy = true;  
    const templateId = this.baseTemplateId;
  
    try {
      const data = await new Promise<string>((resolve, reject) => {
        TXTextControl.saveDocument(TXTextControl.StreamType.InternalUnicodeFormat, function (e) {
          if (e) {
            resolve(e.data);
          } else {
            reject(this.text_error_saving_template);
          }
        });
      });
  
      const templateEditorFileModel = new TemplateEditorFileModel();
      templateEditorFileModel.id = templateId;
      templateEditorFileModel.content = data;
      templateEditorFileModel.name = 'test';
      templateEditorFileModel.description = 'testDesc';
  
      try {
        await self.saveDocument(templateEditorFileModel);
        self.actionBusy = false;      
        self.contentChanged = false;
      }
      catch (error) {
        self.actionBusy = false;  
        self.showAlert(AlertTypeEnum.warning, self.text_template_not_saved);           
      }
    } catch (error) {
      this.showAlert(AlertTypeEnum.warning, this.text_template_not_saved);
      self.actionBusy = false;  
      throw error; 
    }
  }
  

  async saveDocument(templateEditorFileModel: TemplateEditorFileModel) {
    try {
      await this.updateATemplateCmdlet4Content.execute(templateEditorFileModel);
      this.showAlert(AlertTypeEnum.success, this.text_template_saved);      
    } catch (error) {
      console.error('Fout tijdens het updaten van het template:', error);
      this.logger.error('Error saving base template', error);
      throw error; 
    }
  }

  onCancelTemplateClicked($event) {
    this.router.navigate(['/base-templates', this.baseTemplateId, 'details'], { queryParamsHandling: 'merge' });
  }

  async onClearTemplateClicked($event) {
    window.location.reload();
  }

  setStyling() {
    let tabProofing = document.getElementById('tabProofing') as HTMLElement;
    tabProofing?.style.setProperty('display', 'none')

    let tabPermissions = document.getElementById('tabPermissions') as HTMLElement;
    tabPermissions?.style.setProperty('display', 'none')

    let tabReferences = document.getElementById('tabReferences') as HTMLElement;
    tabReferences?.style.setProperty('display', 'none')

    let tabFormFields = document.getElementById('tabFormFields') as HTMLElement;
    tabFormFields?.style.setProperty('display', 'none')

    let txTglBtnSideBars = document.getElementById('txTglBtnSideBars') as HTMLElement;
    txTglBtnSideBars?.style.setProperty('visibility', 'hidden')

    let ribbonTabInsert_drpDnBtnInsertChart = document.getElementById('ribbonTabInsert_drpDnBtnInsertChart') as HTMLElement;
    ribbonTabInsert_drpDnBtnInsertChart?.style.setProperty('display', 'none')

    let ribbonTabInsert_drpDnBtnInsertBarcode = document.getElementById('ribbonTabInsert_drpDnBtnInsertBarcode') as HTMLElement;
    ribbonTabInsert_drpDnBtnInsertBarcode?.style.setProperty('display', 'none')

    let ribbonTabInsert_btnInsertSignature = document.getElementById('ribbonTabInsert_btnInsertSignature') as HTMLElement;
    ribbonTabInsert_btnInsertSignature?.style.setProperty('display', 'none')

    let ribbonGroupDataSource = document.getElementById('ribbonGroupDataSource') as HTMLElement;
    ribbonGroupDataSource?.style.setProperty('display', 'none')

    let ribbonGroupView = document.getElementById('ribbonGroupView') as HTMLElement;
    ribbonGroupView?.style.setProperty('display', 'none')

    let ribbonGroupMailMergeFinish = document.getElementById('ribbonGroupMailMergeFinish') as HTMLElement;
    ribbonGroupMailMergeFinish?.style.setProperty('display', 'none')

    let includeText = document.getElementById('txui-id-707') as HTMLElement;
    includeText?.style.setProperty('display', 'none')

    let next = document.getElementById('txui-id-709') as HTMLElement;
    next?.style.setProperty('display', 'none')

    let nextIf = document.getElementById('txui-id-710') as HTMLElement;
    nextIf?.style.setProperty('display', 'none')

    // let tabTableLayout = document.getElementById('tabTableLayout') as HTMLElement;
    // tabTableLayout?.style.setProperty('display', 'none')
  }

  private onDocumentLoaded() {
    // Bind to changed event after document was loaded    
    TXTextControl.addEventListener("changed", this.changedHandler);
    this.resizeDocumentEditor();
  }

  private onChanged() {
    this.contentChanged = true;    
  }

  private showAlert(type: AlertTypeEnum, text: string) {
    this.alertService.setAlert({ type, text });
  }

  private resizeDocumentEditor() {
    console.info('resizeDocumentEditor');
    if (this.documentEditorContainer) {
      const container = this.documentEditorContainer.nativeElement.parentElement;
      
      // Get the position and size of the container
      const containerRect = container.getBoundingClientRect();
      
      // Calculate available width and height
      const availableWidth = window.innerWidth - containerRect.left;
      const availableHeight = window.innerHeight - containerRect.top;
      
      // Set the size of the document editor
      this.documentEditorContainer.nativeElement.style.width = `${availableWidth - 20}px`;
      this.documentEditorContainer.nativeElement.style.height = `${availableHeight - 20}px`;
    }
  }
}

