import { Injectable } from "@angular/core";
import { FileTypeEnum } from "../types/enums/FileTypeEnum";


@Injectable({ providedIn: "root" })
export class DisplayNameService {

    getDisplayName(arg: FileTypeEnum): string {

        switch (arg) {
            case FileTypeEnum.TextBlock:
                return "Bouwsteen";

            case FileTypeEnum.StandardText:
                return "Standaard text";
        }

        return "Template";
    }
}
