import { Injectable } from '@angular/core';
import { VoidArg } from 'src/app/types/helpers/VoidArg';
import { ITemplateJob } from '../interfaces/ITemplateJob';
import { TemplateContext } from '../types/TemplateContext';
import TextControlManager from 'src/app/text-control/services/TextControlManager';

@Injectable({ providedIn: 'root' })
export class ExtactContentDocumentFileJob implements ITemplateJob<VoidArg, string> {

  constructor(private textControlClient: TextControlManager) { }

  async execute(arg: VoidArg, context: TemplateContext): Promise<string> {
    const result = await this.textControlClient.extractFileContentAsWord();
    return result;
  }
}
