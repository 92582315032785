import { OnInit, Directive } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { ComponentService } from "src/app/services/ComponentService";


@Directive()
export abstract class DialogComponentBase<TComponent, TResult> implements OnInit {
    result: TResult;
    isLoaded: boolean = false;
    isLoading: boolean = false;

    constructor(public componentService: ComponentService, public dialogRef: MatDialogRef<TComponent, TResult>) {
    }

    ngOnInit(): void {
        this.componentService.clearAlerts();

    }

    onCloseClicked(event) {
        this.close();
        event?.preventDefault();
    }

    onCancelClicked(event) {
        this.close();
        event?.preventDefault();
    }

    close() {
        this.componentService.clearAlerts();
        this.dialogRef?.close(this.result);
    }
}
