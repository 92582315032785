import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { MergeFunctionOvm } from "src/app/types/dataModels/MergeFunctionOvm";

@Injectable({ providedIn: 'root' })
export class GetMergeFunctionsByModuleIdJob implements IJob<{ moduleId: number, forceRefresh?: boolean, global: boolean }, MergeFunctionOvm[]> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: { moduleId: number, global: boolean, forceRefresh?: boolean }): Promise<MergeFunctionOvm[]> {
    const forceRefresh = arg.forceRefresh || false;
    const global = arg.global;
    
    // Pass global to getMergeFunctions
    const mergeFunctionsOdm = await this.mergeFunctionsApiClient.getMergeFunctions(arg.moduleId, global, forceRefresh);

    const mergeFunctionsOvm: MergeFunctionOvm[] = mergeFunctionsOdm?.mergeFunctions.map(data => {
      const mergeFunction = new MergeFunctionOvm();
      return Object.assign(mergeFunction, data);
    });
    
    return mergeFunctionsOvm;
  }
}
