import { Component, EventEmitter, Input, OnInit, Output  } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void { }

  @Output()
  readonly pageIndexChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  totalPages: number;

  @Input()
  pageIndex: number;

  // Threshold number of pages for showing first and last links
  @Input()
  pageThreshold: number = 3;

  get total(): number {
    if (!this.totalPages) {
      return 0;
    }
    return this.totalPages;
  }

  get canGotoNextPage(): boolean {
    if (this.pageIndex >= this.totalPages - 1) {
      return false;
    }
    return true;
  }

  get canGotoPrevPage(): boolean {
    if (this.pageIndex == 0) {
      return false;
    }

    return true;
  }

  get canGotoFirstPage(): boolean {
    return this.pageIndex > 0;
  }

  get canGotoLastPage(): boolean {
    return this.pageIndex < this.totalPages - 1;
  }

  setPageIndex(index: number): void {
    if (index >= 0 && index < this.totalPages) {
      this.pageIndex = index;
      this.pageIndexChanged.emit(this.pageIndex);
    }
  }

  onNextPage() {

    if (!this.canGotoNextPage) {
      return;
    }

    this.pageIndex = this.pageIndex + 1;
    this.pageIndexChanged.emit(this.pageIndex);
  }

  onPrevPage() {
    if (!this.canGotoPrevPage) {
      return;
    }

    this.pageIndex = this.pageIndex - 1;
    this.pageIndexChanged.emit(this.pageIndex);
  }

  onFirstPage() {
    if (this.canGotoFirstPage) {
      this.pageIndex = 0;
      this.pageIndexChanged.emit(this.pageIndex);
    }
  }

  onLastPage() {
    if (this.canGotoLastPage) {
      this.pageIndex = this.totalPages - 1;
      this.pageIndexChanged.emit(this.pageIndex);
    }
  }
}
