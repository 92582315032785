import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { DocumentFormatOvm } from "src/app/types/dataModels/DocumentFormatOvm";

@Injectable({ providedIn: 'root' })
export class GetMergeFunctionDocumentFormatsByIdJob implements IJob<number, DocumentFormatOvm[]> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: number): Promise<DocumentFormatOvm[]> {
    const documentFormatData = await this.mergeFunctionsApiClient.getMergeFunctionDocumentFormats(arg);

    const documentFormatOvm: DocumentFormatOvm[] = documentFormatData.documentFormats.map(data => {
      const documentFormat = new DocumentFormatOvm();
      return Object.assign(documentFormat, data);
    });
    
    return documentFormatOvm;
  }
}
