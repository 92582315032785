import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { TemplateVersionRevertIm } from "../types/TemplateVersionRevertIm";
import { RevertTemplateVersionCmdletJob } from "../jobs/RevertTemplateVersionCmdletJob";

@Injectable({ providedIn: 'root' })
export class RevertTemplateVersionCmdlet extends CmdletBase<TemplateVersionRevertIm, VoidResult> {

  constructor(private jobService: JobService, private job: RevertTemplateVersionCmdletJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: TemplateVersionRevertIm): Promise<VoidResult> {
    await this.jobService.runJob<RevertTemplateVersionCmdletJob, TemplateVersionRevertIm, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
