import { Injectable } from '@angular/core';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { CmdletBase } from 'src/app/common/services/CmdletBase';
import { JobService } from 'src/app/common/services/JobService';
import { ComponentService } from 'src/app/services/ComponentService';
import { VoidArg } from 'src/app/types/helpers/VoidArg';
import { GetTemplateContentJob } from '../jobs/GetTemplateContentJob';

@Injectable({ providedIn: 'root' })
export class GetTemplateContentCmdlet extends CmdletBase<number, TemplateEditorFileModel> {

  constructor(private jobService: JobService,
    componentService: ComponentService,
    private getTemplateEditorModelJob: GetTemplateContentJob) {
    super(componentService);
  }

  protected async executeImpl(id: number): Promise<TemplateEditorFileModel> {
    let documentFile = await this.jobService.runJob<GetTemplateContentJob, VoidArg, TemplateEditorFileModel>(this.getTemplateEditorModelJob, id);
    return documentFile;
  }
}
