import { Inject, Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';

type LoggingFunction = (value: any, ...rest: any[]) => void;

export interface LoggerService {
    info: LoggingFunction;
    debug: LoggingFunction;
    log: LoggingFunction;
    warn: LoggingFunction;
    error: LoggingFunction;
}

@Injectable()
export class AppInsightsLoggerService implements LoggerService {

    constructor() {
        this.environment = environment;
        this.setLevel(environment.logging?.level);
    }

    private environment: any;
    private _appInsights: ApplicationInsights;
    private _logLevel: 'debug' | 'info' | 'warn' | 'error';

    setLevel(level: string) {
        switch (level?.toLowerCase() || 'error') {
            case 'debug': this._logLevel = 'debug'; break;
            case 'info': this._logLevel = 'info'; break;
            case 'warn': this._logLevel = 'warn'; break;
            default: this._logLevel = 'error';
        }

        console.info('AppInsightsLoggerService set log level to ' + this._logLevel);

        this.configureAppInsights();
    }

    private configureAppInsights() {
        // Skip appInsights if not set
        if (this.environment.logging?.appInsights && (this.environment.logging.appInsights?.connectionString || this.environment.logging.appInsights?.instrumentationKey)) {
            this._appInsights = new ApplicationInsights({
                config: {
                    connectionString: this.environment.logging.appInsights.connectionString,
                    instrumentationKey: this.environment.logging.appInsights.instrumentationKey,
                    enableAutoRouteTracking: this.isLevelInfo // log all route changes if debug or info level
                }
            });

            this._appInsights.loadAppInsights();
        }
    }

    setAuthenticatedUserId(userId: string): void {
        if (this._appInsights) {
            this._appInsights.setAuthenticatedUserContext(userId);
            this.debug('appInsights: setAuthenticatedUserId ' + userId);
        }
    }

    clearAuthenticatedUser() {
        if (this._appInsights) {
            this._appInsights.clearAuthenticatedUserContext();
        }
    }

    logPageView(name?: string, url?: string) {
        if (this._appInsights) {
            this._appInsights.trackPageView({
                name: name,
                uri: url
            });
            this.debug('appInsights: logPageView ' + name);
        }
    }

    startEvent(name?: string) {
        if (this._appInsights) {
            this._appInsights.startTrackEvent(name);
        }
    }

    stopEvent(name: string, properties?: { [key: string]: any }) {
        if (this._appInsights) {
            this._appInsights.stopTrackEvent(name, properties);
        }
    }

    logEvent(name: string, properties?: { [key: string]: any }) {
        if (this._appInsights) {
            this._appInsights.trackEvent({ name: name }, properties);
        }
    }

    logInfoEvent(name: string, properties?: { [key: string]: any }) {
        if (this.isLevelInfo) {
            this.logEvent(name, properties);
        }
    }

    logDebugEvent(name: string, properties?: { [key: string]: any }) {
        if (this.isLevelDebug) {
            this.logEvent(name, properties);
        }
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        if (this._appInsights) {
            this._appInsights.trackMetric({ name: name, average: average }, properties);
        }
    }

    logException(exception: Error, properties?: { [key: string]: any }, severityLevel?: number) {
        if (this._appInsights) {
            this._appInsights.trackException({ exception, severityLevel }, properties);
        }
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        if (this._appInsights) {
            this._appInsights.trackTrace({ message: message }, properties);
        }
    }

    log(value: any, ...rest: any[]): void {
        console.log(value, rest);
    }

    debug(value: any, ...rest: any[]): void {
        if (this.isLevelDebug) {
            console.info(value, rest);
        }
    }

    info(value: any, ...rest: any[]): void {
        if (this.isLevelInfo) {
            console.info(value, rest);
        }
    }

    warn(value: any, ...rest: any[]): void {
        if (this.isLevelWarn) {
            console.warn(value, rest);
        }
    }

    error(value: any, ...rest: any[]): void {
        if (this.isLevelError) {
            console.error(value, ...rest);

            const errorDetails = rest[0] || {};
            if (value instanceof Error) {
                this.logException(value, errorDetails);
            } else {
                this.logException(new Error(value), errorDetails);
            }
        }
    }

    private get isLevelDebug(): boolean {
        return (this._logLevel === 'debug');
    }

    private get isLevelInfo(): boolean {
        return (this.isLevelDebug || this._logLevel === 'info');
    }

    private get isLevelWarn(): boolean {
        return (this.isLevelInfo || this._logLevel === 'warn');
    }

    private get isLevelError(): boolean {
        return (this.isLevelWarn || this._logLevel === 'error');
    }
}
