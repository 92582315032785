import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-shell',  
  templateUrl: './shell.component.html',
  styleUrl: './shell.component.scss'
})
export class ShellComponent implements OnInit {

  constructor(private router: Router) {
  }

  @ViewChild('scrollableDiv') scrollableDiv!: ElementRef;
  
  isLoading = false;
   
  async ngOnInit(): Promise<void> {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Reset de scrollpositie of the child element
        this.scrollToTop();
      }
    });
  }

  scrollToTop() {
    document.documentElement.scrollTop = 0;
  }

  
}
