import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogComponentBase } from 'src/app/common/components/DialogComponentBase';
import { ProcessingStorage } from 'src/app/common/services/ProcessingStorage';
import { ComponentService } from 'src/app/services/ComponentService';
import { GetMergeDataSpecificationCmdlet } from '../../cmdlets/GetMergeDataSpecificationCmdlet';
import { DataDefinitionDm } from '../../types/DataDefinitionDm';
import { MergeBlock } from '../../types/MergeBlock';
declare const TXTextControl: any;

@Component({
  selector: 'app-merge-block-dialog',
  // standalone: true,
  // imports: [],
  templateUrl: './merge-block-dialog.component.html',
  styleUrl: './merge-block-dialog.component.scss'
})
export class MergeBlockDialogComponent extends DialogComponentBase<MergeBlockDialogComponent, boolean> implements OnInit {
  //TXTextControl: any;
  mergeField: any;
  name: any;
  closeAfterInsert: true;
  textFormat: any;
  textAfter: any;
  textBefore: any;
  dateTimeFormat: any;
  numericFormat: any;

  mergeDataSpecification: DataDefinitionDm;
  categories: string[] = [];
  subCategories: string[] = [];
  mergeFieldNames: string[] = [];
  selectedCategory: string;
  selectedSubCategory: string;
  selectedMergeField: string;
  mergeFunctionId: number;

  mergeBlocks: MergeBlock[] = [];
  selectedMergeBlock: MergeBlock;

  constructor(componentService: ComponentService,
    private getMergeDataSpecificationCmdlet: GetMergeDataSpecificationCmdlet,
    private localStorage: ProcessingStorage,
    private route: ActivatedRoute,
    dialogRef: MatDialogRef<MergeBlockDialogComponent, boolean>) {
    super(componentService, dialogRef);
  }

  async ngOnInit() {
    super.ngOnInit();

    this.route.paramMap.subscribe(async params => {
      this.mergeFunctionId = Number(params.get('mergeFunctionId'));
      //})

      console.log(this.mergeFunctionId)
      this.mergeDataSpecification = await this.getMergeDataSpecificationCmdlet.execute(1000256461);

      for (let mergeBlock of this.mergeDataSpecification.mergeBlocks) {
        this.mergeBlocks.push(mergeBlock);
      }

      this.selectedMergeBlock = this.mergeBlocks[0];

      this.isLoaded = true;
    })
  }

  onMergeBlockChange($event) {
    console.log($event.target.value)
    this.selectedMergeBlock = this.mergeBlocks.find(({ name }) => name === $event.target.value);//$event.target.value.name;
    console.log('Selected Mergeblock: ' + this.selectedMergeBlock.name)
    //const result = inventory.find(({ name }) => name === "cherries");
    //console.log(this.selectedMergeBlock)
  }

  okCallback() {
    // var mergeField = new TXTextControl.MergeField;
    // mergeField.name = this.selectedMergeField;
    // mergeField.text = "«" + this.selectedMergeField + "»";
    // mergeField.textFormat = Number(this.textFormat);
    // mergeField.textAfter = this.textAfter;
    // mergeField.textBefore = this.textBefore;
    // mergeField.dateTimeFormat = this.dateTimeFormat;
    // mergeField.numericFormat = this.numericFormat;
    //console.log(mergeField.TextFormatOptions)
    //this.result = true;

    //TXTextControl.addMergeField(mergeField);
    //if (this.closeAfterInsert === true) {
    this.close();
    //}
  }

  insertMergeFieldFromBlock($event) {
    var mergeField = new TXTextControl.MergeField;
    mergeField.name = this.selectedMergeField;
    mergeField.text = "«" + this.selectedMergeField + "»";
    mergeField.textFormat = Number(this.textFormat);
    mergeField.textAfter = this.textAfter;
    mergeField.textBefore = this.textBefore;
    mergeField.dateTimeFormat = this.dateTimeFormat;
    mergeField.numericFormat = this.numericFormat;
    // console.log(mergeField.TextFormatOptions)
    //this.result = true;

    if (!TXTextControl.mergeBlockStartPosition) {
      if (this.selectedMergeField !== "startMergeBlock") {
        document.getElementById("tooltipFormatID").style.display = "block";
      }
      else {
        document.getElementById("tooltipFormatID").style.display = "none";
      }
    }
    else {
      document.getElementById("tooltipFormatID").style.display = "none";
    }

    console.log("this.selectedMergeField: " + this.selectedMergeField)
    if (this.selectedMergeField) {
      if (this.selectedMergeField.startsWith('Merge Block start')) {
        this.insertBlockStartPosition();
      }
      else if (this.selectedMergeField.startsWith('Merge Block end')) {
        this.insertBlockEndPosition();
      }
      else {
        TXTextControl.addMergeField(mergeField);
      }
    }
  }

  insertBlockStartPosition() {
    console.log("insertBlockStartPosition")
    this.removeStartPositionMergeField();
    TXTextControl.selection.getStart(function (startPosition) {
      TXTextControl.mergeBlockStartPosition = startPosition;

      var mergeField = new TXTextControl.MergeField();
      mergeField.name = "MergeBlockStart";
      mergeField.text = "[";
      var format = Number(this.textFormat);
      mergeField.textFormat = format ? format : 0;

      TXTextControl.addMergeField(mergeField);
    });
  }

  insertBlockEndPosition() {
    console.log("insertBlockEndPosition")
    this.removeEndPositionMergeField();

    // TXTextControl.selection.getStart(function (endPosition) {
    //   if (!TXTextControl.mergeBlockStartPosition) {
    //     console.log("!TXTextControl.mergeBlockStartPosition")
    //     return;
    //   }
    //   TXTextControl.mergeBlockEndPosition = endPosition;

    //   if (this.TXTextControl.mergeBlockEndPosition - this.TXTextControl.mergeBlockStartPosition <= 0) {
    //     alert("Please, set the end position after the start position");
    //     return;
    //   }

    //   var mergeField = new TXTextControl.MergeField();
    //   mergeField.name = "MergeBlockEnd";
    //   mergeField.text = "]";
    //   var format = 0;//parseInt(that.$("#selectedMergeFieldFormat option:selected").attr('id'));
    //   mergeField.textFormat = format ? format : 0;

    //   //.setItem("highlightCount", mergeField.text.length);

    //   this.TXTextControl.addMergeField(mergeField);
    // });

    var mergeField = new TXTextControl.MergeField();
    mergeField.name = "MergeBlockEnd";
    mergeField.text = "]";
    var format = Number(this.textFormat);
    mergeField.textFormat = format ? format : 0;

    //.setItem("highlightCount", mergeField.text.length);

    TXTextControl.addMergeField(mergeField);
  }

  removeStartPositionMergeField() {
    TXTextControl.getTextFields(function (fields) {
      var fieldsToRemove = fields.filter(function (f) {
        return f.name == this.TXTextControl.selectedMergeBlock.name + this.TXTextControl.mergeBlockStartPosition;
      });
      if (fieldsToRemove[0]) {
        this.TXTextControl.removeTextField(fieldsToRemove[0]);
      }
    }, false);
  }

  removeEndPositionMergeField() {
    TXTextControl.getTextFields(function (fields) {
      var fieldsToRemove = fields.filter(function (f) {
        return f.name == this.TXTextControl.selectedMergeBlock.name + this.TXTextControl.mergeBlockEndPosition;
      });
      if (fieldsToRemove[0]) {
        this.TXTextControl.removeTextField(fieldsToRemove[0]);
      }
    }, false);
  }

  onMouseDown() {

  }

  onSelectMergeField($event) {
    console.log($event.target.value)
    this.selectedMergeField = $event.target.value;
  }
}

