import { Injectable } from "@angular/core";
import { DownloadSourceFileReq } from "../types/helpers/DownloadSourceFileReq";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { BaseTemplatesApiClient } from "../api-clients/BaseTemplatesApiClient";
import { ProcessingStorage } from "src/app/common/services/ProcessingStorage";

@Injectable({ providedIn: "root" })
export class DownloadATemplateJob implements IJob<DownloadSourceFileReq, VoidResult> {

  constructor(private sourceFilesClient: BaseTemplatesApiClient, private localStorage: ProcessingStorage) { }

  async execute(arg: DownloadSourceFileReq): Promise<Blob> {
    try {
      let res = await this.sourceFilesClient.getSourceFileContent2(arg);
      return res;
    } catch (error) {
      console.error("Error fetching template", error);
      throw error;
    }
  }
}
