import { Component, OnDestroy, OnInit } from '@angular/core';
import { AlertService } from 'src/app/services/AlertService';

@Component({
  selector: 'app-user-messages',
  templateUrl: './user-messages.component.html',
  styleUrls: ['./user-messages.component.scss']
})
export class UserMessagesComponent implements OnInit, OnDestroy {

  errorMessage: string;
  successMessage: string;
  warningMessage: string;

  constructor(private alertService: AlertService) {
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {

    this.alertService.getError().subscribe((m) => this.showErrorMessage(m));
    this.alertService.getSuccess().subscribe((m) => this.showSuccessMessage(m));
    this.alertService.getWarning().subscribe((m) => this.showWarningMessage(m));
  }

  showErrorMessage(message) {
    this.errorMessage = message;
  }

  showWarningMessage(message) {
    this.warningMessage = message;
  }

  showSuccessMessage(message) {
    this.successMessage = message;
  }
}
