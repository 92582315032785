import { Injectable } from "@angular/core";
import { AlertService } from "./AlertService";
import { ComponentContext } from "./ComponentContext";


@Injectable({ providedIn: "root" })
export class ComponentService {
    constructor(private alertService: AlertService,
        private componentContext: ComponentContext) {
    }

    get isLoading(): boolean {
        return this.componentContext.isLoading;
    }

    set isLoading(value: boolean) {
        this.componentContext.isLoading = value;
    }

    showError(error: string): void {
        this.alertService.error(error);
    }

    clearAlerts(): void {
        this.alertService.clear();
    }
}
