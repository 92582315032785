import { Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { OwnFile } from 'src/app/types/helpers/OwnFile';
import { TemplatesApiClient } from '../api-clients/TemplatesApiClient';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { TemplateIm } from '../types/TemplateIm';

@Injectable({ providedIn: 'root' })
export class CreateTemplateJob implements IJob<TemplateEditorFileModel, any> {

  constructor(private httpClient: TemplatesApiClient) { }

  async execute(m: TemplateEditorFileModel): Promise<any> {
    const self = this;
    let model = new TemplateIm();
    Object.assign(model, m);
    model.name = m.name;
    model.description = m.description;
    model.mergeFunctionId = m.mergeFunctionId;
    var ownFile = new OwnFile();
    ownFile.fileName = m.name;
    ownFile.content = m.content;
    ownFile.contentType = 'application/text-control';
    model.file = ownFile;
    let templateExtension = m.documentType;

    let result = await this.httpClient.createTemplate(model, templateExtension);
    return result;
  }
}
