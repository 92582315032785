import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { UpdateTemplateContentJob } from "../jobs/UpdateTemplateContentJob";
import { TemplateEditorFileModel } from "src/app/base-templates/types/helpers/TemplateEditorFileModel";

@Injectable({ providedIn: 'root' })
export class UpdateTemplateContentCmdlet extends CmdletBase<TemplateEditorFileModel, VoidResult> {

  constructor(private jobService: JobService, private job: UpdateTemplateContentJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: TemplateEditorFileModel): Promise<VoidResult> {
    await this.jobService.runJob<UpdateTemplateContentJob, TemplateEditorFileModel, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
