import { Component, Input } from '@angular/core';

export interface Breadcrumb {
  label: string;
  url?: string | any[];
  params?: any;
}

@Component({
  selector: 'app-bread-crumbs',  
  templateUrl: './bread-crumbs.component.html',
  styleUrl: './bread-crumbs.component.scss'
})
export class BreadCrumbsComponent {
  @Input() breadcrumbs: (Breadcrumb | string)[] = [];
}
