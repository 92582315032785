import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class SessionStorageClient {

  constructor() { }

  public getObject<T>(key: string, ctor: { new(): T; }): T {
    let result: T = new ctor();
    let source = JSON.parse(sessionStorage.getItem(key));
    //tesr
    if (!source) {
      return null;
    }

    Object.assign(result, source);
    return result;
  }

  public getObjectAsString(key: string): string {
    let result = sessionStorage.getItem(key);
    return result;
  }

  public saveObject(key: string, obj: any): void {
    window.sessionStorage.removeItem(key);
    window.sessionStorage.setItem(key, JSON.stringify(obj));
  }

  public removeObject(key: string): void {
    window.sessionStorage.removeItem(key);
  }

  public removeAllObjects(): void {
    window.sessionStorage.clear();
  }
}
