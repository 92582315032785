import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidArg } from "src/app/types/helpers/VoidArg";
import { ModuleOvm } from "src/app/types/viewModels/ModuleOvm";
import { BaseTemplatesApiClient } from "../api-clients/BaseTemplatesApiClient";
import { BaseTemplateOvm } from "src/app/types/viewModels/BaseTemplateOvm";

@Injectable({ providedIn: 'root' })
export class GetBaseTemplatesJob implements IJob<VoidArg, BaseTemplateOvm[]> {

  constructor(private baseTemplatesApiClient: BaseTemplatesApiClient) { }

  async execute(arg: VoidArg): Promise<BaseTemplateOvm[]> {

    const baseTemplatesOdm = await this.baseTemplatesApiClient.getBaseTemplates();
    //console.log(baseTemplatesOdm);
    const baseTemplatesOvms = new Array<BaseTemplateOvm>();
    baseTemplatesOdm.forEach(element => {
      const x = new BaseTemplateOvm();
      Object.assign(x, element);
      baseTemplatesOvms.push(x);
    });

    return baseTemplatesOvms;
  }
}
