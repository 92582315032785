import { Injectable } from '@angular/core';
import { AuthOwnHttpClient } from 'src/app/common/api-clients/AuthOwnHttpClient';
import { ApiEndpointClient } from 'src/app/common/api-clients/ApiEndpointClient';
import { FileTypeEnum } from 'src/app/common/types/enums/FileTypeEnum';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { BlobHttpContent, JsonHttpContent } from 'src/app/types/helpers/HttpContent';
import { ASourceFileUm4Meta } from 'src/app/common/types/helpers/ASourceFileUm4Meta';
import { ASourceFileUm4Content } from 'src/app/common/types/helpers/ASourceFileUm4Content';
import { RaetTemplateOm } from '../types/RaetTemplateOm';
import { RaetTemplateIm } from '../types/RaetTemplateIm';
import { RaetTemplateUm4ActiveState } from '../types/RaetTemplateUm4ActiveState';
import { MergeFunctionsOdm } from 'src/app/types/dataModels/MergeFunctionsOdm';

@Injectable({ providedIn: "root" })
export class RaetTemplatesApiClient {

  constructor(private apiEndpointClient: ApiEndpointClient, private httpClient: AuthOwnHttpClient) { }

  private mergeFunctionsCache: MergeFunctionsOdm;

  async getRaetMergeFunctions(forceRefresh: boolean = false): Promise<MergeFunctionsOdm> {
    
    console.info('getRaetMergeFunctions...');

    if (this.mergeFunctionsCache && !forceRefresh) {
      return Promise.resolve(this.mergeFunctionsCache);
    }

    const url: string = `/globaltemplate/mergefunctions`;
    const data = await this.apiEndpointClient.get<MergeFunctionsOdm>(url);

    // Sla de data op in de cache    
    this.mergeFunctionsCache = data;

    return data;
  }

  async getRaetTemplates(): Promise<RaetTemplateOm[]> {

    let url: string = '/globaltemplate';

    let data = await this.apiEndpointClient.get<RaetTemplateOm[]>(url);
    console.log(data)
    return data;
  }

  // async getRaetTemplate(id: number): Promise<BaseTemplateOdm> {

  //   let url: string = '/raettemplate/' + id + '/meta';
  //   let data = await this.apiEndpointClient.get<BaseTemplateOdm>(url);
  //   console.log('BaseTemplateOdm: ' + data)
  //   return data;
  // }

  async getRaetTemplateMeta(id: number): Promise<RaetTemplateOm> {
    let url: string = '/globaltemplate/' + id + '/meta';
    let data = await this.apiEndpointClient.get<RaetTemplateOm>(url);
    console.log('raettemplateMeta: ')
    console.log(data)
    return data;
  }

  async createRaetTemplate(raetTemplate: RaetTemplateIm, templateExtension: string): Promise<VoidResult> {

    let baseUrl = "/globaltemplate";
    let blobContent = new BlobHttpContent();
    blobContent.fileName = raetTemplate.name + '.' + templateExtension;
    blobContent.content = raetTemplate.file.content;
    let jsonContent = new JsonHttpContent();
    jsonContent.content = JSON.stringify({ name: raetTemplate.name, mergeFunctionId: raetTemplate.mergeFunctionId });
    await this.httpClient.postMultipart(baseUrl, jsonContent, blobContent);
    return new VoidResult();
  }

  async updateSourceFileMeta(m: ASourceFileUm4Meta): Promise<void> {
    let url = `/globaltemplate/${m.id}/meta`;
    await this.apiEndpointClient.put<number>(url, m);
  }

  // async updateSourceFileContent(baseTemplate: BaseTemplateDdm): Promise<void> {
  //   let url = `/raettemplate/${baseTemplate.id}/content`;
  //   let blobContent = new BlobHttpContent();
  //   blobContent.fileName = baseTemplate.name;
  //   blobContent.content = baseTemplate.file.content;
  //   let jsonContent = new JsonHttpContent();
  //   jsonContent.content = JSON.stringify({ id: baseTemplate.id });
  //   await this.httpClient.putMultipart(url, jsonContent, blobContent);
  // }

  async getSourceFileContent(id: number, version: string = null): Promise<Blob> {
    let url = `/globaltemplate/${id}/content`;
    let data = await this.apiEndpointClient.getFile(url);
    return data;
  }

  // async getSourceFileContent2(arg: DownloadSourceFileReq): Promise<Blob> {
  //   console.log(arg)
  //   let url = `/template/${arg.templateId}/documentType/${arg.documentType}/blob`
  //   let data = await this.apiEndpointClient.getFile(url);
  //   return data;
  // }

  // async deleteSourceFile(id: number): Promise<void> {
  //   let url = `/raettemplate/${id}`;
  //   await this.apiEndpointClient.delete<void>(url);
  // }

  async updateRaetTemplateActiveState(m: RaetTemplateUm4ActiveState): Promise<void> {
    let url = `/raettemplate/${m.templateId}/active`;
    console.log(m)
    await this.apiEndpointClient.put<number>(url, m);
  }
}

