import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidArg } from "src/app/types/helpers/VoidArg";
import { BaseTemplateOvm } from "src/app/types/viewModels/BaseTemplateOvm";
import { RaetTemplatesApiClient } from "../api-clients/RaetTemplatesApiClient";
import { RaetTemplateOm } from "../types/RaetTemplateOm";

@Injectable({ providedIn: 'root' })
export class GetRaetTemplatesJob implements IJob<VoidArg, RaetTemplateOm[]> {

  constructor(private raetTemplatesApiClient: RaetTemplatesApiClient) { }

  async execute(arg: VoidArg): Promise<RaetTemplateOm[]> {

    const raetTemplateOms = await this.raetTemplatesApiClient.getRaetTemplates();
    // const baseTemplatesOvms = new Array<RaetTemplateOm>();
    // baseTemplatesOdm.forEach(element => {
    //   const x = new RaetTemplateOm();
    //   Object.assign(x, element);
    //   baseTemplatesOvms.push(x);
    // });

    return raetTemplateOms;
  }
}
