<div class="default-layout"> 
  <div class="content"> 
    <div #scrollableDiv class="right-column" *ngIf="!isLoading">      
      <router-outlet></router-outlet>
    </div>
    <div class="right-column" *ngIf="isLoading">
      <ngx-skeleton-loader appearance="line" count="10" [theme]="{height: '32px'}"></ngx-skeleton-loader>
    </div>
  </div>
</div>

  