<!-- confirmation-dialog.component.html -->
<div class="dialog-layout">
  <div class="right-panel form">
    <div class="close-button">
      <button
        class="dialog-close-button"
        type="button"
        title="Sluiten"
        (click)="onButtonClick(DialogButtonType.Cancel)"
      >x</button>
    </div>
    <div class="wizard">
      <p class="helpText">{{ data.confirmationText }}</p>
    </div>
    <div class="footer">
      <ng-container *ngFor="let button of data.buttons">
        <button
          type="button"
          [ngClass]="getButtonCssClass(button)"
          (click)="onButtonClick(button)"
          [attr.autofocus]="isDefaultButton(button) ? true : null">
          {{ buttonDisplayText[button] }}
        </button>
      </ng-container>
    </div>
  </div>
</div>
