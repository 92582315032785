import { Injectable } from '@angular/core';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { TemplatePlaceholderModel } from 'src/app/base-templates/types/helpers/TemplatePlaceholderModel';
import { VoidArg } from 'src/app/types/helpers/VoidArg';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { LoadDocumentEditorJob } from '../jobs/LoadDocumentEditorJob';
import { TemplateContext } from '../types/TemplateContext';
import { TemplateJobService } from './TemplateJobService';
import { ExtactContentDocumentFileJob } from '../jobs/ExtactContentDocumentFileJob';
import TextControlManager from 'src/app/text-control/services/TextControlManager';

declare const TXTextControl: any;


@Injectable({ providedIn: 'root' })
export default class TemplateEditorClient {

  context: TemplateContext = new TemplateContext();

  constructor(private loadDocumentFileJob: LoadDocumentEditorJob,
    private jobService: TemplateJobService,
    //private templateEventBus: TemplateEventBus,
    private textControlClient: TextControlManager,
    //private getPlaceholdersJob: GetPlaceholdersJob,
    private extactContentDocumentFileJob: ExtactContentDocumentFileJob,
    //private getPlaceholderJob: GetPlaceholderJob
  ) {
  }

  setInactiveMarker(): Promise<VoidResult> {
    return new Promise<VoidResult>((resolve, reject) => {
      TXTextControl.inputPosition.setInactiveMarker(true, () => resolve(new VoidResult), error => reject(error));
    });
  }

  async loadModel(arg: TemplateEditorFileModel): Promise<VoidResult> {

    Object.assign(this.context, arg);
    //this.context.placeholders = arg.placeholders ?? [];

    await this.jobService.runJob<LoadDocumentEditorJob, string, VoidResult>(this.loadDocumentFileJob, arg.content, this.context);

    // TXTextControl.removeEventListener('textFieldClicked', (e) => this.onFormFieldClicked(e));
    // TXTextControl.addEventListener('textFieldClicked', (e) => this.onFormFieldClicked(e));

    // TXTextControl.removeEventListener('textFieldLeft', (e) => this.onFormFieldLeft(e));
    // TXTextControl.addEventListener('textFieldLeft', (e) => this.onFormFieldLeft(e));

    // TXTextControl.removeEventListener('textDropped', (e) => this.onTextDroppedFired(e));
    // TXTextControl.addEventListener('textDropped', (e) => this.onTextDroppedFired(e));

    console.debug("Document file has been loaded into editor...");
    return new VoidResult();
  }

  async extractModel(): Promise<TemplateEditorFileModel> {
    // const self = this;
    // var editorDirectives = await this.textControlClient.getDirectives();

    // editorDirectives.forEach(f => {

    //   const name = f.name;
    //   const xx = self.context.placeholders.filter(x => x.id == name);

    //   if (xx.length == 0) {
    //     return;
    //   }

    //   const zz = xx[0];
    //   const id = zz.id;
    //   f.setText(id);
    // });

    const content = await this.jobService.runJob<ExtactContentDocumentFileJob, VoidArg, string>(this.extactContentDocumentFileJob, new VoidArg(), this.context);
    const result = new TemplateEditorFileModel();
    Object.assign(result, this.context);
    result.content = content;
    return result;
  }

  // onTextDroppedFired(e: any) {
  //   const tagTypeId = e.text;
  //   const tagType = TagTypes.getAll().filter(x => x.id == tagTypeId)[0];
  //   this.templateEventBus.tagDropped.next(tagType)
  //   e.cancel = true;
  // }

  // createTagPlaceholder(arg: TemplatePlaceholderModel): Promise<VoidResult> {
  //   this.context.placeholders.push(arg);
  //   return this.textControlClient.createDirective(arg.id, arg.tag.getDisplayName());
  // }

  // updateFormField(arg: TemplatePlaceholderModel): VoidResult {

  //   let index = this.context.placeholders.findIndex(x => x.id == arg.id);

  //   if (index < 0) {
  //     return;
  //   }

  //   const target = this.context.placeholders[index];

  //   Object.assign(target, arg);
  // }

  // getTagFields(): Promise<TagField[]> {
  //   return this.jobService.runJob<GetPlaceholdersJob, VoidArg, TagField[]>(this.getPlaceholdersJob, new VoidArg(), this.context);
  // }

  // async getTagField(name: string): Promise<TagField> {
  //   return this.jobService.runJob<GetPlaceholderJob, string, TagField>(this.getPlaceholderJob, name, this.context);
  // }

  // async deleteTagPlaceholder(placeholder: TemplatePlaceholderModel) {

  //   const selectedFormField = await this.getTagField(placeholder.id);
  //   await this.textControlClient.deleteDirective(selectedFormField.formField);

  //   let arr = [];

  //   this.context.placeholders.forEach(x => {
  //     if (x.id == placeholder.id) {
  //       return;
  //     }

  //     arr.push(x);
  //   })

  //   this.context.placeholders = arr;
  //   return new VoidResult();
  // }

  // async selectTagField(arg: TagField): Promise<VoidResult> {
  //   await arg.formField.select();
  //   return new VoidResult();
  // }

  // private async onFormFieldClicked(event: any) {
  //   let name = event.textField.name;
  //   let selectedPlaceholder = await this.getTagField(name);
  //   this.templateEventBus.placeholderSelected.next(selectedPlaceholder.model);
  // }

  // private async onFormFieldLeft(event: any) {
  //   this.templateEventBus.placeholderSelected.next(null);
  // }
}
