import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ErrorCodes } from '../../types/enums/error-codes.enum';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error-page',  
  templateUrl: './error-page.component.html',
  styleUrl: './error-page.component.scss'
})
export class ErrorPageComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,    
    private translate: TranslateService,
    private titleService: Title, 
    private _location: Location) { }

  error: ErrorCodes;
  isFatal: boolean;  
  public ErrorCodes = ErrorCodes;
  errorText: string;
  imageUrl: string;

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {      
      if (params['error']) {
        this.isFatal = true;
        this.error = +params['error'];        
      } else if (params['warning']) {
        this.isFatal = false;
        this.error = +params['warning'];
      } else {
        this.isFatal = true; 
        this.error = ErrorCodes.Unknown;
      }

      if (this.error === ErrorCodes.NoAccess || this.error === ErrorCodes.TokenExpired) {
        this.translate.get('errors.no_access').subscribe((text: string) => {
          this.errorText = text;
        });        
        this.imageUrl = 'assets/images/access_denied.svg';
      } else {
        this.errorText = this.translate.instant('errors.unknown');
        this.imageUrl = 'assets/images/server_error.svg';
      }
      
      this.translate.get('pageTitles.error', { error: this.error }).subscribe((title: string) => {
        this.titleService.setTitle(title);
      });
    });
  }

  backClick() {  
      this._location.back();    
  }
}
