import { ComponentService } from "src/app/services/ComponentService";
import { ICmdlet } from "../interfaces/ICmdlet";


export abstract class CmdletBase<TArg, TResult> implements ICmdlet<TArg, TResult> {

    constructor(private componentService: ComponentService) { }

    async execute(arg: TArg): Promise<TResult> {

        //console.log("Running cmdlet " + this.constructor.name);

        try {
            this.componentService.isLoading = true;
            let result: TResult = await this.executeImpl(arg);
            return result;
        } catch (error) {
            this.componentService.showError(error?.message);
            throw error;
        }
        finally {
            this.componentService.isLoading = false;
        }
    }

    protected abstract executeImpl(arg: TArg): Promise<TResult>;
}
