import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { OverviewComponentBase } from 'src/app/common/components/OverviewComponentBase';
import { TableService, TableConfig } from 'src/app/common/services/TableService';
import { FileTypeEnum } from 'src/app/common/types/enums/FileTypeEnum';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { ComponentService } from 'src/app/services/ComponentService';
import { VoidArg } from 'src/app/types/helpers/VoidArg';
import { GetRaetTemplatesCmdlet } from '../../cmdlets/GetRaetTemplatesCmdlet';
import { RaetTemplateOm } from '../../types/RaetTemplateOm';
import { UploadRaetTemplateComponent } from '../upload-raet-template/upload-raet-template.component';
import { RaetTemplateUm4ActiveState } from '../../types/RaetTemplateUm4ActiveState';
import { UpdateRaetTemplateStatusCmdlet } from '../../cmdlets/UpdateRaetTemplateStatusCmdlet';

@Component({
  selector: 'app-raet-template-overview',
  templateUrl: './raet-template-overview.component.html',
  styleUrl: './raet-template-overview.component.scss'
})
export class RaetTemplateOverviewComponent extends OverviewComponentBase<RaetTemplateOm> implements OnInit {

  constructor(    
    private router: Router,    
    private route: ActivatedRoute,
    componentService: ComponentService,    
    public componentContext: ComponentContext,
    private getRaetTemplatesCmdlet: GetRaetTemplatesCmdlet,    
    private updateRaetTemplateStatusCmdlet: UpdateRaetTemplateStatusCmdlet,
    tableService: TableService<RaetTemplateOm>,
    private dialog: MatDialog) {
    super(tableService, componentService)
  }

  isLoaded = false;
  toggle = true;
  showMergeFunctions = false;
  helpPageVisibility = false;
  displayedColumns: string[] = ["raetName", "user", "createdBy", "isActive", "cmds"];
  searchColumns: string[] = ["raetName"];
  baseTemplates: RaetTemplateOm[];
  mergeFunctionId: number;

  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren('tableRow') tableRowsRef: QueryList<ElementRef>;

  protected get tableRows(): QueryList<ElementRef> {
    return this.tableRowsRef;
  }

  async ngOnInit() {
    this.isLoading = false;

    this.sort.active = "raetName";
    this.sort.direction = "asc";
    this.sort.disableClear = true;

    this.sort.sortChange.subscribe(() => {
      this.refreshView();
    });

    this.route.paramMap.subscribe(async params => {      
      this.isLoaded = false;
      
      this.mergeFunctionId = Number(params.get('mergeFunctionId'));
      
      // this.mergeFunction = await this.getMergeFunctionCmdlet.execute({id: this.mergeFunctionId, forceRefresh: false});
      // this.categories = await this.getMergeFunctionCategoriesCmdlet.execute(this.mergeFunctionId);     
      
      // this.selectedCategory = this.mergeFunction.pdCategory;

      // await this.reloadView();

      // if (this.allRows) {
      //   this.defaultTemplate = this.allRows.find(row => row.isDefault);
      // }

      super.ngOnInit();

      this.isLoaded = true;
    });      
  }

  onEditTemplateClicked($event, row: any) {    
    // Force reload to make sure the editor is loaded correctly
    const currentParams = new URLSearchParams(window.location.search);
    const activeParam = currentParams.get('active');
    document.location.href = '/raet-templates/' + row.id + '/editor' + (activeParam ? '?active=true' : '');    
  }

  onManageVersionsClicked(row: RaetTemplateOm) {
  }

  onPageIndexChanged($event) {
  }

  async loadRows(): Promise<RaetTemplateOm[]> { 
    const result = await this.getRaetTemplatesCmdlet.execute(new VoidArg());    
    this.isLoaded = true;
    return result;
  }

  async onDeleteRowClicked(row: RaetTemplateOm) {
  }

  onAddNewRowClicked(mode: string) {

    this.componentContext.isDiaglogShown = true;

    // switch (mode) {
    //   case "wizard":
    //     this.addNewTemplateViaWizard();
    //     break;

    //case "upload":
    this.addNewTemplateViaUpload();
    //break;
  }

  addNewTemplateViaUpload() {
    this.componentContext.isDiaglogShown = true;
    const config: MatDialogConfig = new MatDialogConfig();
    const dialogRef1 = this.dialog.open<UploadRaetTemplateComponent, any, boolean>(UploadRaetTemplateComponent, config);
    const dialogInstance2 = dialogRef1.componentInstance;
    dialogInstance2.selectedTemplateType = FileTypeEnum.Template;
    dialogRef1.afterClosed().subscribe(async (result) => {
      this.componentContext.isDiaglogShown = false;

      if (result) {
        await this.reloadView();
      }
    });
  }

  onSelectRowClicked(row: RaetTemplateOm) {
    this.dataPage.selectRow(row);    
    this.router.navigate(['/raet-templates', row.id, 'details']);
  }

  onEditRowClicked(row: RaetTemplateOm) {
    throw new Error('Method not implemented.');
  }
  onViewRowClicked(row: RaetTemplateOm) {
    throw new Error('Method not implemented.');
  }
  get tableConfig(): TableConfig {
    let config: TableConfig = {
      filterConfig: {
        columns: this.searchColumns,
        searchText: this.searchText,
      },
      sortConfig: {
        sortColumn: this.sort.active,
        sortDirection: this.sort.direction,
      },
      pageConfig: {
        pageIndex: this.pageIndex,
      },
    };
    return config;
  }

  async setRaetTemplateActive($element) {
    let isActive = $element.isActive == 1 ? false : true;
    let baseTemplateUm4ActiveState = new RaetTemplateUm4ActiveState();
    baseTemplateUm4ActiveState.isActive = isActive;
    baseTemplateUm4ActiveState.templateId = $element.id;    
    await this.updateRaetTemplateStatusCmdlet.execute(baseTemplateUm4ActiveState);    
  }
}
