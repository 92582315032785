import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponentBase } from 'src/app/common/components/DialogComponentBase';
import { ComponentService } from 'src/app/services/ComponentService';
declare const TXTextControl: any;

@Component({
  selector: 'app-date-field-dialog',
  templateUrl: './date-field-dialog.component.html',
  styleUrl: './date-field-dialog.component.scss'
})
export class DateFieldDialogComponent extends DialogComponentBase<DateFieldDialogComponent, boolean> implements OnInit {
  //TXTextControl: any;
  mergeField: any;
  name: any;
  closeAfterInsert: true;
  textFormat: any;
  textAfter: any;
  textBefore: any;
  dateTimeFormat: any;
  numericFormat: any;
  selectDateField: any;
  selectedMergeField: string;
  mergeFunctionId: number;
  preserveFormatting: boolean = false;
  disabledFormats: boolean = false;
  customFormat: string = "dd.MM.yyyy";

  constructor(componentService: ComponentService,
    dialogRef: MatDialogRef<DateFieldDialogComponent, boolean>) {
    super(componentService, dialogRef);
  }

  async ngOnInit() {
    super.ngOnInit();
  }

  insertExpressionField() {
    var selectedFormat: any;
    if (!this.disabledFormats) {
      selectedFormat = this.selectDateField;
    }
    else {
      selectedFormat = this.customFormat;
    }
    var preserveFormatting = this.preserveFormatting;
    console.log(selectedFormat)
    this.insertDateField(selectedFormat, preserveFormatting);
    this.close();
  }

  onDateFieldChange($event) {
    console.log($event.target.value)
    this.selectDateField = $event.target.value;
  }

  insertDateField(selectedFormat, preserveFormatting) {
    var dateField = new TXTextControl.DateField();
    dateField.format = selectedFormat;
    dateField.preserveFormatting = preserveFormatting;
    TXTextControl.addMergeField(dateField);
    //this.close();
  }

  updateDateField(selectedFormat, preserveFormatting) {
    TXTextControl.getTextFields(function (items) {
      if (items[0]) {
        TXTextControl.removeTextField([0])
      }
    }, true);

    var dateField = new TXTextControl.DateField();
    dateField.format = selectedFormat;
    dateField.preserveFormatting = preserveFormatting;
    TXTextControl.addMergeField(dateField);
  }
}
