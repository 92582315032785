import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar-primary-button',
  templateUrl: './toolbar-primary-button.component.html',
  styleUrls: ['./toolbar-primary-button.component.scss']
})
export class ToolbarPrimaryButtonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  text: string;

  @Input()
  icon: string;

  @Output()
  readonly clicked: EventEmitter<void> = new EventEmitter<void>();

  onButtonClicked() {
    this.clicked.emit();
  }
}
