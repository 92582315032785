import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { MergeFunctionOvm } from "src/app/types/dataModels/MergeFunctionOvm";

@Injectable({ providedIn: 'root' })
export class GetMergeFunctionByIdJob implements IJob<{ id: number, forceRefresh: boolean }, MergeFunctionOvm> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: { id: number, forceRefresh: boolean }): Promise<MergeFunctionOvm> {
    const mergeFunctionOdm = await this.mergeFunctionsApiClient.getMergeFunction(arg.id, arg.forceRefresh);

    const mergeFunctionOvm = new MergeFunctionOvm();
    const newObject = Object.assign(mergeFunctionOvm, mergeFunctionOdm);
    return newObject;
  }
}
