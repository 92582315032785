import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { DataDefinitionDm } from "../types/DataDefinitionDm";
import { GetMergeDataSpecificationJob } from "../jobs/GetMergeDataSpecificationJob";

@Injectable({ providedIn: 'root' })
export class GetMergeDataSpecificationCmdlet extends CmdletBase<number, DataDefinitionDm> {

  constructor(private jobService: JobService, private job: GetMergeDataSpecificationJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: number): Promise<DataDefinitionDm> {
    let result = await this.jobService.runJob<GetMergeDataSpecificationJob, number, DataDefinitionDm>(this.job, arg);
    return result;
  }
}
