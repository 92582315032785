<div class="right-content-container">
  <div class="page-toolbar">{{ 'headers.base_templates' | translate }}</div>
  <alert></alert>  
  <div class="table">
    <div class="table-header">
      <button (click)="onAddNewRowClicked('upload')" class="editButton3">{{ 'buttons.new_base_templates' | translate}}</button>
      <app-search-field (textChanged)="onSearchTextChanged($event)"></app-search-field>
    </div>

    <div class="table-body" id="baseTemplatesOverview">      
      <table [class.displaynone]="!isLoaded" mat-table matSort [dataSource]="dataPage.rows" class="list-items">

        <!-- <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>Id</th>
              <td mat-cell *matCellDef="let element" id="header"> {{element.id}}</td>
            </ng-container> -->

        <ng-container matColumnDef="raetName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table_headers.base_template' | translate}}</th>
          <td class="text-max-100" mat-cell *matCellDef="let element"> {{element.raetName}}</td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table_headers.user_id' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.createdBy}}</td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'table_headers.name' | translate}}</th>
          <td mat-cell *matCellDef="let element"> {{element.createdBy}}</td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef mat-sort-header class="centered">{{ 'table_headers.active' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="centered width-200">
            <mat-checkbox class="example-margin" [checked]="element.isActive" (click)="$event.stopPropagation()"
              (change)="setBaseTemplateActive(element)"></mat-checkbox>
        </ng-container>

        <ng-container matColumnDef="cmds">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let element" class="buttons-cell centered width-130">
            <ul>
              <li>
                <button (click)="onEditTemplateClicked($event, element)" class="editButton">{{ 'buttons.edit' | translate}}</button>
              </li>
            </ul>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected]='row.isSelected'
          (click)="onSelectRowClicked(row)"></tr>
      </table>

      <div class="empty-table" [class.displaynone]="dataPage.rows.length > 0" *ngIf="isLoaded">
        {{ 'messages.no_templates_found' | translate}}
      </div>

      <div *ngIf="!isLoaded">        
        <ngx-skeleton-loader appearance="line" count="8" [theme]="{height: '32px'}"></ngx-skeleton-loader>
      </div>
      
      <app-paginator [totalPages]="dataPage.totalPages" [pageIndex]="pageIndex" (pageIndexChanged)="onPageIndexChanged($event)" 
        class="paginator">
      </app-paginator>

    </div>
  </div>
</div>