import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { TemplatesApiClient } from "../api-clients/TemplatesApiClient";
import { TemplateDelm } from "../types/TemplateDelm";

@Injectable({ providedIn: "root" })
export class DeleteTemplateJob implements IJob<TemplateDelm, VoidResult> {

  constructor(private apiClient: TemplatesApiClient) { }

  async execute(templateDelm: TemplateDelm): Promise<VoidResult> {
    await this.apiClient.deleteTemplate(templateDelm);
    return new VoidResult();
  }
}
