import { Injectable } from '@angular/core';
import { CmdletBase } from 'src/app/common/services/CmdletBase';
import { JobService } from 'src/app/common/services/JobService';
import { AlertService } from 'src/app/services/AlertService';
import { ComponentService } from 'src/app/services/ComponentService';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { CreateBaseTemplateJob } from '../jobs/CreateBaseTemplateJob';
import { TemplateEditorFileModel } from '../types/helpers/TemplateEditorFileModel';


@Injectable({ providedIn: 'root' })
export default class CreateBaseTemplateCmdlet extends CmdletBase<TemplateEditorFileModel, VoidResult> {

  constructor(private jobService: JobService,
    componentService: ComponentService,
    private alertService: AlertService,
    private createBaseTemplateJob: CreateBaseTemplateJob) {
    super(componentService);
  }

  async executeImpl(m: TemplateEditorFileModel): Promise<VoidResult> {
    await this.jobService.runJob<CreateBaseTemplateJob, TemplateEditorFileModel, VoidResult>(this.createBaseTemplateJob, m);
    this.alertService.success("Template has been created");
    return new VoidResult();
  }
}
