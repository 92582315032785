import { EventEmitter, Injectable } from "@angular/core";
import { Subject } from "rxjs";


@Injectable({ providedIn: "root" })
export class ComponentContext {

    private loading: boolean;
    private dialogShown: boolean = false;

    constructor() { }

    get isLoading(): boolean {
        return this.loading;
    }

    set isLoading(value: boolean) {
        this.loading = value;
    }

    get isDialogShown(): boolean {
        return this.dialogShown;
    }

    set isDiaglogShown(value: boolean) {
        this.dialogShown = value;
    }

    isLoading123: Subject<boolean> = new Subject<boolean>();
}
