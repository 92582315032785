import { Injectable } from '@angular/core';
import { IComparer1, IComparer2, ISortService } from '../interfaces/ISortService';


class NullComparer<T> implements IComparer1, IComparer2<T>
{
    compare1(a: any, b: any, sortDirection: string): number {
        return 0;
    }

    compare2(a: T, b: T, sortDirection: string): number {
        return 0;
    }
}

class StringComparer implements IComparer1, IComparer2<string>
{
    compare1(a: any, b: any, sortDirection: string): number {
        let r = this.compare2(a, b, sortDirection);
        return r;
    }

    compare2(a: string, b: string, sortDirection: string): number {

        let aa = (a ?? "").toLowerCase();
        let bb = (b ?? "").toLowerCase();

        let r: number;

        if (aa > bb) {
            r = 1
        } else if (aa < bb) {
            r = -1
        } else {
            r = 0
        };

        return sortDirection == "asc" ? r : -r;
    }
}

class BooleanComparer implements IComparer1, IComparer2<boolean> {
    compare1(a: any, b: any, sortDirection: string): number {
        return this.compare2(a, b, sortDirection);
    }

    compare2(a: boolean, b: boolean, sortDirection: string): number {
        let aa = a ? 1 : 0;
        let bb = b ? 1 : 0;

        let r: number;

        if (aa > bb) {
            r = 1;
        } else if (aa < bb) {
            r = -1;
        } else {
            r = 0;
        }

        return sortDirection === "asc" ? r : -r;
    }
}

class NumberComparer implements IComparer1, IComparer2<number> {
    compare1(a: any, b: any, sortDirection: string): number {
        return this.compare2(a, b, sortDirection);
    }

    compare2(a: number, b: number, sortDirection: string): number {
        let r: number;

        if (a > b) {
            r = 1;
        } else if (a < b) {
            r = -1;
        } else {
            r = 0;
        }

        return sortDirection === "asc" ? r : -r;
    }
}

@Injectable({ providedIn: 'root' })
export class SortService<T> implements ISortService<T> {

    private findComparer(typeName: string): IComparer1 {

        if (typeName === "string") {
            return new StringComparer() as IComparer1;
        } else if (typeName === "boolean") {
            return new BooleanComparer() as IComparer1;
        } else if (typeName === "number") {
            return new NumberComparer() as IComparer1;
        }

        return new NullComparer<T>();
    }

    sort<T>(source: T[], columnName: string, direction: string): T[] {

        if (source.length == 0) {
            return source;
        }

        let firstItem = source[0];
        let columnType = typeof (firstItem[columnName]);
        let comparer: IComparer1;
        comparer = this.findComparer(columnType);

        let arr = new Array<T>();

        source.forEach(a => {
            arr.push(a);
        })

        let result = arr.sort((a, b) => {

            let left = a[columnName];
            let right = b[columnName];
            return comparer.compare1(left, right, direction.toLowerCase());
        })

        return result;
    }
}
