<div class="dialog-layout" *ngIf="isLoaded">

  <!-- <div class="left-panel">

    <div class="main-text">
      <p>Redo Template</p>
    </div>

  </div> -->

  <div class="right-panel form">

    <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onFormSubmitted()" disabled="success">

      <div class="close-button">
        <button class="dialog-close-button" type="button" [title]="'buttons.close' | translate" (click)="onCloseClicked($event)">x</button>
      </div>

      <div class="wizard">

        <div class="step" id="start-step" *ngIf="currentStepId == 'start-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>{{ 'headers.new_template' | translate }}</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row radio-row">
                <label for="use-default-template">&nbsp;{{ 'labels.use_base_template' | translate}}</label>
                <div class="form-control">
                  <input id="use-default-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="use-default-template">
                </div>
              </div>

              <div class="form-row radio-row">
                <label for="copy-this-template">&nbsp;{{ 'labels.make_copy_template' | translate}}</label>
                <div class="form-control">
                  <input id="copy-this-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="copy-this-template">
                </div>
              </div>

              <div class="form-row radio-row">
                <label for="use-local-template">&nbsp;{{ 'labels.use_local_template' | translate}}</label>
                <div class="form-control">
                  <input id="use-local-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="use-local-template">
                </div>
              </div>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate}}</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">{{ 'buttons.next' | translate}}</button>

          </div>

        </div>

        <div class="step" id="select-file-step" *ngIf="currentStepId == 'select-file-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>{{ 'labels.template_source' | translate }}</p>
            <p>{{ 'messages.select_file_as_source' | translate }}</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <label for="file-content">{{ 'labels.selected_file' | translate }}</label>
                <div class="file-form-control">

                  <span>{{selectedFileName}}</span>
                  <button type="button" (click)="onUploadFileClicked($event)">{{ 'buttons.open' | translate }}</button>

                  <input id="select-file" style="display: none;" type="file" accept=".doc,.docx,.bin"
                    (change)="onFileChange($event)">
                </div>
              </div>
            </div>
          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)="onPrevClicked($event)">{{ 'buttons.previous' | translate}}</button>

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate}}</button>

            <button type="button" class="primary-button" [disabled]="isLoading || !templateFile"
              (click)="onNextClicked($event)">{{ 'buttons.next' | translate}}</button>

          </div>

        </div>


        <div class="step" id="select-default-template-step" *ngIf="currentStepId == 'select-default-template-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>{{ 'headers.select_base_template' | translate }}</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <label for="file-content">{{ 'labels.selected_base_template' | translate }}</label>
                <div class="file-form-control">

                  <select matNativeControl class="dropdownlist" (change)="onDefaultTemplateChange($event)" formControlName="selectedTemplate"
                    class="dropDown" >
                    <option *ngFor="let defaultTemplate of baseTemplates" value="{{defaultTemplate.id}}">
                      {{defaultTemplate.raetName}}
                    </option>
                  </select>

                </div>
              </div>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)="onPrevClicked($event)">{{ 'buttons.previous' | translate}}</button>

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate}}</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">{{ 'buttons.next' | translate}}</button>

          </div>

        </div>


        <div class="step" id="select-template-step" *ngIf="currentStepId == 'select-template-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>{{ 'headers.select_source_template' | translate }}</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <label for="file-content">{{ 'labels.selected_template' | translate }}</label>
                <div class="file-form-control">

                  <select matNativeControl class="dropdownlist" (change)="onTemplateChange($event)" class="dropDown" formControlName="selectedTemplate">
                    <option *ngFor="let template of templates" value="{{template.id}}">{{template.name}}
                    </option>
                  </select>

                </div>
              </div>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)="onPrevClicked($event)">{{ 'buttons.previous' | translate}}</button>

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate}}</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">{{ 'buttons.next' | translate}}</button>

          </div>

        </div>
        
      </div>

    </form>

  </div>

</div>