import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { MergeFunctionUm4TemplateReq } from "../types/MergeFunctionUm4TemplateReq";

@Injectable({ providedIn: 'root' })
export class UpdateMergeFunctionTemplateByIdJob implements IJob<MergeFunctionUm4TemplateReq, VoidResult> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: MergeFunctionUm4TemplateReq): Promise<VoidResult> {
    await this.mergeFunctionsApiClient.updateMergeFunctionTemplateById(arg);
    return VoidResult;
  }
}
