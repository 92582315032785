<div id="BackgroundDiv">
  <div class="">
    <div class="txui-dialog-titlebar txui-widget-header txui-corner-all txui-helper-clearfix">
      <span id="txui-id-1671" class="txui-dialog-title">Merge Field Options</span>
      <button id="butt" (click)="onCloseClicked($event)"
        class="txui-button txui-widget txui-state-default txui-corner-all txui-button-icon-only txui-dialog-titlebar-close">
        <span class="txui-button-icon-primary txui-icon txui-icon-closethick" style="pointer-events: none"></span>
        <span class="txui-button-text" style="pointer-events: none">Close</span>
      </button>
    </div>

    <div class="txDialog txui-dialog-content txui-widget-content">

      <div id="OfficelinkModalContent">

        <div id="mergeFieldDialog" class="txDialog txui-dialog-content txui-widget-content">

          <fieldset id="MERGE_FIELD">
            <legend id="MERGE_FIELD_FILTER">Merge field filter</legend>
            <table style="border-collapse: collapse">
              <tbody>
                <tr>
                  <td>
                    <label id="MERGE_FIELD_FILTER_CATEGORY" style="width: 100px" for="txtFieldName">Category</label>
                  </td>
                  <td>
                    <select size="1" id="selectedCategory" (change)="onCategoryChange($event)" style="width:500px"
                      title="selectedCategoryValue">
                      <option *ngFor="let category of categories" value="{{category}}">
                        {{category}}
                      </option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label id="MERGE_FIELD_FILTER_SUBCATEGORY" style="width: 100px"
                      for="txtFieldText">Subcategory</label>
                  </td>
                  <td>
                    <select size="1" id="selectedSubCategory" (change)="onSubCategoryChange($event)" style="width:500px"
                      title="selectedSubCategoryValue">
                      <option *ngFor="let subCategory of subCategories" value="{{subCategory}}">
                        {{subCategory}}
                      </option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
          <fieldset>
            <legend id="MERGE_GROUP_FIELD_PROPERTIES">Merge Field Properties</legend>
            <table>
              <tbody>
                <tr>
                  <td>
                    <label id="MERGE_FIELD_NAME" style="width:100px" for="selMergeFieldFormat">Merge Field Name</label>
                  </td>
                  <td>
                    <select size="6" id="selectedMergeField" (change)="selectMergeField($event)" style="width:500px">
                      <option *ngFor="let mergeFieldName of mergeFieldNames" value="{{mergeFieldName}}"
                        ngModel="selectedMergeField" selected="selectedMergeField">
                        {{mergeFieldName}}
                      </option>
                    </select>
                  </td>
                  <td>
                    <div class="tooltipCustom" id="tooltipFormatID">
                      <!-- MERGE_FIELD_NAME_ERROR -->
                      <span id="tooltiptextID" class="tooltiptextC"></span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>
          <fieldset>
            <legend id="MERGEFIELD_GROUP_FIELD_OPTIONS">Merge Field Options</legend>
            <table style="border-collapse: collapse">
              <tr>
                <td style="padding-right:10px">
                  <label id="MERGE_FIELD_FORMAT" for="selMergeFieldFormat">Format:</label>
                  <select size="6" id="selectedMergeFieldFormat" style="width: 150px" [(ngModel)]="textFormat">
                    <option id="none" value="0">(none)</option>
                    <option id="upper_case" value="1">UPPER CASE</option>
                    <option id="lower_case" value="2">lower case</option>
                    <option id="first_cap" value="3">First capital</option>
                    <option id="title_case" value="4">Title Case</option>
                  </select>
                </td>
                <td width="20px"></td>
                <td>
                  <label id="MERGEFIELD_LABEL_TEXT_BEFORE" for="chkTextBefore"
                    style="float: left; clear: both; margin-top: 5px">Text to be inserted before:</label>
                  <input type="text" id="txtTextBefore" style="float: left; clear: both; width: 200px"
                    [(ngModel)]="textBefore" />
                  <input type="checkbox" id="chkTextBefore" style="float: left" hidden>

                  <label id="MERGEFIELD_LABEL_TEXT_AFTER" for="chkTextAfter"
                    style="float: left; clear: both; margin-top: 5px">Text to be inserted after:</label>
                  <input type="text" id="txtTextAfter" style="float: left; clear: both; width: 200px"
                    [(ngModel)]="textAfter" />
                  <input type="checkbox" id="chkTextAfter" style="float: left; clear: left" hidden>
                </td>
                <td width="20px"></td>
                <td>
                  <label id="MERGEFIELD_LABEL_DATE_TIME_FORMAT" for="chkDateTimeFormat"
                    style="float: left; clear: both; margin-top: 5px">Date/time format:</label>
                  <input type="text" id="txtDateTimeFormat" style="float: left; clear: both; width: 200px"
                    [(ngModel)]="dateTimeFormat" />
                  <input type="checkbox" id="chkDateTimeFormat" style="float: left; clear: left" hidden>

                  <label id="MERGEFIELD_LABEL_NUMERIC_FORMAT" for="chkNumericFormat"
                    style="float: left; clear: both; margin-top: 5px">Numeric format:</label>
                  <input type="text" id="txtNumericFormat" style="float: left; clear: both; width: 200px"
                    [(ngModel)]="numericFormat" />
                  <input type="checkbox" id="chkNumericFormat" style="float: left; clear: left" hidden>
                </td>
              </tr>
            </table>
            <label for="chkClose" style="float: right; clear: left;">Close after insert</label>
            <input type="checkbox" id="chkClose" name="chkClose" style="float: right; clear: left; "
              (click)="changeCheckbox()" checked>
          </fieldset>
        </div>
        <div class="txui-dialog-buttonpane txui-widget-content txui-helper-clearfix">
          <div class="txui-dialog-buttonset">
            <button type="button" id="modalOkButton" (click)="okCallback()"
              class="txui-button txui-widget txui-state-default txui-corner-all txui-button-text-only" role="button"
              aria-disabled="false">
              <span id="MODAL_OK" class="txui-button-text">Ok</span>
            </button>
            <button type="button" id="modalCancelButton" (click)="onCloseClicked($event)"
              class="txui-button txui-widget txui-state-default txui-corner-all txui-button-text-only" role="button"
              aria-disabled="false">
              <span id="MODAL_CANCEL" class="txui-button-text">Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>