<div class="dialog-layout" *ngIf="isLoaded">

  <div class="right-panel form">

    <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onFormSubmitted()" disabled="success">

      <div class="close-button">
        <button class="dialog-close-button" type="button" [title]="'buttons.close' | translate" (click)="onCloseClicked($event)">x</button>
      </div>

      <div class="wizard">

        <div class="step" id="start-step" *ngIf="currentStepId == 'start-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>{{ 'headers.new_template' | translate}}</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row radio-row">
                <label for="use-default-template">&nbsp;{{ 'labels.use_base_template' | translate}}</label>
                <div class="form-control">
                  <input id="use-default-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="use-default-template">
                </div>
              </div>

              <div class="form-row radio-row">
                <label for="copy-this-template">&nbsp;{{ 'labels.make_copy_template' | translate}}</label>
                <div class="form-control">
                  <input id="copy-this-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="copy-this-template">
                </div>
              </div>

              <div class="form-row radio-row">
                <label for="use-local-template">&nbsp;{{ 'labels.use_local_template' | translate}}</label>
                <div class="form-control">
                  <input id="use-local-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="use-local-template">
                </div>
              </div>

              <div class="form-row radio-row">
                <label for="create-empty-template">&nbsp;{{ 'labels.use_empty_template' | translate}}</label>
                <div class="form-control">
                  <input id="create-empty-template" name="wizardType" formControlName="wizardType" type="radio"
                    value="create-empty-template">
                </div>
              </div>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate}}</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">{{ 'buttons.next' | translate}}</button>

          </div>

        </div>

        <div class="step" id="select-file-step" *ngIf="currentStepId == 'select-file-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>{{ 'labels.template_source' | translate }}</p>
            <p>{{ 'messages.select_file_as_source' | translate }}</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <label for="file-content">{{ 'labels.selected_file' | translate }}</label>
                <div class="file-form-control">

                  <span>{{selectedFileName}}</span>
                  <button type="button" (click)="onUploadFileClicked($event)">{{ 'buttons.open' | translate }}</button>

                  <input id="select-file" style="display: none;" type="file" accept=".doc,.docx,.bin"
                    (change)="onFileChange($event)">
                </div>
              </div>
            </div>
          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)="onPrevClicked($event)">{{ 'buttons.previous' | translate }}</button>

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate }}</button>

            <button type="button" class="primary-button" [disabled]="isLoading || !templateFile"
              (click)="onNextClicked($event)">{{ 'buttons.next' | translate }}</button>

          </div>

        </div>


        <div class="step" id="select-default-template-step" *ngIf="currentStepId == 'select-default-template-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>{{ 'headers.select_base_template' | translate }}</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <label for="file-content">{{ 'labels.selected_base_template' | translate }}</label>
                <div class="file-form-control">

                  <select matNativeControl class="dropdownlist" (change)="onDefaultTemplateChange($event)"
                    class="dropDown">
                    <option *ngFor="let defaultTemplate of activeDefaultTemplates" value="{{defaultTemplate.id}}">
                      {{defaultTemplate.raetName}}
                    </option>
                  </select>

                </div>
              </div>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)="onPrevClicked($event)">{{ 'buttons.previous' | translate }}</button>

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate }}</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">{{ 'buttons.next' | translate }}</button>

          </div>

        </div>


        <div class="step" id="select-template-step" *ngIf="currentStepId == 'select-template-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>{{ 'headers.select_source_template' | translate }}</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <!-- <label for="file-content">Selected template</label> -->
                <div class="file-form-control">

                  <select *ngIf="templates.length != 0" matNativeControl class="dropdownlist"
                    (change)="onTemplateChange($event)" class="dropDown">
                    <option *ngFor="let template of templates" value="{{template.id}}">{{template.name}}
                    </option>
                  </select>

                  <div *ngIf="templates.length == 0">
                    <p class="title">{{ 'messages.no_templates_to_copy' | translate }}</p>
                  </div>

                </div>
              </div>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)="onPrevClicked($event)">{{ 'buttons.previous' | translate }}</button>

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate }}</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">{{ 'buttons.next' | translate }}</button>

          </div>

        </div>


        <div class="step" id="name-description-step" *ngIf="currentStepId == 'name-description-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>{{ 'headers.template_name_description' | translate }}</p>
            <p>{{ 'messages.enter_template_details' | translate }}</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row">
                <label for="name">{{ 'labels.name' | translate }}<span class="required-field-marker">*</span></label>
                <div class="form-control">
                  <input id="name" formControlName="name" type="text">
                </div>
                <div *ngIf="f.name.errors?.required && (f.name.touched || f.name.dirty)" class="form-field-error-text">
                  {{ 'labels.required_field' | translate }}
                </div>
              </div>

              <div class="form-row">
                <label for="description">{{ 'labels.description' | translate }}<span class="required-field-marker">*</span></label>
                <div class="form-control">
                  <textarea id="description" formControlName="description" rows="3"></textarea>
                </div>
                <div *ngIf="f.description.errors?.required && (f.description.touched || f.description.dirty)" class="form-field-error-text">
                  {{ 'labels.required_field' | translate }}
                </div>
              </div>


            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading || isBusy"
              (click)="onPrevClicked($event)">{{ 'buttons.previous' | translate }}</button>

            <button type="button" class="optional-button" [disabled]="isLoading || isBusy"
              (click)='onCancelClicked($event)'>{{ 'buttons.cancel' | translate }}</button>

            <button type="button" class="primary-button" [disabled]="isLoading || isBusy"
              (click)="onNextClicked($event)">{{ 'buttons.create' | translate }}</button>

          </div>

        </div>

      </div>

    </form>

  </div>

</div>