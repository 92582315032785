import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidArg } from "src/app/types/helpers/VoidArg";
import { GetBaseTemplatesJob } from "../jobs/GetBaseTemplatesJob";
import { BaseTemplateOvm } from "src/app/types/viewModels/BaseTemplateOvm";

@Injectable({ providedIn: 'root' })
export class GetBaseTemplatesCmdlet extends CmdletBase<VoidArg, BaseTemplateOvm[]> {

  constructor(private jobService: JobService, private job: GetBaseTemplatesJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: VoidArg): Promise<BaseTemplateOvm[]> {
    let result = await this.jobService.runJob<GetBaseTemplatesJob, VoidArg, BaseTemplateOvm[]>(this.job, arg);
    //console.log(result);
    return result;
  }
}
