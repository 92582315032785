import { Injectable } from "@angular/core";
import { BaseTemplatesApiClient } from "../api-clients/BaseTemplatesApiClient";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { BaseTemplateUm4ActiveState } from "../types/helpers/BaseTemplateUm4ActiveState";


@Injectable({ providedIn: "root" })
export class UpdateBaseTemplateStatusJob implements IJob<BaseTemplateUm4ActiveState, VoidResult> {

  constructor(private baseTemplatesApiClient: BaseTemplatesApiClient) { }

  async execute(arg: BaseTemplateUm4ActiveState): Promise<VoidResult> {
    await this.baseTemplatesApiClient.updatebaseTemplateStatus(arg);
    return new VoidResult();
  }
}
