import { Injectable } from "@angular/core";

import { BaseTemplatesApiClient } from "../api-clients/BaseTemplatesApiClient";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { ASourceFileUm4Meta } from "src/app/common/types/helpers/ASourceFileUm4Meta";
import { VoidResult } from "src/app/types/helpers/VoidResult";


@Injectable({ providedIn: "root" })
export class UpdateATemplateJob4Meta implements IJob<ASourceFileUm4Meta, VoidResult> {

    constructor(private baseTemplatesApiClient: BaseTemplatesApiClient) { }

    async execute(arg: ASourceFileUm4Meta): Promise<VoidResult> {
        await this.baseTemplatesApiClient.updateSourceFileMeta(arg);
        return new VoidResult();
    }
}
