import { Injectable } from "@angular/core";
import { DisplayNameService } from "../../common/services/DisplayNameService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { AlertService } from "src/app/services/AlertService";
import { ComponentService } from "src/app/services/ComponentService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { RaetTemplateUm4ActiveState } from "../types/RaetTemplateUm4ActiveState";
import { UpdateRaetTemplateActiveStateJob } from "../jobs/UpdateRaetTemplateActiveStateJob";

@Injectable({ providedIn: "root" })
export class UpdateRaetTemplateStatusCmdlet extends CmdletBase<RaetTemplateUm4ActiveState, VoidResult> {

  constructor(private jobService: JobService,
    private job: UpdateRaetTemplateActiveStateJob,
    componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: RaetTemplateUm4ActiveState): Promise<VoidResult> {
    await this.jobService.runJob<UpdateRaetTemplateActiveStateJob, RaetTemplateUm4ActiveState, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
