import { Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { TemplateDdm } from 'src/app/types/dataModels/TemplateDdm';
import { VoidArg } from 'src/app/types/helpers/VoidArg';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { TemplateJobService } from '../services/TemplateJobService';
import { TemplateContext } from '../types/TemplateContext';
import { ITemplateJob } from '../interfaces/ITemplateJob';

declare const TXTextControl: any;

@Injectable({ providedIn: 'root' })
class SetupDocumentEditorJob implements ITemplateJob<VoidArg, VoidResult> {

  constructor() { }

  private setUpPassiveFormFieldColor() {
    return new Promise<VoidResult>((resolve, reject) => {
      TXTextControl.displayColors.setFormFieldColor("#0000FF", () => resolve(new VoidResult()));
    });
  }

  private setUpActiveFormFieldColor() {
    return new Promise<VoidResult>((resolve, reject) => {
      TXTextControl.displayColors.setActiveFormFieldColor("#FF0000", () => resolve(new VoidResult()));
    });
  }

  async execute(): Promise<VoidResult> {

    return new Promise<VoidResult[]>((resolve, reject) => {
      let arr: Promise<VoidResult>[] = [];
      arr.push(this.setUpActiveFormFieldColor());
      arr.push(this.setUpPassiveFormFieldColor());
      Promise.all(arr).then(data => resolve(data), error => reject(error));
    });
  }
}

@Injectable({ providedIn: 'root' })
class LoadDocumentJob implements IJob<VoidArg, VoidResult> {

  constructor() { }

  execute(fileContent: string): Promise<VoidResult> {
    console.log('LoadDocumentJob fileContent')
    console.log(fileContent)
    if (fileContent) {
      return new Promise<VoidResult>((resolve, reject) => {
        TXTextControl.loadDocument(TXTextControl.StreamType.WordprocessingML, fileContent, () => resolve(new VoidResult()));
      });
    }
    else {
      return new Promise<VoidResult>((resolve, reject) => {
        TXTextControl.loadDocument(TXTextControl.StreamType.HTMLFormat, btoa(''), () => resolve(new VoidResult()));
      });
    }
  }
}

@Injectable({ providedIn: 'root' })
export class LoadDocumentEditorJob implements ITemplateJob<VoidArg, VoidResult> {

  constructor(private jobService: TemplateJobService,
    private loadDocumentJob: LoadDocumentJob,
    //private setupFormFieldsJob: SetupFormFieldsJob,
    private setupDocumentEditorJob: SetupDocumentEditorJob
  ) { }

  async execute(fileContent: string, context: TemplateContext): Promise<VoidResult> {

    //set text control self first
    await this.jobService.runJob<SetupDocumentEditorJob, VoidArg, VoidResult>(this.setupDocumentEditorJob, new VoidArg(), context);

    //load file into text control
    await this.jobService.runJob<LoadDocumentJob, string, VoidResult>(this.loadDocumentJob, fileContent, context)

    //set up form fields
    //await this.jobService.runJob<SetupFormFieldsJob, VoidArg, VoidResult>(this.setupFormFieldsJob, context.placeholders, context);

    return new VoidResult();
  }
}
