import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { UpdateMergeFunctionPdCategoryJob } from "../jobs/UpdateMergeFunctionPdCategoryJob";
import { MergeFunctionUm4PdCategoryReq } from "src/app/types/dataModels/MergeFunctionUm4PdCategoryReq";

@Injectable({ providedIn: 'root' })
export class UpdateMergeFunctionPdCategoryCmdlet extends CmdletBase<MergeFunctionUm4PdCategoryReq, VoidResult> {

  constructor(private jobService: JobService, private job: UpdateMergeFunctionPdCategoryJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: MergeFunctionUm4PdCategoryReq): Promise<VoidResult> {
    await this.jobService.runJob<UpdateMergeFunctionPdCategoryJob, MergeFunctionUm4PdCategoryReq, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
