<div class="export-button" [class.expanded]="isMenuExpanded">
  <div class="menu-button">
    <button class="exportBtn" [disabled]="disabled" (click)="onExpandMenuClicked($event)">
      <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-download" aria-hidden="true"></span>
      <span> &nbsp;{{title}} &nbsp;</span>
      <mat-icon *ngIf="!isMenuExpanded">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="isMenuExpanded">keyboard_arrow_up</mat-icon>
    </button>

    <ul *ngIf="isMenuExpanded">
      <li *ngFor="let i of items" (click)="onButtonClicked($event, i)">
        <p>{{i.text}}</p>
      </li>
    </ul>
  </div>
</div>
