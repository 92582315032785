import { Injectable } from "@angular/core";
import { BaseTemplatesApiClient } from "../api-clients/BaseTemplatesApiClient";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { TemplateEditorFileModel } from "../types/helpers/TemplateEditorFileModel";
import { BaseTemplateDdm } from "src/app/types/dataModels/BaseTemplateDdm";
import { OwnFile } from "src/app/types/helpers/OwnFile";

@Injectable({ providedIn: "root" })
export class UpdateATemplateJob4Content implements IJob<TemplateEditorFileModel, VoidResult> {

  constructor(private baseTemplatesApiClient: BaseTemplatesApiClient) { }

  async execute(arg: TemplateEditorFileModel): Promise<VoidResult> {

    let baseTemplateDdm = new BaseTemplateDdm();
    baseTemplateDdm.id = arg.id;
    baseTemplateDdm.name = arg.name;// + '.tx'
    baseTemplateDdm.description = arg.description;
    let ownFile = new OwnFile();
    ownFile.fileName = arg.name;// + '.tx';
    ownFile.contentType = 'application/text-control';
    ownFile.content = arg.content;
    baseTemplateDdm.file = ownFile;

    let templateExtension = arg.documentType;

    await this.baseTemplatesApiClient.updateSourceFileContent(baseTemplateDdm, templateExtension);
    return new VoidResult();
  }
}






