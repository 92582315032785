import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { DocumentFormatOvm } from "src/app/types/dataModels/DocumentFormatOvm";
import { GetMergeFunctionDocumentFormatsByIdJob } from "../jobs/GetMergeFunctionDocumentFormatsByIdJob";

@Injectable({ providedIn: 'root' })
export class GetMergeFunctionDocumentFormatsCmdlet extends CmdletBase<number, DocumentFormatOvm[]> {

  constructor(private jobService: JobService, private job: GetMergeFunctionDocumentFormatsByIdJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: number): Promise<DocumentFormatOvm[]> {
    let result = await this.jobService.runJob<GetMergeFunctionDocumentFormatsByIdJob, number, DocumentFormatOvm[]>(this.job, arg);
    return result;
  }
}
