import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentService } from 'src/app/services/ComponentService';
import { DialogComponentBase } from '../DialogComponentBase';
import { HttpClient } from '@angular/common/http'; // Used to load JSON files
import { SessionService } from '../../api-clients/session.service';
import { MarkdownService } from '../../services/markdown.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent extends DialogComponentBase<HelpComponent, boolean> implements OnInit {
  formGroup: FormGroup;
  helpText: string = ''; // Holds the loaded markdown text
  url: string;

  constructor(
    componentService: ComponentService,
    private markdownService: MarkdownService,
    private http: HttpClient, // Inject HttpClient for loading JSON files
    private sessionService: SessionService, // Inject SessionService to get user's culture
    dialogRef: MatDialogRef<HelpComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) private data: { id: string } // Inject id from dialog data
  ) {
    super(componentService, dialogRef);
  }

  async ngOnInit() {    
    
    // Get the user's culture from SessionService, default to 'en-US' if not available
    const userCulture = this.sessionService.session.culture || 'nl-NL';

    // Determine which JSON file to load based on the user's language
    const language = userCulture.startsWith('nl') ? 'nl' : 'en';
    const helpFilePath = `assets/help-${language}.json`;

    // Load the correct JSON file using HttpClient
    this.http.get<any>(helpFilePath).subscribe(helpData => {
      // Use the id from dialog data (data.id) to retrieve the correct help text
      const helpTextId = this.data.id;

      // Load the markdown text from the JSON file if available
      if (helpTextId && helpData[helpTextId]) {
        const helpTextMd = helpData[helpTextId]; // Set the markdown content
        this.helpText = this.markdownService.convert(helpTextMd); // Convert markdown to HTML
      } else {
        this.helpText = 'Help text not available'; // Show default help text if not found
      }
    });

    this.isLoaded = true;
  }

  async onFormSubmitted() {}

  // Closes the dialog
  onCloseWizardClicked(event): void {
    this.close();
  }
}
