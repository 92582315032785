import { FileTypeEnum } from "src/app/common/types/enums/FileTypeEnum";

export class TemplateContext {
  id?: number;
  name: string;
  description: string;
  fileType: FileTypeEnum;
  folderId?: number;
  departmentId?: number;
  //placeholders: TemplatePlaceholderModel[];
}
