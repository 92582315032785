import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { MergeFunctionOvm } from "src/app/types/dataModels/MergeFunctionOvm";
import { GetMergeFunctionByIdJob } from "../jobs/GetMergeFunctionByIdJob";

@Injectable({ providedIn: 'root' })
export class GetMergeFunctionCmdlet extends CmdletBase<{ id: number, forceRefresh: boolean }, MergeFunctionOvm> {

  constructor(private jobService: JobService, private job: GetMergeFunctionByIdJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: { id: number, forceRefresh: boolean }): Promise<MergeFunctionOvm> {
    let result = await this.jobService.runJob<GetMergeFunctionByIdJob, { id: number, forceRefresh: boolean }, MergeFunctionOvm>(this.job, arg);
    return result;
  }
}
