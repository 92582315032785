<main class="container">
    <div class="error-page">
        <div class="error-page-content">
            <h1>{{errorText}}</h1>            
            <p *ngIf="!isFatal" class="error-page-home-link">
                <a class="btn btn-primary" href="/">Ga terug</a>
            </p>            
        </div>
        <div class="error-page-image">
            <img [src]="imageUrl" alt="Error illustration">
        </div>
    </div>
</main>