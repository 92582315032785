<div class="user-messages">

  <p class="placeholder" *ngIf="!errorMessage && !successMessage && !warningMessage">
    &nbsp;
  </p>

  <p class="error-message" *ngIf="errorMessage">
    {{errorMessage}}
  </p>

  <p class="success-message" *ngIf="successMessage">
    {{successMessage}}
  </p>

  <p class="warning-message" *ngIf="warningMessage">
    {{warningMessage}}
  </p>

</div>