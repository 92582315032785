import { OnInit, Directive } from '@angular/core';
import { ComponentService } from '../../services/ComponentService';

@Directive()
export abstract class ComponentBase implements OnInit {

    isLoaded: boolean = false;
    isDialogShown: boolean = false;
    isLoadingField: boolean = false;

    constructor(private componentService: ComponentService) {
    }

    ngOnInit(): void {
        this.componentService.clearAlerts();
    }

    get isLoading(): boolean {
        return this.componentService.isLoading;
    }

    set isLoading(value: boolean) {
        this.componentService.isLoading = value;
    }

    showError(error: any): void {
        this.componentService.showError(error);
    }

    clearAlerts(): void {
        this.componentService.clearAlerts();
    }
}
