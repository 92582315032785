import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-logged-out',
  templateUrl: './logged-out.component.html',
  styleUrls: ['./logged-out.component.scss']
})
export class LoggedOutComponent {

  constructor(private translate: TranslateService,
    private titleService: Title) { 
    this.translate.get('pageTitles.loggedOut').subscribe((title: string) => {
      this.titleService.setTitle(title);  
    });
  }
}
