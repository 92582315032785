import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { CategoryOvm } from "src/app/types/dataModels/CategoryOvm";
import { GetMergeFunctionCategoriesByIdJob } from "../jobs/GetMergeFunctionCategoriesByIdJob";

@Injectable({ providedIn: 'root' })
export class GetMergeFunctionCategoriesCmdlet extends CmdletBase<number, CategoryOvm[]> {

  constructor(private jobService: JobService, private job: GetMergeFunctionCategoriesByIdJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: number): Promise<CategoryOvm[]> {
    let result = await this.jobService.runJob<GetMergeFunctionCategoriesByIdJob, number, CategoryOvm[]>(this.job, arg);
    return result;
  }
}
