<div class="toolbar-component">
  <div class="left-panel">
    <span aria-hidden="true" class="visma-pictogram-plus visma-pictogram-plus-user-search"></span>
    <span>{{ 'common.title' | translate }}</span>
  </div>
  <div class="right-panel">
    <ul>
      <li>
        <a class="toolbarIcon">
          <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-print" (click)="printPage()" [title]="'common.print' | translate"
            aria-hidden="true"></span>
        </a>
      </li>
      <!-- Help button is only shown if 'hasHelpText' is true -->
      <li *ngIf="hasHelpText">
        <a class="toolbarIcon">
          <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-help" (click)="onHelpClicked()" [title]="'common.help' | translate"
            aria-hidden="true"></span>
        </a>
      </li>
    </ul>
  </div>
</div>
