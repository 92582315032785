import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { CategoryOvm } from "src/app/types/dataModels/CategoryOvm";

@Injectable({ providedIn: 'root' })
export class GetMergeFunctionCategoriesByIdJob implements IJob<number, CategoryOvm[]> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: number): Promise<CategoryOvm[]> {
    const categoriesData = await this.mergeFunctionsApiClient.getMergeFunctionCategories(arg);

    const categoriesOvm: CategoryOvm[] = categoriesData.categories.map(data => {
      const category = new CategoryOvm();
      return Object.assign(category, data);
    });
    
    return categoriesOvm;
  }
}
