import { Injectable } from "@angular/core";
import { RaetTemplateOm } from "src/app/raet-templates/types/RaetTemplateOm";
import { SessionStorageClient } from "src/app/services/SessionStorageClient";
import { MergeFunctionOdm } from "src/app/types/dataModels/MergeFunctionOdm";
import { ModuleDdm } from "src/app/types/dataModels/ModuleDdm";
import { TemplateDdm } from "src/app/types/dataModels/TemplateDdm";
import { BaseTemplateOvm } from "src/app/types/viewModels/BaseTemplateOvm";
import { TemplateOvm } from "src/app/types/viewModels/TemplateOvm";

@Injectable({ providedIn: "root" })
class ProcessingStorageProxy {

  constructor(private storage: SessionStorageClient) { }

  get module(): ModuleDdm {
    return this.storage.getObject<ModuleDdm>("module", ModuleDdm);
  }

  set module(value: ModuleDdm) {
    if (value) {
      this.storage.saveObject("module", value);
    } else {
      this.storage.removeObject("module");
    }
  }


  get mergeFunction(): MergeFunctionOdm {
    return this.storage.getObject<MergeFunctionOdm>("mergeFunction", MergeFunctionOdm);
  }

  set mergeFunction(value: MergeFunctionOdm) {
    if (value) {
      this.storage.saveObject("mergeFunction", value);
    } else {
      this.storage.removeObject("mergeFunction");
    }
  }


  get template(): TemplateOvm {
    return this.storage.getObject<TemplateOvm>("template", TemplateOvm);
  }

  set template(value: TemplateOvm) {
    if (value) {
      this.storage.saveObject("template", value);
    } else {
      this.storage.removeObject("template");
    }
  }


  get baseTemplate(): BaseTemplateOvm {
    return this.storage.getObject<BaseTemplateOvm>("baseTemplate", BaseTemplateOvm);
  }

  set baseTemplate(value: BaseTemplateOvm) {
    if (value) {
      this.storage.saveObject("baseTemplate", value);
    } else {
      this.storage.removeObject("baseTemplate");
    }
  }

  get raetTemplate(): RaetTemplateOm {
    return this.storage.getObject<RaetTemplateOm>("raetTemplate", RaetTemplateOm);
  }

  set raetTemplate(value: RaetTemplateOm) {
    if (value) {
      this.storage.saveObject("raetTemplate", value);
    } else {
      this.storage.removeObject("raetTemplate");
    }
  }
}

@Injectable({ providedIn: "root" })
export class ProcessingStorage {

  constructor(private processingStorage: ProcessingStorageProxy) { }

  get module(): ModuleDdm {
    return this.processingStorage.module;
  }

  set module(value: ModuleDdm) {
    this.processingStorage.module = value;
  }


  get mergeFunction(): MergeFunctionOdm {
    return this.processingStorage.mergeFunction;
  }

  set mergeFunction(value: MergeFunctionOdm) {
    this.processingStorage.mergeFunction = value;
  }


  get template(): TemplateOvm {
    return this.processingStorage.template;
  }

  set template(value: TemplateOvm) {
    this.processingStorage.template = value;
  }


  get baseTemplate(): BaseTemplateOvm {
    return this.processingStorage.baseTemplate;
  }

  set baseTemplate(value: BaseTemplateOvm) {
    this.processingStorage.baseTemplate = value;
  }


  get raetTemplate(): RaetTemplateOm {
    return this.processingStorage.raetTemplate;
  }

  set raetTemplate(value: RaetTemplateOm) {
    this.processingStorage.raetTemplate = value;
  }
}
