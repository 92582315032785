import { Injectable } from "@angular/core";
import { BaseTemplatesApiClient } from "../api-clients/BaseTemplatesApiClient";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";

@Injectable({ providedIn: "root" })
export class DeleteATemplateJob implements IJob<number, VoidResult> {

    constructor(private apiClient: BaseTemplatesApiClient) { }

    async execute(id: number): Promise<VoidResult> {
        await this.apiClient.deleteSourceFile(id);
        return new VoidResult();
    }
}
