import { Injectable } from "@angular/core";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { AlertService } from "src/app/services/AlertService";
import { ComponentService } from "src/app/services/ComponentService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { UpdateATemplateJob4Meta } from "../jobs/UpdateATemplateJob4Meta";
import { ASourceFileUm4Content } from "src/app/common/types/helpers/ASourceFileUm4Content";
import { UpdateATemplateJob4Content } from "../jobs/UpdateATemplateJob4Content";
import { TemplateEditorFileModel } from "../types/helpers/TemplateEditorFileModel";

@Injectable({ providedIn: "root" })
export class UpdateATemplateCmdlet4Content extends CmdletBase<TemplateEditorFileModel, VoidResult> {

  constructor(private jobService: JobService,
    private job: UpdateATemplateJob4Content,
    private alertService: AlertService,
    componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: TemplateEditorFileModel): Promise<VoidResult> {
    await this.jobService.runJob<UpdateATemplateJob4Content, TemplateEditorFileModel, VoidResult>(this.job, arg);
    this.alertService.success(`Template content has been updated successfully`);
    return new VoidResult();
  }
}
