import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute } from '@angular/router';
import { OverviewComponentBase } from 'src/app/common/components/OverviewComponentBase';
import { TableService, TableConfig } from 'src/app/common/services/TableService';
import { ComponentService } from 'src/app/services/ComponentService';
import { ModuleOvm } from 'src/app/types/viewModels/ModuleOvm';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { GetRaetMergeFunctionsCmdlet } from '../../cmdlets/GetRaetMergeFunctionsCmdlet';

@Component({
  selector: 'app-merge-function-overview',
  templateUrl: './static-merge-function-overview.component.html',
  styleUrls: ['./static-merge-function-overview.component.scss']
})
export class StaticMergeFunctionOverviewComponent extends OverviewComponentBase<MergeFunctionOvm> implements OnInit {
  constructor(
    private router: Router,    
    componentService: ComponentService,    
    private getRaetMergeFunctionsCmdlet: GetRaetMergeFunctionsCmdlet,  
    tableService: TableService<MergeFunctionOvm>) {
    super(tableService, componentService)
  }

  toggle = true;
  toggleStepper = false;
  showMergeFunctions = false;
  helpPageVisibility = false;
  displayedColumns: string[] = ["name", "description", "numberActiveTemplates"];
  searchColumns: string[] = ["name", "description"];  
  showOnlyActive = false;
  isLoaded = false;  
  mergeFunctions: MergeFunctionOvm[];
  moduleChosen: boolean = false;
  
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren('tableRow') tableRowsRef: QueryList<ElementRef>;

  protected get tableRows(): QueryList<ElementRef> {
    return this.tableRowsRef;
  }

  async ngOnInit() {    
    this.sort.active = "name";
    this.sort.direction = "asc";
    this.sort.disableClear = true;

    this.sort.sortChange.subscribe(() => {
      this.refreshView();
    });
    
    super.ngOnInit();    
  }

  onPageIndexChanged(index: number) {
    this.pageIndex = index;
    this.refreshView();
  }

  onshowOnlyActiveChanged(value: boolean) {
    this.reloadView();
  }

  async loadRows(): Promise<MergeFunctionOvm[]> { //abstract loadRows(): Promise<TRow[]>;    
    
    this.mergeFunctions = await this.getRaetMergeFunctionsCmdlet.execute(false);
    this.isLoaded = true;

    return this.filterRows(this.mergeFunctions, this.showOnlyActive);
  }

  onRowMenuButtonClicked(cmd: string, mergeFunction: MergeFunctionOvm) {
    //this.localStorage.mergeFunction = mergeFunction;
    this.router.navigate(['/templates'], { queryParamsHandling: 'merge' });
  }

  onDeleteRowClicked(row: MergeFunctionOvm) {
    throw new Error('Method not implemented.');
  }

  onAddNewRowClicked(mode: string) {
    throw new Error('Method not implemented.');
  }

  onEditRowClicked(row: MergeFunctionOvm) {
    throw new Error('Method not implemented.');
  }

  onViewRowClicked(row: MergeFunctionOvm) {
    throw new Error('Method not implemented.');
  }

  onSelectRowClicked(row: MergeFunctionOvm) {
    this.dataPage.selectRow(row);    
    this.router.navigate(['/static-templates', 'merge-functions', row.id, 'details'], { queryParamsHandling: 'merge' });
  }

  get tableConfig(): TableConfig {
    let config: TableConfig = {
      filterConfig: {
        columns: this.searchColumns,
        searchText: this.searchText,
      },
      sortConfig: {
        sortColumn: this.sort.active,
        sortDirection: this.sort.direction,
      },
      pageConfig: {
        pageIndex: this.pageIndex,
      },
    };
    return config;
  }

  private filterRows(mergeFunctions: MergeFunctionOvm[], showOnlyActive: boolean): MergeFunctionOvm[] {
    if (showOnlyActive == true && mergeFunctions) {
      return mergeFunctions.filter(x => x.numberActiveTemplates != 0)      
    } else {
      return mergeFunctions;
    }
  }
}
