<div class="right-content-container">  
  <div class="page-toolbar">Raet Templates</div>

  <alert></alert>

  <div class="table">

    <div class="table-header">
      <button (click)="onAddNewRowClicked('upload')" class="editButton3">Nieuwe Raet Template</button>
      <app-search-field (textChanged)="onSearchTextChanged($event)"></app-search-field>
    </div>

    <div class="table-body">

      <table [class.hidden]="dataPage.rows.length == 0" mat-table matSort [dataSource]="dataPage.rows"
        class="list-items">
        <!--
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>Id</th>
              <td mat-cell *matCellDef="let element" id="header"> {{element.id}}</td>
            </ng-container> -->

        <ng-container matColumnDef="raetName">
          <th mat-header-cell *matHeaderCellDef>RaetTemplate</th>
          <td class="text-max-100" mat-cell *matCellDef="let element"> {{element.raetName}}</td>
        </ng-container>

        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>Gebruiker</th>
          <td mat-cell *matCellDef="let element"> {{element.createdBy}}</td>
        </ng-container>

        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef>Naam</th>
          <td mat-cell *matCellDef="let element"> {{element.createdBy}}</td>
        </ng-container>

        <ng-container matColumnDef="isActive">
          <th mat-header-cell *matHeaderCellDef>Actief</th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox class="example-margin" [checked]="element.isActive" (click)="$event.stopPropagation()"
              (change)="setRaetTemplateActive(element)"></mat-checkbox>
        </ng-container>

        <ng-container matColumnDef="cmds">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let element" class="buttons-cell">
            <ul>
              <li>
                <button (click)="onEditTemplateClicked($event, element)" class="editButton">Bewerken</button>
              </li>
            </ul>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected]='row.isSelected'
          (click)="onSelectRowClicked(row)"></tr>
      </table>

      <div class="empty-table" [class.displaynone]="dataPage.rows.length > 0" *ngIf="isLoaded">
        Data not found
      </div>

      <div class="empty-table" [class.hidden]="dataPage.rows.length > 0" *ngIf="!isLoaded">
        Laden...
      </div>

    </div>

    <div class="table-footer" [class.displaynone]="dataPage.rows.length == 0">
    </div>
  </div>
</div>