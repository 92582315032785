import { APP_BASE_HREF } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { OpenIdConfiguration } from "angular-auth-oidc-client";
import { authConfig, environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class OpenIdConfigService {

    constructor(@Inject(APP_BASE_HREF) private baseHref: string) { }

    getConfig(): OpenIdConfiguration {
        const config = {
            authority: authConfig.authority,
            redirectUrl: `${window.location.origin}${this.baseHref || '/'}${authConfig.redirectUrl}`,
            postLogoutRedirectUri: `${window.location.origin}${this.baseHref || '/'}sessionended`,
            clientId: authConfig.clientId,
            scope: authConfig.scope,
            responseType: 'code',
            silentRenew: authConfig.silentRenew,
            useRefreshToken: authConfig.useRefreshToken,
            ignoreNonceAfterRefresh: true,
            triggerRefreshWhenIdTokenExpired: false,
            renewTimeBeforeTokenExpiresInSeconds: Math.floor(Math.random() * 120 + 60),
            tokenRefreshInSeconds: 15,            
            disableIdTokenValidation: true,
            disableIatOffsetValidation: true,
            autoUserInfo: authConfig.autoUserInfo === false ? false : true,
            secureRoutes: authConfig.secureRoutes ? [authConfig.secureRoutes] : [environment.backendUrl]
        } as OpenIdConfiguration;

        if (authConfig.wellknownEndpoint) {
            config.authWellknownEndpointUrl = this.baseHref.replace(/\/?$/, authConfig.wellknownEndpoint);
        }
        
        return config;
    }
}
