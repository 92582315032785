import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationApiClient } from "src/app/authentication/api-clients/AuthenticationApiClient";
import { AuthenticationStorage } from "src/app/services/AuthenticationStorage";
import { SessionStorageClient } from "src/app/services/SessionStorageClient";
import CannotAuthenticateUserError from "src/app/types/exceptions/CannotAuthenticateUserError";
import UserTokenExpiredError from "src/app/types/exceptions/UserTokenExpiredError";
import { HttpContentBase } from "src/app/types/helpers/HttpContent";
import { AuthOwnHttpClient } from "./AuthOwnHttpClient";
import { RaetFile } from "src/app/types/dataModels/RaetFile";

@Injectable({ providedIn: "root" })
class OwnHttpService {
  constructor(private httpClient: AuthOwnHttpClient,
    private authenticationClient: AuthenticationApiClient,
    private authenticationStorage: AuthenticationStorage,
    private appStorage: SessionStorageClient) { }

  async send<T>(method: string, relativeUrl: string, payload?: any): Promise<T> {

    try {
      var response = await this.sendImpl<T>(method, relativeUrl, payload);
      return response;
    }
    catch (e) {

      throw e;

      /*             if (e instanceof UserTokenExpiredError) {
                      await this.reload();
                      var response = await this.sendImpl<T>(method, relativeUrl, payload);
                      return response;
                  }
                  else {
                      throw e;
                  } */
    };
  }

  async send2(method: string, relativeUrl: string, payload?: any): Promise<Blob> {

    try {
      var response = await this.sendImpl2(method, relativeUrl, payload);
      return response;
    }
    catch (e) {

      throw e;

      /*             if (e instanceof UserTokenExpiredError) {
                      await this.reload();
                      var response = await this.sendImpl2(method, relativeUrl, payload);
                      return response;
                  }
                  else {
                      throw e;
                  } */
    };
  }

  async send3<T>(method: string, relativeUrl: string, model: any, file: Blob): Promise<T> {

    try {
      var response = await this.sendImpl3<T>(method, relativeUrl, model, file);
      return response;
    }
    catch (e) {

      throw e;

      /*             if (e instanceof UserTokenExpiredError) {
                      await this.reload();
                      var response = await this.sendImpl3<T>(method, relativeUrl, model, file);
                      return response;
                  }
                  else {
                      throw e;
                  } */
    };
  }

  async getMultipart(relativeUrl: string): Promise<HttpContentBase[]> {
    try {
      const response = await this.getMultipartImpl(relativeUrl);
      return response;
    }
    catch (e) {

      throw e;


      /*             if (e instanceof UserTokenExpiredError) {
                      await this.reload();
                      const response = await this.getMultipartImpl(relativeUrl);
                      return response;
                  }
                  else {
                      throw e;
                  } */
    };
  }

  async getMultipart2(relativeUrl: string): Promise<RaetFile> {
    try {
      const response = await this.getMultipartImpl2(relativeUrl);
      return response;
    }
    catch (e) {

      throw e;


      /*             if (e instanceof UserTokenExpiredError) {
                      await this.reload();
                      const response = await this.getMultipartImpl(relativeUrl);
                      return response;
                  }
                  else {
                      throw e;
                  } */
    };
  }

  private getMultipartImpl(relativeUrl: string): Promise<HttpContentBase[]> {
    return this.httpClient.getMultipart(relativeUrl);
  }

  private getMultipartImpl2(relativeUrl: string): Promise<RaetFile> {
    return this.httpClient.getMultipart2(relativeUrl);
  }

  private async sendImpl3<T>(method: string, relativeUrl: string, model: any, file: Blob): Promise<T> {
    let response = await this.httpClient.send3<T>(method, relativeUrl, model, file);
    return response;
  }

  private async sendImpl2(method: string, relativeUrl: string, payload?: any): Promise<Blob> {
    let response = await this.httpClient.send2(method, relativeUrl, payload);
    return response;
  }

  private async sendImpl<T>(method: string, relativeUrl: string, payload?: any): Promise<T> {
    let response = await this.httpClient.send<T>(method, relativeUrl, payload);
    return response;
  }

  private async reload(): Promise<any> {
    try {
      let newUserTokenDvm = await this.authenticationClient.refreshUserToken();
      this.authenticationStorage.authToken = newUserTokenDvm;
      return newUserTokenDvm;
    }
    catch (error) {
      console.log("User token has expired");
      this.appStorage.removeAllObjects();
      throw new UserTokenExpiredError();
    }
  }
}


@Injectable({ providedIn: "root" })
export class ApiEndpointClient {
  constructor(
    private httpService: OwnHttpService,
    private router: Router,
    private appStorage: SessionStorageClient) { }

  async getFile(relativeUrl: string): Promise<Blob> {
    var result = await this.send2("GET", relativeUrl);
    return result;
  }

  async get<T>(relativeUrl: string): Promise<T> {
    return await this.send<T>("GET", relativeUrl);
  }

  async delete<T>(relativeUrl: string, payload?: any): Promise<T> {
    return await this.send<T>("DELETE", relativeUrl, payload);
  }

  async post<T>(relativeUrl: string, payload?: any): Promise<T> {
    return await this.send<T>("POST", relativeUrl, payload);
  }

  async post2<T>(relativeUrl: string, model: any, file: Blob): Promise<T> {
    return await this.send3<T>("POST", relativeUrl, model, file);
  }

  async put<T>(relativeUrl: string, payload?: any): Promise<T> {
    return await this.send<T>("PUT", relativeUrl, payload);
  }

  async put2<T>(relativeUrl: string, model: any, file: Blob): Promise<T> {
    return await this.send3<T>("PUT", relativeUrl, model, file);
  }

  async getMultipart(relativeUrl: string): Promise<HttpContentBase[]> {

    try {      
      var result = await this.httpService.getMultipart(relativeUrl);      
      return result;
    }
    catch (e) {

      if (e instanceof UserTokenExpiredError || e instanceof CannotAuthenticateUserError) {        
        this.appStorage.removeAllObjects();
        await this.router.navigate(["login"]);
      }

      throw e;
    }
  }

  private async send<T>(method: string, relativeUrl: string, payload?: any): Promise<T> {

    try {
      var result = await this.httpService.send<T>(method, relativeUrl, payload);
      return result;
    }
    catch (e) {

      if (e instanceof UserTokenExpiredError || e instanceof CannotAuthenticateUserError) {        
        this.appStorage.removeAllObjects();
        await this.router.navigate(["login"]);
      }

      throw e;
    }
  }

  private async send2<T>(method: string, relativeUrl: string, payload?: any): Promise<Blob> {

    try {
      var result = await this.httpService.send2(method, relativeUrl, payload);


      return result;
    }
    catch (e) {

      if (e instanceof UserTokenExpiredError || e instanceof CannotAuthenticateUserError) {        
        this.appStorage.removeAllObjects();
        await this.router.navigate(["/login"]);
        throw e;
      }

      throw e;
    }
  }

  private async send3<T>(method: string, relativeUrl: string, model: any, file: Blob): Promise<T> {

    try {
      var result = await this.httpService.send3<T>(method, relativeUrl, model, file);
      return result;
    }
    catch (e) {

      if (e instanceof UserTokenExpiredError || e instanceof CannotAuthenticateUserError) {        
        this.appStorage.removeAllObjects();
        await this.router.navigate(["/login"]);
      }

      throw e;
    }
  }


  async getMultipart2(relativeUrl: string): Promise<RaetFile> {

    try {      
      var result = await this.httpService.getMultipart2(relativeUrl);      
      return result;
    }
    catch (e) {

      if (e instanceof UserTokenExpiredError || e instanceof CannotAuthenticateUserError) {        
        this.appStorage.removeAllObjects();
        await this.router.navigate(["login"]);
      }

      throw e;
    }
  }
}
