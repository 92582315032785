import { Injectable } from '@angular/core';
import { EditorEventBus } from '../services/EditorEventBus';
import { SetupEditorReq } from "../types/helpers/SetupEditorReq";
import { EditorInfo } from '../types/helpers/EditorInfo';
import { LoadEditorCmdlet } from '../cmdlets/LoadEditorCmdlet';
import { SetupEditorCmdlet } from '../cmdlets/SetupEditorCmdlet';
import { ExtractFileContentAsWordCmdlet } from '../cmdlets/ExtractFileContentAsWordCmdlet';
import { GetEditorInfoCmdlet } from '../cmdlets/GetEditorInfoCmdlet';
import { VoidResult } from 'src/app/types/helpers/VoidResult';

export declare const TXTextControl: any;

@Injectable({ providedIn: 'root' })
export default class TextControlManager {

  constructor(
    // private getTextControlDirectivesCmdlet: GetTextControlDirectivesCmdlet,
    // private getTextControlDirectiveCmdlet: GetTextControlDirectiveCmdlet,
    private loadEditorCmdlet: LoadEditorCmdlet,
    private editorEventBus: EditorEventBus,
    private setEditorModeCmdlet: SetupEditorCmdlet,
    private extractFileContentCmdlet: ExtractFileContentAsWordCmdlet,
    // private createFormFieldCmdlet: CreateTextEditorDirectiveCmdlet,
    private getEditorInfoCmdlet: GetEditorInfoCmdlet,
    // private replaceEditorDirectiveWithTextCmdlet: ReplaceTextControlDirectiveWithTextCmdlet,
    // private replaceEditorDirectiveWithRadioButtonsCmdlet: ReplaceTextControlDirectiveWithRadioButtonsCmdlet,
    // private replaceEditorDirectiveWithCheckBoxesCmdlet: ReplaceTextControlDirectiveWithCheckBoxesCmdlet,
    // private replaceEditorDirectiveWithFileCmdlet: ReplaceTextControlDirectiveWithFileCmdlet,
    // private deleteTextControlDirectiveCmdlt: DeleteTextControlDirectiveCmdlet
  ) {
  }

  async loadFile(fileContent: string, isReadMode: boolean): Promise<VoidResult> {
    await this.loadEditorCmdlet.run(fileContent);
    const setuEditorReq: SetupEditorReq = new SetupEditorReq(isReadMode);
    await this.setEditorModeCmdlet.run(setuEditorReq);

    // TXTextControl.removeEventListener('textFieldClicked', (e) => this.onTextFielldClicked(e));
    // TXTextControl.addEventListener('textFieldClicked', (e) => this.onTextFielldClicked(e));

    TXTextControl.removeEventListener('textDropped', (e) => this.onTextDroppedFired(e));
    TXTextControl.addEventListener('textDropped', (e) => this.onTextDroppedFired(e));

    return new VoidResult();
  }

  getEditorInfo(): Promise<EditorInfo> {
    return this.getEditorInfoCmdlet.run();
  }

  // async getDirectives(): Promise<TextControlDirective[]> {
  //   const result = await this.getTextControlDirectivesCmdlet.run();
  //   return result;
  // }

  // async getDirective(name: string): Promise<TextControlDirective> {
  //   return this.getTextControlDirectiveCmdlet.run(name);
  // }

  // async deleteDirective(arg: TextControlDirective): Promise<VoidResult> {
  //   let result = await this.deleteTextControlDirectiveCmdlt.run(arg.name);
  //   return result;
  // }

  // async createDirective(name: string, text: string): Promise<VoidResult> {
  //   let req: CreateEditorDirectiveReq = { name, text };
  //   let result = await this.createFormFieldCmdlet.run(req);
  //   return result;
  // }

  // async replaceDirectiveWithText(d: TextControlDirective, text: string): Promise<VoidResult> {
  //   const req = new ReplaceEditorDirectiveWithTextReq(d, text);
  //   let result = await this.replaceEditorDirectiveWithTextCmdlet.run(req);
  //   return result;
  // }

  // async replaceDirectiveWithRadioButtons(d: TextControlDirective, listName: string, options: SelectOption2[]): Promise<VoidResult> {
  //   const req = new ReplaceEditorDirectiveWithRadioButtonsReq(d, listName, options);
  //   let result = await this.replaceEditorDirectiveWithRadioButtonsCmdlet.run(req);
  //   return result;
  // }

  // async replaceDirectiveWithCheckBoxes(d: TextControlDirective, listName: string, options: SelectOption2[]): Promise<VoidResult> {
  //   const req = new ReplaceEditorDirectiveWithCheckBoxesReq(d, listName, options);
  //   let result = await this.replaceEditorDirectiveWithCheckBoxesCmdlet.run(req);
  //   return result;
  // }

  // async replaceDirectiveWithFile(req: ReplaceEditorDirectiveWithFileReq): Promise<VoidResult> {
  //   let result = await this.replaceEditorDirectiveWithFileCmdlet.run(req);
  //   return result;
  // }

  async extractFileContentAsWord(): Promise<string> {
    let result = await this.extractFileContentCmdlet.run();
    return result;
  }

  onTextDroppedFired(e: any) {
    console.log("On text dropped fired...");
    TXTextControl.inputPosition.getLocation(d => console.log(d));
    e.preventDefault();
  }

  // private async onTextFielldClicked(event: any) {
  //   const name = event.textField.name;

  //   if (!name) {
  //     return;
  //   }

  //   console.debug(`Editor: directive with name='${name}' clicked`);
  //   const ev: EditorDirectiveSelectedEvent = new EditorDirectiveSelectedEvent();
  //   ev.name = name;
  //   this.editorEventBus.directiveSelected.next(ev);
  // }
}
