import { OwnFile } from "./OwnFile";

export abstract class HttpContentBase {
  content: string;
  abstract get contentType();
}

export class JsonHttpContent extends HttpContentBase {

  get contentType(): any {    
    return "application/json";
  }
}

export class BlobHttpContent extends HttpContentBase {
  fileName: string;

  get contentType(): any {


    const index = this.fileName.lastIndexOf('.');
    const extension = this.fileName.substr(index);

    if (extension === ".tx") {
      return "application/text-control";
    }

    if (extension === ".docx") {
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }

    if (extension === ".doc") {
      return "application/msword";
    }

    return "application/text-control";
    //return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  }

  getFile(): OwnFile {
    const result = new OwnFile();
    result.contentType = this.contentType;
    result.fileName = this.fileName;
    result.content = this.content;
    return result;
  }
}
