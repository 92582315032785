import { Injectable } from '@angular/core';
import { AuthOwnHttpClient } from 'src/app/common/api-clients/AuthOwnHttpClient';
import { ApiEndpointClient } from 'src/app/common/api-clients/ApiEndpointClient';
import { BaseTemplateOdm } from 'src/app/types/dataModels/BaseTemplateOdm';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { BaseTemplateIm } from 'src/app/types/dataModels/BaseTemplateIdm';
import { BlobHttpContent, JsonHttpContent } from 'src/app/types/helpers/HttpContent';
import { ASourceFileUm4Meta } from 'src/app/common/types/helpers/ASourceFileUm4Meta';
import { BaseTemplateDdm } from 'src/app/types/dataModels/BaseTemplateDdm';
import { BaseTemplateUm4ActiveState } from '../types/helpers/BaseTemplateUm4ActiveState';
import { DownloadSourceFileReq } from '../types/helpers/DownloadSourceFileReq';

@Injectable({ providedIn: "root" })
export class BaseTemplatesApiClient {

  constructor(private apiEndpointClient: ApiEndpointClient, private httpClient: AuthOwnHttpClient) { }

  async getBaseTemplates(): Promise<BaseTemplateOdm[]> {

    let url: string = '/basetemplate';

    let data = await this.apiEndpointClient.get<BaseTemplateOdm[]>(url);    
    return data;
  }

  async getBaseTemplate(id: number): Promise<BaseTemplateOdm> {
    let url: string = '/basetemplate/' + id + '/meta';
    let data = await this.apiEndpointClient.get<BaseTemplateOdm>(url);    
    return data;
  }

  async getBaseTemplateMeta(id: number): Promise<BaseTemplateOdm> {
    let url: string = '/basetemplate/' + id + '/meta';
    let data = await this.apiEndpointClient.get<BaseTemplateOdm>(url);    
    return data;
  }

  async createBaseTemplate(baseTemplate: BaseTemplateIm, templateExtension: string): Promise<VoidResult> {
    let baseUrl = "/basetemplate";

    let blobContent = new BlobHttpContent();
    blobContent.fileName = baseTemplate.name + '.' + templateExtension;
    blobContent.content = baseTemplate.file.content;

    let jsonContent = new JsonHttpContent();
    jsonContent.content = JSON.stringify(baseTemplate);

    await this.httpClient.postMultipart(baseUrl, jsonContent, blobContent);
    return new VoidResult();
  }

  async updateSourceFileMeta(m: ASourceFileUm4Meta): Promise<void> {
    let url = `/basetemplate/${m.id}/meta`;
    await this.apiEndpointClient.put<number>(url, m);
  }

  async updateSourceFileContent(baseTemplate: BaseTemplateDdm, templateExtension: string): Promise<void> {
    let url = `/basetemplate/${baseTemplate.id}/content`;
    let blobContent = new BlobHttpContent();
    blobContent.fileName = baseTemplate.name + '.' + templateExtension;
    blobContent.content = baseTemplate.file.content;
    let jsonContent = new JsonHttpContent();
    jsonContent.content = JSON.stringify({ id: baseTemplate.id });
    await this.httpClient.putMultipart(url, jsonContent, blobContent);
  }

  async getSourceFileContent(id: number, version: string = null): Promise<Blob> {
    let url = `/basetemplate/${id}/content`;
    let data = await this.apiEndpointClient.getFile(url);
    return data;
  }

  async getSourceFileContent2(arg: DownloadSourceFileReq): Promise<Blob> {    
    let url = `/template/${arg.templateId}/documentType/${arg.documentType}/blob`
    let data = await this.apiEndpointClient.getFile(url);    
    return data;
  }

  async deleteSourceFile(id: number): Promise<void> {
    let url = `/basetemplate/${id}`;
    await this.apiEndpointClient.delete<void>(url);
  }

  async updatebaseTemplateStatus(m: BaseTemplateUm4ActiveState): Promise<void> {
    let url = `/basetemplate/${m.templateId}/active`;
    await this.apiEndpointClient.put<number>(url, m);
  }
}

