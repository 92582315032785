import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { OverviewComponentBase } from 'src/app/common/components/OverviewComponentBase';
import { TableService, TableConfig } from 'src/app/common/services/TableService';
import { GetModulesCmdlet } from 'src/app/modules/cmdlets/GetModulesCmdlet';
import { ComponentService } from 'src/app/services/ComponentService';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { TemplateOvm } from 'src/app/types/viewModels/TemplateOvm';
import { GetMergeFunctionCmdlet } from '../../cmdlets/GetMergeFunctionCmdlet';
import { DocumentFormatOvm } from 'src/app/types/dataModels/DocumentFormatOvm';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { UpdateMergeFunctionDocumentFormatCmdlet } from '../../cmdlets/UpdateMergeFunctionDocumentFormatCmdlet';
import { DocumentFormatReq } from 'src/app/types/dataModels/DocumentFormatReq';
import { MergeFunctionUm4PdCategoryReq } from 'src/app/types/dataModels/MergeFunctionUm4PdCategoryReq';
import { UpdateMergeFunctionPdCategoryCmdlet } from '../../cmdlets/UpdateMergeFunctionPdCategoryCmdlet';
import { TemplateAddStartComponent } from 'src/app/common/components/template-add-start/template-add-start.component';
import { UpdateMergeFunctionDefaultTemplateCmdlet } from '../../cmdlets/UpdateMergeFunctionDefaultTemplateCmdlet';
import { UpdateMergeFunctionTemplateByIdCmdlet } from '../../cmdlets/UpdateMergeFunctionTemplateByIdCmdlet';
import { MergeFunctionUm4TemplateReq } from '../../types/MergeFunctionUm4TemplateReq';
import { MergeFunctionUm4DefaultTemplateReq } from '../../types/MergeFunctionUm4DefaultTemplateReq';
import { CategoryOvm } from 'src/app/types/dataModels/CategoryOvm';
import { GetMergeFunctionCategoriesCmdlet } from '../../cmdlets/GetMergeFunctionCategoriesCmdlet';
import { GetMergeFunctionDocumentFormatsCmdlet } from '../../cmdlets/GetMergeFunctionDocumentFormatsCmdlet';
import { GetMergeFunctionTemplatesCmdlet } from '../../cmdlets/GetMergeFunctionTemplatesCmdlet';
import { AlertService } from 'src/app/common/services/AlertService';
import { AlertTypeEnum } from 'src/app/common/types/enums/alertType.enum';
import { TranslateService } from '@ngx-translate/core';
import { MergeFunctionsApiClient } from '../../api-clients/MergeFunctionsApiClient';
import { SessionService } from 'src/app/common/api-clients/session.service';
import { UserSession } from 'src/app/common/types/models/user-session.model';
import { Breadcrumb } from 'src/app/common/components/bread-crumbs/bread-crumbs.component';
import { AppInsightsLoggerService } from 'src/app/services/logging.service';
import { Title } from '@angular/platform-browser';
import { MatRow } from '@angular/material/table';
import { ViewportScroller, Location } from '@angular/common';

@Component({
  selector: 'app-merge-function-details',
  templateUrl: './merge-function-details.component.html',
  styleUrls: ['./merge-function-details.component.scss']
})
export class MergeFunctionDetailsComponent extends OverviewComponentBase<TemplateOvm> implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    componentService: ComponentService,
    private mergeFunctionsApi: MergeFunctionsApiClient,
    private getModulesCmdlet: GetModulesCmdlet,    
    private titleService: Title,    
    private getMergeFunctionCmdlet: GetMergeFunctionCmdlet,
    private getMergeFunctionTemplatesCmdlet: GetMergeFunctionTemplatesCmdlet,
    private getMergeFunctionCategoriesCmdlet: GetMergeFunctionCategoriesCmdlet,
    private getMergeFunctionDocumentFormatsCmdlet: GetMergeFunctionDocumentFormatsCmdlet,
    private updateMergeFunctionDocumentFormatCmdlet: UpdateMergeFunctionDocumentFormatCmdlet,
    private updateMergeFunctionDefaultTemplateCmdlet: UpdateMergeFunctionDefaultTemplateCmdlet,
    private updateMergeFunctionTemplateByIdCmdlet: UpdateMergeFunctionTemplateByIdCmdlet,
    private updateMergeFunctionPdCategoryCmdlet: UpdateMergeFunctionPdCategoryCmdlet,
    private viewportScroller: ViewportScroller,
    private cdr: ChangeDetectorRef,
    private location: Location,
    public componentContext: ComponentContext,
    private logger: AppInsightsLoggerService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private userSession: SessionService,    
    tableService: TableService<TemplateOvm>) {
    super(tableService, componentService)    
  }

  isLoaded = false;
  toggle = true;
  toggleStepper = false;
  showMergeFunctions = false;
  helpPageVisibility = false;
  displayedColumns: string[] = ["name", "description", "isActive", "isDefault", "bewerken"];
  displayedColumnsForFileFormats: string[] = ["extension", "description", "isActive", "isDefault"];
  searchColumns: string[] = ["name", "description"];    
  moduleId: number;
  moduleName: string;  
  mergeFunction: MergeFunctionOvm;  
  mergeFunctionId: number;  
  selectedCategory: string;
  documentFormats: DocumentFormatOvm[] = null;
  categories: CategoryOvm[];
  categoriesNotAvailable: boolean = false;
  showFileFormats = false;
  defaultTemplate: TemplateOvm
  templateListChanged = false;
  defaultDocumentFormatId: number | null;  
  myUser: UserSession;
  globalMode: boolean = false;
  breadcrumbs: Breadcrumb[];
  isBusy: boolean = false;
  rowIdToHighlight: number;

  private text_error_retrieving_data: string;
  private text_mergefunctions: string;
  private text_global_mergefunctions: string;
    
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren(MatRow, { read: ElementRef }) tableRowsRef: QueryList<ElementRef>;

  protected get tableRows(): QueryList<ElementRef> {
    return this.tableRowsRef;
  }

  async ngOnInit() {

    this.myUser = this.userSession.session;

    this.sort.active = "name";
    this.sort.direction = "asc";
    this.sort.disableClear = true;

    this.sort.sortChange.subscribe(() => {      
      this.refreshView();
    });

    this.translate.get([
      'errors.retrieving_data',
      'headers.mergefunctions',
      'headers.global_mergefunctions'
    ]).subscribe(translations => {      
      this.text_error_retrieving_data = translations['errors.retrieving_data'];   
      this.text_mergefunctions = translations['headers.mergefunctions'];   
      this.text_global_mergefunctions = translations['headers.global_mergefunctions'];
    });

    this.route.data.subscribe((data) => {      
      this.globalMode = !!data?.isGlobal;      
    });

    this.route.paramMap.subscribe(async params => {      
      this.isLoaded = false;

      this.moduleId = Number(params.get('moduleId'));
      this.mergeFunctionId = Number(params.get('mergeFunctionId'));

      const modules = await this.getModulesCmdlet.execute(false);
      this.moduleName = modules.find(x => x.id == this.moduleId).name;

      try {
        this.mergeFunction = await this.getMergeFunctionCmdlet.execute({id: this.mergeFunctionId, forceRefresh: false});

        this.translate.get(this.globalMode ? 'pageTitles.global_merge_function_details' : 'pageTitles.merge_function_details', { mergeFunctionName: this.mergeFunction.name }).subscribe((title: string) => {
          this.titleService.setTitle(title);
        });

      } catch (error) {
        console.error('Error while loading merge function details:', error);
        this.logger.error('Error while loading merge function details', error);
        this.showAlert(AlertTypeEnum.warning, this.text_error_retrieving_data);        
        return;
      }

      try {
        this.categories = await this.getMergeFunctionCategoriesCmdlet.execute(this.mergeFunctionId);     
      } catch (error) {
        console.error('Error while loading merge function categories:', error);
        this.logger.error('Error while loading merge function categories', error);
        // In case of error, just continue without categories
        // This can happen if the PersonnelFile Api is down
        this.categories = [];
        this.categoriesNotAvailable = true;
      }

      const baseUrl = this.globalMode ? '/global-modules' : '/modules';

      this.breadcrumbs = [
        { label: this.moduleName },
        { label: this.globalMode ? this.text_global_mergefunctions : this.text_mergefunctions, url: [baseUrl, this.moduleId, 'details'] },
        { label: this.mergeFunction.name }
      ];
      
      this.selectedCategory = this.mergeFunction.pdCategory;

      await this.reloadView();

      if (this.allRows) {
        this.defaultTemplate = this.allRows.find(row => row.isDefault);
      }

      super.ngOnInit();

      if (this.rowIdToHighlight) {
        this.highlightRow(this.rowIdToHighlight); 
        this.rowIdToHighlight = null;
      }

      this.isLoaded = true;
    }); 
    
    this.route.queryParamMap.subscribe(params => {
      const sortColumn = params.get('sc');
      const sortDirection = params.get('sd');
      const searchText = params.get('st');
      const templateId = params.get('tid');

      console.info('Query params:', sortColumn, sortDirection, searchText);
  
      if (sortColumn && sortDirection) {
        this.sort.active = sortColumn;
        this.sort.direction = sortDirection as 'asc' | 'desc';
      }
  
      if (searchText) {
        this.searchText = searchText;
      }

      if (templateId) {        
          this.highlightRow(Number(templateId));
          this.rowIdToHighlight = Number(templateId);        
      }
    });    
  }

  showAlert(type: AlertTypeEnum, text: string) {
    this.alertService.setAlert({ type, text });
  }

  async onCategoryChange($event) {
    var mergeFunctionUm4PdCategoryReq = new MergeFunctionUm4PdCategoryReq();
    mergeFunctionUm4PdCategoryReq.mergeFunctionId = this.mergeFunction.id;
    mergeFunctionUm4PdCategoryReq.newName = $event.target.value;    
    await this.updateMergeFunctionPdCategoryCmdlet.execute(mergeFunctionUm4PdCategoryReq);    
  }

  onEditTemplateClicked($event, template: any) {        
    // Force reload to make textcontrol work
    const currentParams = new URLSearchParams(window.location.search);
    const activeParam = currentParams.get('active');

    const baseUrl = this.globalMode ? '/global-modules/' : '/modules/';
    const queryParams = this.getQueryParams();

    // construct string that contains the query params to be added to the url
    // but only add thos that have a value    
    const additionalParams = Object.keys(queryParams).map(key => {
      if (queryParams[key]) {
        return `${key}=${queryParams[key]}`;
      }
    }).join('&');

    document.location.href = baseUrl + this.moduleId + '/merge-functions/' + this.mergeFunction.id + '/templates/' + template.id + '/editor' + (activeParam ? '?active=' + activeParam + '&' + additionalParams : '?' + additionalParams);
  }

  onNewTemplateClicked($event) {
    this.componentContext.isDiaglogShown = true;
    const config: MatDialogConfig = new MatDialogConfig();
    config.data = { mergeFunctionId: this.mergeFunctionId };
    const dialogRef1 = this.dialog.open<TemplateAddStartComponent, any, number>(TemplateAddStartComponent, config);
    
    dialogRef1.afterClosed().subscribe(async (newTemplateId) => {

      if (newTemplateId) {
        this.isLoaded = false;
        this.templateListChanged = true;

        // Clear active search
        this.searchText = '';    
        
        // Clear mergfunction list cache (numberOfActiveTemplates could have changed)
        this.mergeFunctionsApi.clearMergeFunctionsCache();
        await this.reloadView();

        this.highlightRow(newTemplateId);

        this.isLoaded = true;
      }
    });
  }

  async setFileFormatActive(documentFormat: DocumentFormatOvm, newState: boolean) {    
    if (this.isBusy) {
      return;
    }

    console.info('setFileFormatActive ' + documentFormat.id + ' to ' + newState);

    try {
      this.isBusy = true;
      var documentFormatReq = new DocumentFormatReq();
      documentFormatReq.mergeFunctionId = this.mergeFunction.id;
      documentFormatReq.documentFormatId = documentFormat.id;    
      documentFormatReq.isEnabled = newState;    
      documentFormatReq.isDefault = null;    
      await this.updateMergeFunctionDocumentFormatCmdlet.execute(documentFormatReq);   
      documentFormat.isActive = newState;
    } catch (error) {
      console.error('Error while updating the document format:', error);
      this.logger.error('Error while updating the document format', error);
      
      // Revert state in case of error
      this.documentFormats = this.documentFormats.map(row => {
        if (row.id === documentFormat.id) {      
          return { ...row, isActive: !newState };
        }
        return row;
      });
      
      this.cdr.detectChanges();      
    } finally {
      this.isBusy = false;
    }
  }

  async onFileFormatDefaultChange(documentFormat: DocumentFormatOvm) {    
    if (this.isBusy) {
      return;
    }

    try {
      this.isBusy = true;
      var documentFormatReq = new DocumentFormatReq();
      documentFormatReq.mergeFunctionId = this.mergeFunction.id;
      documentFormatReq.documentFormatId = documentFormat.id;
      documentFormatReq.isDefault = true;
      documentFormatReq.isEnabled = true; // The default one should also be active
      await this.updateMergeFunctionDocumentFormatCmdlet.execute(documentFormatReq); 
      
      // Update the table
      this.defaultDocumentFormatId = documentFormat.id;
      this.documentFormats.forEach(row => {
        row.isDefault = row.id === documentFormat.id;
        if (row.isDefault) {
          console.info('Setting row to activbe: ', row);
          row.isActive = true;
        }
      });    
      // Clone this.documentFormats
      //this.documentFormats = [...this.documentFormats];      
      //this.cdr.detectChanges();      
    } catch (error) {
      console.error('Error while updating the document format:', error);
      this.logger.error('Error while updating the document format', error);

      // revert state in case of error
      this.documentFormats.forEach(documentFormat => {        
        documentFormat.isDefault = documentFormat.id === this.defaultDocumentFormatId;        
      });
    } finally {
      this.isBusy = false;
    }
  }

  async setTemplateActive(isActive: boolean, template: TemplateOvm) {     
    if (this.isBusy) {
      return;
    }

    try {
      this.isBusy = true;
      let mergeFunctionUm4TemplateReq = new MergeFunctionUm4TemplateReq();
      mergeFunctionUm4TemplateReq.templateId = template.id;
      mergeFunctionUm4TemplateReq.mergeFunctionId = this.mergeFunctionId;
      mergeFunctionUm4TemplateReq.isDisabled = !isActive;
      await this.updateMergeFunctionTemplateByIdCmdlet.execute(mergeFunctionUm4TemplateReq);

      // Clear mergfunction list cache (numberOfActiveTemplates has changed)
      this.mergeFunctionsApi.clearMergeFunctionsCache();

      this.allRows.forEach(row => {
        if (row.id === template.id) {
          row.isActive = isActive;
        }
      });
    } catch (error) {
      console.error('Error while updating the template:', error);
      this.logger.error('Error while updating the template', error);
    } finally {
      this.isBusy = false;
    }
  }

  async setDefaultTemplate(template: TemplateOvm) {
    if (this.isBusy) {
      return;
    }

    try {      
      this.isBusy = true;
      let mergeFunctionUm4DefaultTemplateReq = new MergeFunctionUm4DefaultTemplateReq();
      mergeFunctionUm4DefaultTemplateReq.mergeFunctionId = this.mergeFunctionId;
      mergeFunctionUm4DefaultTemplateReq.templateId = template.id;
            
      await this.updateMergeFunctionDefaultTemplateCmdlet.execute(mergeFunctionUm4DefaultTemplateReq);

      this.defaultTemplate = template;
            
      this.allRows.forEach(row => {
        row.isDefault = row.id === template.id;
        if (row.isDefault) {
          row.isActive = true;
        }
      });

      this.mergeFunctionsApi.clearMergeFunctionsCache();
      
    } catch (error) {      
      console.error('Error while updating the default template:', error);
      this.logger.error('Error while updating the default template', error);
    } finally {
      this.isBusy = false;
    }
  }

  async resetDefaultTemplate() {
   
    // Do this by setting the template to inactive. This will clear the isDefault flag.
    // Then make it active again
    if (this.defaultTemplate) {
      await this.setTemplateActive(false, this.defaultTemplate);
      await this.setTemplateActive(true, this.defaultTemplate);
    }

    // Update thje table
    this.defaultTemplate = null;            
    this.allRows.forEach(row => {
      row.isDefault = false;       
    });
  }

  onPageIndexChanged(index: number) {    
    this.pageIndex = index;
    this.refreshView();
    // Scroll the body to top
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  async loadRows(): Promise<TemplateOvm[]> {       
    const templates = await this.getMergeFunctionTemplatesCmdlet.execute({ mergeFunctionId: this.mergeFunctionId, global: this.globalMode, forceRefresh: this.templateListChanged });  
    this.isLoaded = true;     
    return templates;
  }

  onDeleteRowClicked(row: TemplateOvm) {
    throw new Error('Method not implemented.');
  }

  onAddNewRowClicked(mode: string) {
    throw new Error('Method not implemented.');
  }

  onEditRowClicked(row: TemplateOvm) {
    throw new Error('Method not implemented.');
  }

  onViewRowClicked(row: TemplateOvm) {
    throw new Error('Method not implemented.');
  }

  get tableConfig(): TableConfig {
    let config: TableConfig = {
      filterConfig: {
        columns: this.searchColumns,
        searchText: this.searchText,
      },
      sortConfig: {
        sortColumn: this.sort.active,
        sortDirection: this.sort.direction,
      },
      pageConfig: {
        pageIndex: this.pageIndex,
      },
    };
    return config;
  }

  onSelectRowClicked(row: TemplateOvm) {
    this.dataPage.selectRow(row);    
    const baseUrl = this.globalMode ? '/global-modules' : '/modules';    
    const queryParams = this.getQueryParams();
    this.router.navigate([baseUrl, this.moduleId, 'merge-functions', this.mergeFunction.id, 'templates', row.id, 'details'], { queryParams, queryParamsHandling: 'merge' });
  }

  onSliderChange($event) {
    // If showFileFormats is true: make sure documentFormats are up te date
    if (this.showFileFormats && this.documentFormats == null) {
      this.updateDocumentFormats();
    }
  }

  private async updateDocumentFormats() {
    this.documentFormats = await this.getMergeFunctionDocumentFormatsCmdlet.execute(this.mergeFunctionId);
    this.documentFormats.sort((a, b) => a.extension.localeCompare(b.extension));
    this.defaultDocumentFormatId = this.documentFormats.find(x => x.isDefault)?.id;
  }

  private getQueryParams() {
    return {
      sc: this.sort.active,
      sd: this.sort.direction,
      st: this.searchText
    };  
  }
  
}
