import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { ModulesApiClient } from "../api-clients/ModulesApiClient";
import { ModuleOvm } from "../../types/viewModels/ModuleOvm";

@Injectable({ providedIn: 'root' })
export class GetModuleByIdJob implements IJob<number, ModuleOvm> {

  constructor(private modulesApiClient: ModulesApiClient) { }

  async execute(arg: number): Promise<ModuleOvm> {
    const moduleOdm = await this.modulesApiClient.getModuleDetails(arg);
    console.log(moduleOdm);
    const moduleOvm = new ModuleOvm();
    const newObject = Object.assign(moduleOvm, moduleOdm);
    console.log(newObject)
    return newObject;
  }
}
