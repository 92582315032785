import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

export enum DialogButtonType {
  Ok = 'ok',
  Cancel = 'cancel',
  Yes = 'yes',
  No = 'no'
}

interface DialogData {
  confirmationText: string;
  buttons: DialogButtonType[];
  defaultButton?: DialogButtonType;
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  DialogButtonType = DialogButtonType; // Om te gebruiken in de template

  buttonDisplayText = {
    [DialogButtonType.Ok]: 'Ok',
    [DialogButtonType.Cancel]: 'Annuleren',
    [DialogButtonType.Yes]: 'Ja',
    [DialogButtonType.No]: 'Nee',
  };

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    // Zorg dat er altijd minimaal één knop is
    if (!data.buttons || data.buttons.length === 0) {
      data.buttons = [DialogButtonType.Ok];
    }
  }

  ngOnInit(): void {
    this.translate.get([     
      'buttons.ok',
      'buttons.cancel',
      'buttons.yes',
      'buttons.no'
    ]).subscribe(translations => {           
      this.buttonDisplayText = {
        [DialogButtonType.Ok]: translations['buttons.ok'],
        [DialogButtonType.Cancel]: translations['buttons.cancel'],
        [DialogButtonType.Yes]: translations['buttons.yes'],
        [DialogButtonType.No]: translations['buttons.no'],
      };
    });
  }

  onButtonClick(buttonType: DialogButtonType): void {
    this.dialogRef.close(buttonType);
  }

  getButtonCssClass(button: DialogButtonType): string {    
    switch (button) {
      case DialogButtonType.Ok:
      case DialogButtonType.Yes:
        return 'primary-button';
      case DialogButtonType.Cancel:
      case DialogButtonType.No:
        return 'optional-button';
      default:
        return 'primary-button';
    }
  }

  isDefaultButton(button: DialogButtonType): boolean {
    return this.data.defaultButton === button;
  }
}
