import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { BaseTemplatesApiClient } from "../api-clients/BaseTemplatesApiClient";
import { BaseTemplateOvm } from "src/app/types/viewModels/BaseTemplateOvm";
import { BaseTemplateOdm } from "src/app/types/dataModels/BaseTemplateOdm";

@Injectable({ providedIn: 'root' })
export class GetBaseTemplateJob implements IJob<number, BaseTemplateOdm> {

  constructor(private baseTemplatesApiClient: BaseTemplatesApiClient) { }

  async execute(id: number): Promise<BaseTemplateOdm> {
    const baseTemplateOvm = await this.baseTemplatesApiClient.getBaseTemplate(id);    
    return baseTemplateOvm;
  }
}
