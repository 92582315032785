import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { GetTemplateBlobJob } from "../jobs/GetTemplateBlobJob";
import { RaetFile } from "src/app/types/dataModels/RaetFile";

@Injectable({ providedIn: 'root' })
export class GetTemplateBlobCmdlet extends CmdletBase<number, Blob> {

  constructor(private jobService: JobService, private job: GetTemplateBlobJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: number): Promise<Blob> {
    let result = await this.jobService.runJob<GetTemplateBlobJob, number, Blob>(this.job, arg);
    return result;
  }
}
