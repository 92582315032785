import { Injectable } from '@angular/core';
import { ITemplateJob } from '../interfaces/ITemplateJob';
import { TemplateContext } from '../types/TemplateContext';


@Injectable({ providedIn: "root" })
export class TemplateJobService {

  constructor() { }

  async runJob<TJob extends ITemplateJob<TArg, TResult>, TArg, TResult>(job: TJob, arg: TArg, context: TemplateContext): Promise<TResult> {

    const jobName = job.constructor.name;

    try {      
      let result = await job.execute(arg, context);      
      return result;
    } catch (error) {      
      throw error;
    }
  }
}
