import { Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { TemplatesApiClient } from '../api-clients/TemplatesApiClient';
import { TemplateVersionFromTemplateIm } from '../types/TemplateVersionFromTemplateIm';

@Injectable({ providedIn: 'root' })
export class CreateTemplateVersionFromIdJob implements IJob<TemplateVersionFromTemplateIm, VoidResult> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(m: TemplateVersionFromTemplateIm): Promise<any> {
    let result = await this.templatesApiClient.createTemplateVersionFromId(m);
    return result;
  }
}
