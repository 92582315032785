import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponentBase } from 'src/app/common/components/DialogComponentBase';
import { JobService } from 'src/app/common/services/JobService';
import { FileTypeEnum } from 'src/app/common/types/enums/FileTypeEnum';
import { SelectOption } from 'src/app/common/types/helpers/SelectOption';
import { ComponentService } from 'src/app/services/ComponentService';
import { BaseTemplateOdm } from 'src/app/types/dataModels/BaseTemplateOdm';
import { GetBaseTemplateJob } from '../../jobs/GetBaseTemplateJob';
import { ASourceFileUm4Meta } from 'src/app/common/types/helpers/ASourceFileUm4Meta';
import { DisplayNameService } from 'src/app/common/services/DisplayNameService';
import { UpdateATemplateCmdlet4Meta } from '../../cmdlets/UpdateATemplateCmdlet4Meta';

@Component({
  selector: 'app-base-template-update-start',
  templateUrl: './base-template-update-start.component.html',
  styleUrls: ['./base-template-update-start.component.scss']
})
export class BaseTemplateUpdateStartComponent extends DialogComponentBase<BaseTemplateUpdateStartComponent, boolean> implements OnInit {

  formGroup: UntypedFormGroup;
  templateFile: Blob;
  templateTypes: SelectOption[];
  selectedTemplateId: number;
  selectedTemplate: BaseTemplateOdm;
  selectedFileType: FileTypeEnum;
  currentStepId: string = "update-meta-step";

  config: any = {
    'update-meta': ['update-meta-step', 'confirmation-step'],
    //'update-content': ['start-step']
  };

  constructor(private formBuilder: UntypedFormBuilder,
    componentService: ComponentService,
    private updateTemplateMetaCmdlet: UpdateATemplateCmdlet4Meta,
    private jobService: JobService,
    private job: GetBaseTemplateJob,
    private displayNameService: DisplayNameService,    
    dialogRef: MatDialogRef<BaseTemplateUpdateStartComponent, boolean>) {
    super(componentService, dialogRef);
  }

  async ngOnInit() {

    super.ngOnInit();

    this.selectedTemplate = await this.jobService.runJob<GetBaseTemplateJob, number, BaseTemplateOdm>(this.job, this.selectedTemplateId);

    this.formGroup = this.formBuilder.group({
      fileDescription: [this.selectedTemplate.description, []],
      name: [this.selectedTemplate.name, []],
      selectedTemplateType: this.formBuilder.control({ value: this.selectedFileType, disabled: true }),
      wizardType: ['update-meta'],
    });

    this.isLoaded = true;
  }

  onNextClicked(event) {

    if (!this.validateStep(this.currentStepId)) {
      return;
    }

    const currentStepId = this.currentStepId;
    const wizardConfig: string[] = this.config[this.wizardType];
    const currentIndex = wizardConfig.findIndex(x => x == currentStepId);
    const nextIndex = currentIndex + 1;
    const isLastStep = nextIndex == wizardConfig.length - 1;
    this.currentStepId = wizardConfig[nextIndex];

    if (isLastStep) {
      this.onFormSubmitted();
      return;
    }

    event.preventDefault();
  }

  private validateStep(stepId: string): boolean {

    if (stepId == 'update-meta-step') {
      if (!this.f.name.value) {
        this.f.name.setErrors({ required: true });
        return false;
      }

      if (!this.f.fileDescription.value) {
        this.f.fileDescription.setErrors({ required: true });
        return false;
      }
    }

    return true;
  }

  async onFormSubmitted() {

    if (this.formGroup.invalid) {
      return;
    }

    const wizardType = this.wizardType;
    let model = this.getModel();
    await this.updateTemplateMetaCmdlet.execute(model);
    this.result = true;
  }

  private getModel() {

    let model = new ASourceFileUm4Meta();
    model.name = this.f.name.value;
    model.id = this.selectedTemplate.id;
    return model;
  }

  onTemplateLocationChanged() {
  }

  onDepartmentChanged() {
  }

  get templateType() {
    return this.displayNameService.getDisplayName(this.selectedFileType);
  }

  get f() { return this.formGroup.controls; }

  get wizardType(): string {
    return this.f.wizardType.value;
  }
}

