<div class="dialog-layout" *ngIf="isLoaded">

  <div class="left-panel">

    <div class="main-text">
      <p>{{templateTypeDisplayName}}</p>
      <p>Delete</p>
    </div>

  </div>

  <div class="right-panel">

    <div class="close-button">
      <button class="dialog-close-button" title="Sluiten" (click)="onCloseClicked($event)">x</button>
    </div>

    <div class="wizard">

      <div class="delete-step" *ngIf="!result">

        <div class="messages">
          <app-user-messages></app-user-messages>
        </div>

        <div class="title">
          <p>Hey!</p>
          <p>Next {{templateTypeDisplayName | lowercase}} will be deleted</p>
        </div>

        <div class="body">

          <dl>
            <dt>
              Name
            </dt>
            <dd>
              {{selectedFile.name}}
            </dd>

            <dt>
              Description
            </dt>
            <dd>
              {{selectedFile.description ? selectedFile.description : '&nbsp;'}}
            </dd>

            <dt>
              Created by
            </dt>
            <dd>
              created by
              <!-- {{selectedFile.createdBy}} -->
            </dd>

          </dl>

        </div>

        <div class="footer">
          <button class="optional-button" [disabled]="isLoading" (click)='onCancelClicked($event)'>Cancel</button>
          <button class="primary-button" [disabled]="isLoading" (click)="onSubmitClicked()">Delete</button>
        </div>

      </div>

      <div class="confirmation-step" *ngIf="result">

        <div class="messages">
          <app-user-messages></app-user-messages>
        </div>

        <div class="footer">
          <button class="primary-button" (click)='onCloseClicked($event)'>Close</button>
        </div>

      </div>

    </div>

  </div>

</div>