import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { TemplatesApiClient } from "../api-clients/TemplatesApiClient";
import { TemplateUpdate4Meta } from "../types/TemplateUpdate4Meta";

@Injectable({ providedIn: 'root' })
export class UpdateTemplateFileMetaJob implements IJob<TemplateUpdate4Meta, VoidResult> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(arg: TemplateUpdate4Meta): Promise<VoidResult> {
    await this.templatesApiClient.updateTemplateFileMeta(arg);
    return VoidResult;
  }
}
