import { Injectable } from '@angular/core';
import { ModuleOdm } from '../../types/dataModels/ModuleOdm';
import { AuthOwnHttpClient } from 'src/app/common/api-clients/AuthOwnHttpClient';

@Injectable({ providedIn: "root" })
export class ModulesApiClient {

  constructor(private httpClient: AuthOwnHttpClient) { }

  private modulesCache: ModuleOdm[] | null = null;

  async getModules(forceRefresh: boolean = false): Promise<ModuleOdm[]> {
    if (this.modulesCache && !forceRefresh) {      
      return Promise.resolve(this.modulesCache);
    } else {
      const modules = await this.httpClient.send<ModuleOdm[]>('get', '/module');
      this.modulesCache = modules;
      return modules;
    }
  }

  async getModuleDetails(id: number): Promise<ModuleOdm> {    
    return await this.httpClient.send<ModuleOdm>('get', `/module/${id}`);
  }
}
