import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { GetTemplateFileMetaByIdJob } from "../jobs/GetTemplateFileMetaByIdJob";
import { TemplateDdm } from "src/app/types/dataModels/TemplateDdm";

@Injectable({ providedIn: 'root' })
export class GetTemplateFileMetaByIdCmdlet extends CmdletBase<{ templateId: number, forceRefresh?: boolean }, TemplateDdm> {

  constructor(
    private jobService: JobService, 
    private job: GetTemplateFileMetaByIdJob, 
    componentService: ComponentService) {
    super(componentService);
  }

  // Optionele forceRefresh parameter toegevoegd
  protected async executeImpl(arg: { templateId: number, forceRefresh?: boolean }, forceRefresh: boolean = false): Promise<TemplateDdm> {
    // Doorgeven van forceRefresh aan de job
    const result = await this.jobService.runJob<GetTemplateFileMetaByIdJob, { templateId: number, forceRefresh?: boolean }, TemplateDdm>(this.job, arg);
    return result;
  }
}