import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { TemplatesApiClient } from "../api-clients/TemplatesApiClient";
import { TemplateDdm } from "src/app/types/dataModels/TemplateDdm";

@Injectable({ providedIn: 'root' })
export class GetTemplateFileMetaByIdJob implements IJob<{ templateId: number, forceRefresh?: boolean }, TemplateDdm> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(arg: { templateId: number, forceRefresh?: boolean }): Promise<TemplateDdm> {    
    const templateOvm = await this.templatesApiClient.getTemplateFileMetaByIdJob(arg.templateId, arg.forceRefresh);
    return templateOvm;
  }
}
