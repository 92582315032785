import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { AppInsightsLoggerService } from "src/app/services/logging.service";


@Injectable({ providedIn: "root" })
export class JobService {

    constructor(private logService: AppInsightsLoggerService) { }

    async runJob<TJob extends IJob<TArg, TResult>, TArg, TResult>(job: TJob, arg: TArg): Promise<TResult> {

        const jobName = job.constructor.name;

        try {
            let result = await job.execute(arg);        
            return result;
        } catch (error) {
            this.logService.error(`Job '${jobName}' failed`);
            throw error;
        }
    }
}



