import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { ModuleOvm } from "src/app/types/viewModels/ModuleOvm";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { GetModuleByIdJob } from "../jobs/GetModuleByIdJob";

@Injectable({ providedIn: 'root' })
export class GetModuleCmdlet extends CmdletBase<number, ModuleOvm> {

  constructor(private jobService: JobService, private job: GetModuleByIdJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: number): Promise<ModuleOvm> {
    let result = await this.jobService.runJob<GetModuleByIdJob, number, ModuleOvm>(this.job, arg);
    return result;
  }
}
