import { ITableRow } from "../../interfaces/ITableRow";

export class DataPage<T extends ITableRow> {
    public canPrev: boolean;
    public canNext: boolean;
    public rows: T[] = [];
    public totalPages: number;


    public selectRow(row: T): void {

        this.rows.forEach(r => {

            if (r.id == row.id) {
                if (r.isSelected) {
                    r.isSelected = false;
                }
                else {
                    r.isSelected = true;
                }
            }
            else {
                r.isSelected = false;
            }
        });
    };
}


