<main class="container">
    <div class="error-page">
        <div class="error-page-content">
            <h1>{{'messages.session_expired' | translate}}</h1>            
            <p class="error-page-home-link">
                <a class="btn btn-primary" href="/">{{'buttons.login_again' | translate}}</a>
            </p>            
        </div>
        <div class="error-page-image">
            <img src="assets/images/access_denied.svg" alt="Access denied illustration">
        </div>
    </div>
</main>