import { inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ConfirmationDialogComponent, DialogButtonType } from 'src/app/common/components/confirmation-dialog/confirmation-dialog.component';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Observable<boolean> | Promise<boolean>;
  deactivationMessage: string; 
}

export const canDeactivateGuard: (component: CanComponentDeactivate) => Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree = 
  (component: CanComponentDeactivate) => {
    const dialog = inject(MatDialog);

    if (component.canDeactivate && !component.canDeactivate()) {
      const dialogRef = dialog.open(ConfirmationDialogComponent, {
        data: {
          confirmationText: component.deactivationMessage,
          buttons: [DialogButtonType.Yes, DialogButtonType.No],
          defaultButton: DialogButtonType.No
        }
      });

      return dialogRef.afterClosed().toPromise().then(result => result === DialogButtonType.Yes);
    }
    return true;
};