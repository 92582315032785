export class CurrentUserAccountModel {
    id: number;
    function: string;
    effectiveUserDisplayName: string;
    realUserDisplayName: string;
    accessType: string;
    isTenantUser: boolean;


    canImpersonate: boolean;
    isImpersonated: boolean;
}
