import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { RaetTemplatesApiClient } from "../api-clients/RaetTemplatesApiClient";
import { RaetTemplateUm4ActiveState } from "../types/RaetTemplateUm4ActiveState";


@Injectable({ providedIn: "root" })
export class UpdateRaetTemplateActiveStateJob implements IJob<RaetTemplateUm4ActiveState, VoidResult> {

  constructor(private raetTemplatesApiClient: RaetTemplatesApiClient) { }

  async execute(arg: RaetTemplateUm4ActiveState): Promise<VoidResult> {
    await this.raetTemplatesApiClient.updateRaetTemplateActiveState(arg);
    return new VoidResult();
  }
}
