import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ObjectAlreadyExistsError } from "../../types/exceptions/ObjectAlreadyExistsError";
import UnexpectedError from "../../types/exceptions/UnexpectedError";
import ExternalServiceOfflineError from "../../types/exceptions/ExternalServiceOfflineError";
import CannotAuthenticateUserError from "../../types/exceptions/CannotAuthenticateUserError";
import OperationForbiddenError from "../../types/exceptions/OperationForbiddenError";
import UserTokenExpiredError from "../../types/exceptions/UserTokenExpiredError";
import { BlobHttpContent, HttpContentBase, JsonHttpContent } from "../../types/helpers/HttpContent";
import { VoidResult } from "../../types/helpers/VoidResult";
import { ObjectCannotBeFoundError } from '../../types/exceptions/ObjectCannotBeFoundError';
import { RaetFile } from "src/app/types/dataModels/RaetFile";
import { AppInsightsLoggerService } from "src/app/services/logging.service";

@Injectable({ providedIn: "root" })
export class OwnHttpClient {
  constructor(private httpClient: HttpClient,
    private logger: AppInsightsLoggerService) { }

  async send2(method: string, relativeUrl: string, payload?: any): Promise<Blob> {
    const fullUrl = this.buildFullUrl(relativeUrl);
    let eventName: string = `sending request to backend`;
    let eventData: any = { method, fullUrl };

    try {      
      let response = await this.httpClient.request(method, fullUrl, { body: payload, responseType: "blob" }).toPromise();      
      return response;
    }
    catch (error) {
      this.logger.error(`Failed ${eventName}`, { method, fullUrl, error });
      this.ensureSuccessCode(error);
      throw new UnexpectedError();
    }
  }

  async send<T>(method: string, relativeUrl: string, payload?: any): Promise<T> {
    const fullUrl = this.buildFullUrl(relativeUrl);    
    let eventName: string = `sending request to backend`;
    let eventData: any = { method, fullUrl };

    try {      
      let response = await this.httpClient.request<T>(method, fullUrl, { body: payload }).toPromise();      
      return response;
    }
    catch (error) {
      this.logger.error(`Failed ${eventName}`, { method, fullUrl, error });
      this.ensureSuccessCode(error);
      throw new UnexpectedError();
    }
  }

  async send3<T>(method: string, relativeUrl: string, model: any, file: Blob): Promise<T> {
    const fullUrl = this.buildFullUrl(relativeUrl);

    try {
      const payload = new FormData();
      payload.append("form", JSON.stringify(model));
      payload.append('file', file);
      let response = await this.httpClient.request<T>(method, fullUrl, { body: payload }).toPromise();
      return response;
    }
    catch (error) {
      this.ensureSuccessCode(error);
      throw new UnexpectedError();
    }
  }

  async getMultipart(relativeUrl: string): Promise<HttpContentBase[]> {
    const fullUrl = this.buildFullUrl(relativeUrl);    

    try {      
      let httpResponse = await this.httpClient.get(fullUrl, { observe: 'response', responseType: "blob" }).toPromise();      
      let httpResponseBody = await httpResponse.body.text();      
      let httpContents: HttpContentBase[] = this.parseHttpResponseBody(httpResponseBody, "boundary");      
      return httpContents;
    }
    catch (error) {
      this.ensureSuccessCode(error);
      throw new UnexpectedError();
    }
  }

  async getMultipart2(relativeUrl: string): Promise<RaetFile> {
    const fullUrl = this.buildFullUrl(relativeUrl);    

    try {      
      let httpResponse = await this.httpClient.get(fullUrl, { observe: 'response', responseType: "blob" }).toPromise();    
      let raetFile = new RaetFile();
      raetFile.content = await httpResponse.body.text();
      raetFile.contentType = 'application/text-control';
      raetFile.fileName = await httpResponse.url;      
      return raetFile;
    }
    catch (error) {
      this.ensureSuccessCode(error);
      throw new UnexpectedError();
    }
  }

  async postMultipart(relativeUrl: string, jsonContent: JsonHttpContent, blobContent: BlobHttpContent): Promise<any> {

    const fullUrl = this.buildFullUrl(relativeUrl);
    const formData = this.getFormData(jsonContent, blobContent);

    try {
      let httpResponse = await this.httpClient.post(fullUrl, formData).toPromise();
      return httpResponse;
    }
    catch (error) {
      this.ensureSuccessCode(error);
      throw new UnexpectedError();
    }

    return new VoidResult();
  }

  async putMultipart(relativeUrl: string, jsonContent: JsonHttpContent, blobContent: BlobHttpContent): Promise<VoidResult> {
    const fullUrl = this.buildFullUrl(relativeUrl);
    const formData = this.getFormData(jsonContent, blobContent);

    try {
      let httpResponse = await this.httpClient.put(fullUrl, formData).toPromise();
    }
    catch (error) {
      this.ensureSuccessCode(error);
      throw new UnexpectedError();
    }

    return new VoidResult();
  }

  private parseHttpResponseBody(responseBody: string, boundary: string): HttpContentBase[] {    
    const self = this;
    let result: HttpContentBase[] = [];
    const dataArray: string[] = responseBody.split(`--${boundary}`);

    dataArray.forEach((dataBlock) => {
      let subResult: HttpContentBase = self.parseHttpContent(dataBlock);

      if (subResult) {
        result.push(subResult);
      }
    });

    return result;
  }

  private getContentType(row: string) {
    let result = row.split(":")[1].split(";")[0].trim();
    return result;
  }

  private getContentDisposition(row: string) {
    let result = row.split(":")[1].trim();
    return result;
  }

  private parseHttpContent(dataBlock: string): HttpContentBase {   
    let rows = dataBlock.split(/\r?\n/);
    rows = rows.filter(x => x.length > 1);

    if (rows.length < 3) {
      return null;
    }

    const contentType = this.getContentType(rows[0]);
    const contentDisposition = this.getContentDisposition(rows[1]);
    const content = rows[2];

    if (contentType == "application/json") {
      let result = new JsonHttpContent();
      result.content = content;
      return result;
    }

    let result = new BlobHttpContent();

    const index1 = contentDisposition.indexOf('filename');
    const index2 = contentDisposition.indexOf('=', index1);
    const fileName = contentDisposition.substr(index2 + 1);
    result.fileName = fileName;
    result.content = content;
    return result;
  }

  private ensureSuccessCode(error: any) {

    if (error.status == 0) {
      throw new ExternalServiceOfflineError();
    }

    if (error.status == 500) {
      throw new UnexpectedError();
    }

    if (error.status == 401) {

      const isTokenExpired: boolean = error.statusText == 'TokenExpired';

      if (isTokenExpired) {
        throw new UserTokenExpiredError();
      }

      throw new CannotAuthenticateUserError();
    }

    if (error.status == 403) {
      throw new OperationForbiddenError();
    }

    if (error.status == 404) {
      throw new ObjectCannotBeFoundError();
    }

    if (error.status == 409) {
      throw new ObjectAlreadyExistsError();
    }
  }

  private buildFullUrl(relativeUrl: string) {    
    return (environment.backendUrl + `/api/v${environment.backendUrlApiVersion}${relativeUrl}`);
  }

  private getFormData(jsonContent: JsonHttpContent, blobContent: BlobHttpContent) {
    const formData = new FormData();

    var myblob = new Blob([jsonContent.content], {
      type: jsonContent.contentType
    });

    formData.append("form", myblob, '');
    formData.append("file", this.base64toBlob(blobContent.content, blobContent.contentType), blobContent.fileName);
    return formData;
  }

  private base64toBlob(base64Data, contentType) {    
    contentType = contentType || '';
    var sliceSize = 1024;
    var byteCharacters = atob(base64Data);
    var bytesLength = byteCharacters.length;
    var slicesCount = Math.ceil(bytesLength / sliceSize);
    var byteArrays = new Array(slicesCount);

    for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      var begin = sliceIndex * sliceSize;
      var end = Math.min(begin + sliceSize, bytesLength);

      var bytes = new Array(end - begin);
      for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }
}
