    <div class="right-column">
      <br>
      <div><button class="redirectBtn" (click)="redirect(1)">Raet templates</button> > <button
          class="redirectBtn2">{{raetTemplate.raetName}}</button></div>
      <div class="page-toolbar">Raet template</div>
      <br>
      <div class="table-header">
        <div class="mergeFunctionDescription">
          <div class="mergeFunctionLabels">
            <p class="templategegevens">Raettemplategegevens</p>
            <p class="description templateDescription"><span style="font-weight: bold;">Naam: </span></p>
            <p class="description templateDescription"><span style="font-weight: bold;">Status: </span></p>
          </div>
          <div>
            <p class="templategegevens" style=" visibility: hidden;">Raettemplategegevens</p>
            <p><input class="input" [ngModel]="raetTemplate.raetName" id="name"></p>
            <p *ngIf="raetTemplate.isActive" class="description">Actief</p>
            <p *ngIf="!raetTemplate.isActive" class="description">Inactief</p>
          </div>
          <button (click)="onSaveTemplateClicked($event)" class="editButton3">Opslaan</button>
        </div>
      </div>

      <br>

      <div class="table-header">
        <div>
          <p style="font-size: 1.2rem; font-weight: bold;">Raet template</p>
          <br>
          <img class="thumbnail" [src]="imagePath">
        </div>
        <div class="templateDetails">
          <ul>
            <li>
              <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-edit" aria-hidden="true"></span>
              <button (click)="onEditemplateClicked($event)" class="templateDetailsBtn">Bewerken</button>
            </li>
            <li>
              <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-refresh" aria-hidden="true"></span>
              <button (click)="onRedoTemplateClicked($event)" class="templateDetailsBtn">Opnieuw Maken</button>
            </li>
            <li>
              <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-delete" aria-hidden="true"></span>
              <button (click)="onDeleteTemplateClicked($event)" class="templateDetailsBtn"> Verwijderen</button>
            </li>
            <li><mat-icon title="Clear">upload</mat-icon>
              <button (click)="onAddNewRowClicked('upload')" class="templateDetailsBtn">Lokale raettemplate opvoeren</button>
            </li>
            <li>
              <app-dropdown-button [title]="'buttons.export' | translate" [items]="exportItems"
                (clicked)="onExportTemplateClicked($event)">
              </app-dropdown-button>
            </li>
          </ul>
        </div>
      </div>
    </div>


<ul>
  <li>
    <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-edit" aria-hidden="true"></span>
    <button (click)="onEditemplateClicked($event)" class="templateDetailsBtn">Bewerken</button>
  </li>
  <li>
    <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-refresh" aria-hidden="true"></span>
    <button (click)="onRedoTemplateClicked($event)" class="templateDetailsBtn">Opnieuw Maken</button>
  </li>
  <li> <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-upload" aria-hidden="true"></span>
    <button (click)="onAddNewRowClicked('upload')" class="templateDetailsBtn">Lokale basistemplate opvoeren</button>
  </li>
  <li>
    <span class="vismaicon vismaicon-dynamic vismaicon-sm vismaicon-delete" aria-hidden="true"></span>
    <button (click)="onDeleteTemplateClicked($event)" class="templateDetailsBtn">Verwijderen</button>
  </li>
  <li>
    <app-dropdown-button [title]="'buttons.export' | translate" [items]="exportItems"
      (clicked)="onExportTemplateClicked($event)">
    </app-dropdown-button>
  </li>
</ul>