import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { TemplateUpdate4Meta } from "../types/TemplateUpdate4Meta";
import { UpdateTemplateFileMetaJob } from "../jobs/UpdateTemplateFileMetaJob";

@Injectable({ providedIn: 'root' })
export class UpdateTemplateFileMetaCmdlet extends CmdletBase<TemplateUpdate4Meta, VoidResult> {

  constructor(private jobService: JobService, private job: UpdateTemplateFileMetaJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: TemplateUpdate4Meta): Promise<VoidResult> {
    await this.jobService.runJob<UpdateTemplateFileMetaJob, TemplateUpdate4Meta, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
