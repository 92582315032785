import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentService } from 'src/app/services/ComponentService';
import { FileTypeEnum } from '../../types/enums/FileTypeEnum';
import { DialogComponentBase } from '../DialogComponentBase';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { BaseTemplateOdm } from 'src/app/types/dataModels/BaseTemplateOdm';
import { UpdateATemplateCmdlet4Content } from 'src/app/base-templates/cmdlets/UpdateATemplateCmdlet4Content';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { ProcessingStorage } from '../../services/ProcessingStorage';


@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-replace-template.component.html',
  styleUrls: ['./upload-replace-template.component.scss']
})
export class UploadReplaceTemplateComponent extends DialogComponentBase<UploadReplaceTemplateComponent, boolean> implements OnInit {
  formGroup: UntypedFormGroup;
  templateFile: Blob;
  selectedTemplateIdToCopy: number;
  selectedTemplateType: FileTypeEnum;
  newBaseTemlateNumber: number;
  mergeFunction: MergeFunctionOvm;
  templateIdToReplace: number;
  selectedTemplateId: number;
  selectedTemplate: BaseTemplateOdm;
  selectedFileType: FileTypeEnum;
  fileExtension: string;
  fileName: string;
  contentType: string;

  constructor(private formBuilder: UntypedFormBuilder,
    componentService: ComponentService,
    private cd: ChangeDetectorRef,
    private updateATemplateCmdlet4Content: UpdateATemplateCmdlet4Content,
    dialogRef: MatDialogRef<UploadReplaceTemplateComponent, boolean>    
  ) {
    super(componentService, dialogRef);
  }

  // @ViewChild('search') search: ElementRef;

  async ngOnInit() {

    super.ngOnInit();

    this.formGroup = this.formBuilder.group({
      description: ['testDesc', []],
      name: ['BaseTemplate' + this.newBaseTemlateNumber, []],
      selectedTemplate: ['', []],
      wizardType: ['use-local-template'],
    });

    this.isLoaded = true;
  }

  onFileChange(event) {
    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;

      const fullFileName = file.name;
      const fileNameWithoutExtension = fullFileName.split('.').slice(0, -1).join('.');

      this.f.name.setValue(fileNameWithoutExtension);
      this.fileName = fullFileName;      
      this.fileExtension = fullFileName.split('.').pop();
      this.contentType = file.type;

      console.info('uploading file: ', file.type);
      
      reader.readAsBinaryString(file);
      reader.onload = () => {

        this.templateFile = file;
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  onUploadFileClicked(event) {
    document.getElementById("select-file").click();    
    event.preventDefault();
  }

  get f() { return this.formGroup.controls; }

  get wizardType(): string {
    return this.f.wizardType.value;
  }

  get selectedFileName() {
    return this.f.name.value;
  }

  private async getModel(): Promise<TemplateEditorFileModel> {
    const model = new TemplateEditorFileModel();
    model.name = this.f.name.value;
    model.documentType = this.contentType;

    if (this.templateFile) {
      model.content = await this.blobToBase64Async(this.templateFile);
    }

    return model;
  }

  async blobToBase64Async(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onerror = (e) => reject(fileReader.error);
      fileReader.onloadend = (e) => {
        const dataUrl = fileReader.result as string;
        // remove "data:mime/type;base64," prefix from data url
        const base64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
        resolve(base64);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  async onNextClicked($event) {
    let templateEditorFileModel: TemplateEditorFileModel = await this.getModel();
    templateEditorFileModel.documentType = this.fileExtension;    
    templateEditorFileModel.id = this.templateIdToReplace;    
    await this.updateATemplateCmdlet4Content.execute(templateEditorFileModel);
    this.result = true;
    this.close();
    return;
  }
}
