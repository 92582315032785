import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { ModuleOvm } from "src/app/types/viewModels/ModuleOvm";
import { GetModulesJob } from "../jobs/GetModulesJob";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { VoidArg } from "src/app/types/helpers/VoidArg";

@Injectable({ providedIn: 'root' })
export class GetModulesCmdlet extends CmdletBase<VoidArg, ModuleOvm[]> {

  constructor(private jobService: JobService, private job: GetModulesJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(forceRefresh: boolean = false): Promise<ModuleOvm[]> {
    let result = await this.jobService.runJob<GetModulesJob, VoidArg, ModuleOvm[]>(this.job, forceRefresh);
    return result;
  }
}
