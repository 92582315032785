import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { SelectOption } from '../../types/helpers/SelectOption';

@Component({
  selector: 'app-table-row-primary-button',
  templateUrl: './table-row-primary-button.component.html',
  styleUrls: ['./table-row-primary-button.component.scss']
})
export class TableRowPrimaryButtonComponent implements OnInit {

  isMenuExpanded = false;
  options: SelectOption[];

  constructor() { }

  @Input()
  title: string;

  @Input()
  items: string;

  @Output()
  readonly clicked: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {

    let arr = this.items.split(',').map(s => s.trim());
    //console.log(arr);
    let result: SelectOption[] = [];

    arr.forEach(i => {
      let xxx = i.split('|').map(s => s.trim());
      let x = new SelectOption();
      x.id = xxx[0];
      x.text = xxx[1];
      result.push(x);
    });

    this.options = result;
  }

  onExpandMenuClicked(event) {
    this.isMenuExpanded = !this.isMenuExpanded;
    event.stopPropagation();
  }

  onButtonClicked(event, item: string) {
    this.clicked.emit(item);
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener("window:mouseup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    this.isMenuExpanded = false;
  }
}
