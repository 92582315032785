import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModuleType } from 'src/app/common/types/enums/moduleType.enum';
import { ModuleOvm } from 'src/app/types/viewModels/ModuleOvm';

export class MenuItem {
  id: string;
  text: string;
  isActive: boolean;
}

@Component({
  selector: 'app-module-panel',
  templateUrl: './module-panel.component.html',
  styleUrls: ['./module-panel.component.scss']
})
export class ModulePanelComponent {

  @Input() modules: ModuleOvm[] = [];  
  @Input() activeModuleId: number | null = null;
  @Input() showBaseTemplates: boolean = true;
  @Input() showRaetTemplates: boolean = true;
  @Input() activeModuleType: ModuleType = ModuleType.Unknown;

  @Output() moduleClicked = new EventEmitter<ModuleOvm>();
  @Output() globalModuleClicked = new EventEmitter<ModuleOvm>();
  @Output() baseTemplatesClicked = new EventEmitter<void>();
  @Output() staticModuleClicked = new EventEmitter<void>();

  ModuleType = ModuleType;

  onModuleClicked(module: ModuleOvm, isGlobal: boolean) {
    if (isGlobal) {
      this.globalModuleClicked.emit(module);
    } else {
      this.moduleClicked.emit(module);
    }
  }

  onBaseTemplatesClicked() {
    this.baseTemplatesClicked.emit();
  }

  onStaticTemplatesClicked() {
    this.staticModuleClicked.emit();
  }

}
