import { Injectable } from '@angular/core';
import { ITextControl } from '../interfaces/ITextControl';
export declare const TXTextControl: any;

@Injectable({ providedIn: 'root' })
export class TextControl implements ITextControl {

  get instance(): any {
    return TXTextControl;
  }
}
