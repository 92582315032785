import { Injectable } from "@angular/core";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { ComponentService } from "src/app/services/ComponentService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { UpdateBaseTemplateStatusJob } from "../jobs/UpdateBaseTemplateStatusJob";
import { BaseTemplateUm4ActiveState } from "../types/helpers/BaseTemplateUm4ActiveState";

@Injectable({ providedIn: "root" })
export class UpdateBaseTemplateStatusCmdlet extends CmdletBase<BaseTemplateUm4ActiveState, VoidResult> {

  constructor(private jobService: JobService,
    private job: UpdateBaseTemplateStatusJob,
    componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: BaseTemplateUm4ActiveState): Promise<VoidResult> {
    await this.jobService.runJob<UpdateBaseTemplateStatusJob, BaseTemplateUm4ActiveState, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
