import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { DocumentFormatReq } from "src/app/types/dataModels/DocumentFormatReq";

@Injectable({ providedIn: 'root' })
export class UpdateMergeFunctionDocumentFormatJob implements IJob<DocumentFormatReq, VoidResult> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: DocumentFormatReq): Promise<VoidResult> {
    await this.mergeFunctionsApiClient.updateMergeFunctionDocumentFormat(arg);
    return VoidResult;
  }
}
