import { Injectable } from '@angular/core';
import { IFilterService } from '../interfaces/IFilterService';

@Injectable({ providedIn: 'root' })
export class FilterService<T> implements IFilterService<T> {

    filter<T>(source: T[], columnNames: string[], keyword: string): T[] {

        if (source.length == 0) {
            return source;
        }

        if (!keyword) {
            return source;
        }

        let searchText = keyword.toLowerCase();

        let r = source.filter(x => {

            let found: boolean = false;

            columnNames.forEach(columnName => {

                let columnValue: any = x[columnName];
                let columnType = typeof (columnValue);

                if (columnType == "string") {
                    let z: string = (columnValue ?? "").toLowerCase();

                    if (z.indexOf(searchText) >= 0) {
                        found = true;
                    }
                }

                if (columnType == "number") {
                    let z: string = (`${columnValue}`).toLowerCase();

                    if (z.indexOf(searchText) >= 0) {
                        found = true;
                    }
                }
            });


            if (found) {
                return x;
            }

            return null;

        });


        return r;

    }
}
