import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { SelectOption } from '../../types/helpers/SelectOption';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss']
})
export class DropdownButtonComponent implements OnInit {

  isMenuExpanded = false;

  constructor(private elementRef: ElementRef) { }

  @Input() title: string;
  @Input() items: SelectOption[];
  @Input() disabled: boolean;

  @Output()
  readonly clicked: EventEmitter<SelectOption> = new EventEmitter<SelectOption>();

  ngOnInit(): void {
    // Init code indien nodig
  }

  onExpandMenuClicked(event: MouseEvent) {
    this.isMenuExpanded = !this.isMenuExpanded;
    event.stopPropagation();
  }

  onButtonClicked(event: MouseEvent, item: SelectOption) {
    console.info('DropdownButtonComponent.onButtonClicked', item);
    this.clicked.emit(item);
    event.preventDefault();
    event.stopPropagation();
    this.isMenuExpanded = false; // Sluit het menu na selectie
  }

  @HostListener('window:mouseup', ['$event'])
  onWindowMouseUp(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isMenuExpanded = false;
    }
  }
}
