<div class="left-menu-component">
    <ul>
        <li>
            <p class="menuTemplateItem">{{'headers.templates' | translate}}</p>
        </li>
        <li *ngFor="let mi of modules">
            <p class="menuTemplateItemBtn" [ngClass]="{'bold': mi.id===activeModuleId && activeModuleType===ModuleType.RegularModule}" (click)="onModuleClicked(mi, false)">{{mi.name}}</p>
        </li>
        <ng-container *ngIf="showBaseTemplates">
            <li>
                <p class="menuTemplateItem" id="basisTemplates">{{'headers.base_templates' | translate}}</p>
            </li>
            <li>
                <p class="menuTemplateItemBtn" [ngClass]="{'bold': activeModuleType===ModuleType.BaseTemplates}" (click)="onBaseTemplatesClicked()">{{'headers.base_template_manage' | translate}}</p>
            </li>
        </ng-container>
        <ng-container *ngIf="showRaetTemplates && modules">
            <li>
                <p class="menuTemplateItem" id="basisTemplates">{{'headers.raet_templates' | translate}}</p>
            </li>
            <!-- <li>
                <p class="menuTemplateItemBtn" [ngClass]="{'bold': activeModuleType===ModuleType.StaticModule}" (click)="onStaticTemplatesClicked()">Static templates</p>
            </li> -->
            <li *ngFor="let mi of modules">
                <p class="menuTemplateItemBtn" [ngClass]="{'bold': mi.id===activeModuleId && activeModuleType===ModuleType.GlobalModule}" (click)="onModuleClicked(mi, true)">{{mi.name}}</p>
            </li>
        </ng-container>
    </ul>
</div>