<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li *ngFor="let breadcrumb of breadcrumbs; let last = last" class="breadcrumb-item" [class.active]="last">        
        <a *ngIf="breadcrumb.url && !last" 
          [routerLink]="breadcrumb.url" 
          [queryParams]="breadcrumb.params"
          [queryParamsHandling]="'merge'">{{ breadcrumb.label }}</a>        
        <span *ngIf="!breadcrumb.url || last">{{ breadcrumb.label }}</span>        
        <span *ngIf="!last" class="separator"> > </span>
      </li>
    </ol>
  </nav>