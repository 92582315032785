import { Inject, Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { TemplatePlaceholderModel } from 'src/app/base-templates/types/helpers/TemplatePlaceholderModel';
import { ApiEndpointClient } from 'src/app/common/api-clients/ApiEndpointClient';
import { FileTypeEnum } from 'src/app/common/types/enums/FileTypeEnum';
import { HttpContentBase, JsonHttpContent, BlobHttpContent } from 'src/app/types/helpers/HttpContent';


class TemplateContentVm {
  id?: number;
  name: string;
  description: string;
  fileType: string;
  folderId?: number;
  departmentId?: number;
  content: string;
}


@Injectable({ providedIn: 'root' })
export class GetTemplateContentJob implements IJob<number, TemplateEditorFileModel> {

  constructor(private httpClient: ApiEndpointClient) { }

  async execute(id: number): Promise<TemplateEditorFileModel> {
    var result = await this.getTemplateEditorModel(id);
    console.debug("Template editor model recieved from back-end...");
    return result;
  }

  private async getTemplateEditorModel(id: number): Promise<TemplateEditorFileModel> {
    const self = this;
    const relativeUrl = `/template/${id}/blob`;
    console.log('GetTemplateContentJob getTemplateEditorModel: ')
    let httpContents = await this.httpClient.getMultipart(relativeUrl);
    console.log('GetTemplateContentJob httpContents: ')
    console.log(httpContents)
    let result = new TemplateEditorFileModel();

    //result.content = JSON.parse(httpContents);
    httpContents.forEach(c => {

      // if (c instanceof JsonHttpContent) {
      //   self.addJson(result, <JsonHttpContent>c);
      // }

      if (c instanceof BlobHttpContent) {
        self.addBlob(result, <BlobHttpContent>c);
      }
    });

    return result;
  }

  private addBlob(result: TemplateEditorFileModel, blobHttpContent: BlobHttpContent) {
    console.log('addBlob result');
    console.log(result);
    result.name = blobHttpContent.fileName;
    //result.content = blobHttpContent.content;
  }

}
