import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { TemplateOvm } from "src/app/types/viewModels/TemplateOvm";
import { GetMergeFunctionTemplatesByIdJob } from "../jobs/GetMergeFunctionTemplatesByIdJob";

@Injectable({ providedIn: 'root' })
export class GetMergeFunctionTemplatesCmdlet extends CmdletBase<{ mergeFunctionId: number, global: boolean, forceRefresh?: boolean }, TemplateOvm[]> {

  constructor(private jobService: JobService, private job: GetMergeFunctionTemplatesByIdJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: { mergeFunctionId: number, global: boolean, forceRefresh?: boolean }): Promise<TemplateOvm[]> {
    let result = await this.jobService.runJob<GetMergeFunctionTemplatesByIdJob, { mergeFunctionId: number, global: boolean, forceRefresh?: boolean }, TemplateOvm[]>(this.job, arg);
    return result;
  }
}
