import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject, Subscription, concat, forkJoin, share, switchMap, take, tap, timer } from 'rxjs';

@Component({
  selector: 'app-refresh-button', 
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss']
})
export class RefreshButtonComponent implements OnInit, OnDestroy {
  @Input() refreshTrigger: Subject<void>;
  @Input() refreshDoneTrigger: Subject<void>;
  @Input() minAnimationTime = 1000; // in milliseconds
  @Output() refreshRequest = new EventEmitter<void>();
  isRefreshing = false;

  private refreshSubscription: Subscription;

  onRefreshClicked() {
    if (this.isRefreshing) {
      return;
    }

    this.isRefreshing = true;
    this.refreshRequest.emit();
  }

  ngOnInit() {
    this.refreshSubscription = this.refreshTrigger.pipe(
      tap(() => this.isRefreshing = true),
      switchMap(() => {
        const refreshTimer$ = timer(this.minAnimationTime);
        const sharedRefreshDoneTrigger$ = this.refreshDoneTrigger.pipe(take(1), share());
        return forkJoin([refreshTimer$, sharedRefreshDoneTrigger$]);
      })
    ).subscribe(() => this.isRefreshing = false);
  }
  
  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
}