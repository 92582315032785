<ng-container *ngIf="isLoading; else showContent">
  <div class="default-layout">
    <div class="content">
      <p>{{'messages.app_loading' | translate}}</p>
      <div class="loading-icon">
        <img src="assets/images/spinner_doughnut_blue_light.svg" alt="Loading..." />
      </div>
    </div>
  </div>
</ng-container>
<ng-template #showContent>  
  <app-youforce-navigation-bar
      [subHeaderItems]="subHeaderItems"      
      [culture]="navBarCulture"      
      [activeItemId]="activeSubMenuItemId"
      [helpItems]="helpItems"
      (subHeaderItemClicked)="onSubHeaderItemClicked($event)"      
      (helpItemClicked)="onHelpItemClicked($event)"
      (logoutClicked)="onLogoutClicked($event)">
    </app-youforce-navigation-bar>        
  <router-outlet></router-outlet>
</ng-template>