import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { MergeFunctionTemplateUmdb } from "../types/MergeFunctionTemplateUmdb";
import { MergeFunctionUm4DefaultTemplateReq } from "../types/MergeFunctionUm4DefaultTemplateReq";

@Injectable({ providedIn: 'root' })
export class UpdateMergeFunctionDefaultTemplateJob implements IJob<MergeFunctionUm4DefaultTemplateReq, VoidResult> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: MergeFunctionUm4DefaultTemplateReq): Promise<VoidResult> {
    console.log('UpdateMergeFunctionDefaultTemplateJob')
    console.log(arg)
    await this.mergeFunctionsApiClient.updateMergeFunctionDefaultTemplate(arg);
    return VoidResult;
  }
}
