import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { BaseTemplateOvm } from "src/app/types/viewModels/BaseTemplateOvm";
import { BaseTemplateOdm } from "src/app/types/dataModels/BaseTemplateOdm";
import { TemplatesApiClient } from "../api-clients/TemplatesApiClient";
import { TemplateDdm } from "src/app/types/dataModels/TemplateDdm";
import { RaetFile } from "src/app/types/dataModels/RaetFile";
import { BaseTemplatesApiClient } from "src/app/base-templates/api-clients/BaseTemplatesApiClient";
import { TemplateVersionReq } from "../types/TemplateVersionReq";

@Injectable({ providedIn: 'root' })
export class GetTemplateBlobJob implements IJob<number, Blob> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(id: number): Promise<Blob> {
    let templateVersionReq = new TemplateVersionReq();
    templateVersionReq.templateId = id;
    templateVersionReq.documentType = 2;
    let res = await this.templatesApiClient.getTemplateBlob3(templateVersionReq);
    //let raetFile = new RaetFile()
    // raetFile.content = await new Response(res).text()
    // raetFile.contentType = res.type;
    // raetFile.fileName = await new Response(res).url;
    return res;
  }
}
