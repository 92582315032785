import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { RaetTemplateOm } from "../types/RaetTemplateOm";
import { GetRaetTemplateMetaJob } from "../jobs/GetRaetTemplateMetaJob";

@Injectable({ providedIn: 'root' })
export class GetRaetTemplateMetaCmdlet extends CmdletBase<number, RaetTemplateOm> {

  constructor(private jobService: JobService, private job: GetRaetTemplateMetaJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: number): Promise<RaetTemplateOm> {
    let result = await this.jobService.runJob<GetRaetTemplateMetaJob, number, RaetTemplateOm>(this.job, arg);
    return result;
  }
}
