import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ComponentService } from 'src/app/services/ComponentService';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { BaseTemplateOdm } from 'src/app/types/dataModels/BaseTemplateOdm';
import { MergeFunctionOvm } from 'src/app/types/dataModels/MergeFunctionOvm';
import { TemplateOvm } from 'src/app/types/viewModels/TemplateOvm';
import { DialogComponentBase } from 'src/app/common/components/DialogComponentBase';
import { FileTypeEnum } from 'src/app/common/types/enums/FileTypeEnum';
import CreateRaetTemplateCmdlet from '../../cmdlets/CreateRaetTemplateCmdlet';
import { GetModuleCmdlet } from 'src/app/modules/cmdlets/GetModuleCmdlet';
import { GetModulesCmdlet } from 'src/app/modules/cmdlets/GetModulesCmdlet';
import { ModuleOvm } from 'src/app/types/viewModels/ModuleOvm';
import { VoidArg } from 'src/app/types/helpers/VoidArg';

@Component({
  selector: 'app-upload-template',
  templateUrl: './upload-raet-template.component.html',
  styleUrls: ['./upload-raet-template.component.scss']
})
export class UploadRaetTemplateComponent extends DialogComponentBase<UploadRaetTemplateComponent, boolean> implements OnInit {  
  formGroup: UntypedFormGroup;
  templateFile: Blob;
  selectedTemplateIdToCopy: number;
  templates: TemplateOvm[];
  defaultTemplates: TemplateOvm[];
  selectedTemplateType: FileTypeEnum;
  newBaseTemlateNumber: number;
  modules: ModuleOvm[];
  module: ModuleOvm;
  mergeFunction: MergeFunctionOvm;
  mergeFunctions: MergeFunctionOvm[];
  selectedTemplateId: number;
  selectedModuleId: number;
  selectedMergeFunctionId: number;
  selectedTemplate: BaseTemplateOdm;
  selectedFileType: FileTypeEnum;
  fileExtension: string;
  currentStepId: string = "start-step";
  config: any = {
    'use-local-template': ['start-step', 'module-step', 'mergeFunction-step', 'select-file-step', 'name-description-step'],
    'create-empty-template': ['start-step', 'module-step', 'mergeFunction-step', 'name-description-step'],
  };

  constructor(private formBuilder: UntypedFormBuilder,
    componentService: ComponentService,
    private cd: ChangeDetectorRef,
    dialogRef: MatDialogRef<UploadRaetTemplateComponent, boolean>,    
    private createRaetTemplateCmdlet: CreateRaetTemplateCmdlet,
    private getModulesCmdlet: GetModulesCmdlet,
    private getModuleCmdlet: GetModuleCmdlet,    
  ) {
    super(componentService, dialogRef);
  }

  // @ViewChild('search') search: ElementRef;

  async ngOnInit() {

    super.ngOnInit();

    this.formGroup = this.formBuilder.group({
      description: ['testDesc', []],
      name: ['BaseTemplate' + this.newBaseTemlateNumber, []],
      // selectedFolder: ['', []],
      // selectedTemplateLocation: ['', []],
      selectedTemplate: ['', []],
      wizardType: ['use-local-template'],
    });

    this.modules = await this.getModulesCmdlet.execute(new VoidArg());
    this.selectedModuleId = this.modules[0].id;
    this.module = await this.getModuleCmdlet.execute(this.selectedModuleId);
    console.log('this.module');
    console.log(this.module);
    //TODO: Determine what information is needed here
    //this.mergeFunctions = this.module.mergeFunctions;
    console.log('this.mergeFunctions');
    console.log(this.mergeFunctions);
    //let test = await this.getMergeFunctionCmdlet.execute(this.module.id);
    this.isLoaded = true;
  }

  async onFormSubmitted() {

    switch (this.wizardType) {

      case 'use-local-template': {
        let templateEditorFileModel: TemplateEditorFileModel = await this.getModel();
        templateEditorFileModel.name = this.f.name.value;
        templateEditorFileModel.documentType = this.fileExtension;
        this.createRaetTemplateCmdlet.execute(templateEditorFileModel);
        break;
      }

      case 'create-empty-template': {
        let templateEditorFileModel = new TemplateEditorFileModel();
        templateEditorFileModel.name = this.f.name.value;
        templateEditorFileModel.content = '';
        this.createRaetTemplateCmdlet.execute(templateEditorFileModel);
        break;
      }
    }

    window.location.reload();
    //this.router.navigate(['/raet-templates'], { relativeTo: this.route }).then(() => { window.location.reload(); });
    this.result = true;
    this.close();
    return;
  }

  onNextClicked(event) {
    if (!this.validateStep(this.currentStepId)) {
      return;
    }

    if (!this.validateStep(this.currentStepId)) {
      return;
    }

    const currentStepId = this.currentStepId;
    const wizardConfig: string[] = this.config[this.wizardType];
    const currentIndex = wizardConfig.findIndex(x => x == currentStepId);
    const isLastStep = currentIndex == wizardConfig.length - 1;

    if (!isLastStep) {
      this.currentStepId = wizardConfig[currentIndex + 1];
      event.preventDefault();
    }
    else {
      this.onFormSubmitted();
    }

  }

  onPrevClicked(event) {
    const currentStepId = this.currentStepId;
    const wizardConfig: string[] = this.config[this.wizardType];
    const currentIndex = wizardConfig.findIndex(x => x == currentStepId);
    this.currentStepId = wizardConfig[currentIndex - 1];
    event.preventDefault();
  }

  onTemplateLocationChanged() {

    console.log("On template location changed...");

    this.f.selectedFolder.setValue(undefined);
  }

  onTemplateChange($event) {
    console.log("onTemplateChange");
    this.selectedTemplateIdToCopy = $event.target.value;
    console.log($event.target.value)
  }

  async onModuleChange($event) {
    this.selectedModuleId = this.modules[0].id;
    //console.log($event.target.value)
    this.selectedModuleId = $event.target.value;
    console.log(this.selectedModuleId);
    //this.mergeFunctions = (await this.getModuleCmdlet.execute(this.selectedModuleId)).mergeFunctions;
    console.log(this.mergeFunctions)
    this.selectedMergeFunctionId = this.mergeFunctions[0].id;
  }

  onMergeFunctionChange($event) {
    this.selectedMergeFunctionId = $event.target.value;
    console.log(this.selectedMergeFunctionId)
  }

  // onDefaultTemplateChange($event) {
  //   console.log("onDefaultTemplateChange");
  // }

  onFileChange(event) {

    let reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.f.name.setValue(event.target.files[0].name);
      let extension = event.target.files[0].name;
      this.fileExtension = extension.split('.').pop();
      console.log("fileExtension: " + this.fileExtension)
      reader.readAsBinaryString(file);
      reader.onload = () => {

        this.templateFile = file;
        // need to run CD since file load runs outside of zone
        this.cd.markForCheck();
      };
    }
  }

  onUploadFileClicked(event) {
    document.getElementById("select-file").click();
    console.log("Un upload file button clicked...");
    event.preventDefault();
  }

  get f() { return this.formGroup.controls; }

  get wizardType(): string {
    return this.f.wizardType.value;
  }

  get selectedFileName() {
    return this.f.name.value;
  }

  private async getModel(): Promise<TemplateEditorFileModel> {

    const model = new TemplateEditorFileModel();
    model.name = this.f.name.value;
    //model.fileType = this.selectedTemplateType;

    if (this.templateFile) {
      model.content = await this.blobToBase64Async(this.templateFile);
    }

    return model;
  }

  async blobToBase64Async(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.onerror = (e) => reject(fileReader.error);
      fileReader.onloadend = (e) => {
        const dataUrl = fileReader.result as string;
        // remove "data:mime/type;base64," prefix from data url
        const base64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
        resolve(base64);
      };
      fileReader.readAsDataURL(blob);
    });
  }

  private validateStep(stepId: string): boolean {

    if (stepId == 'general-info-step') {
      return this.validateGenerelInfoStep();
    }

    // if (stepId == 'select-template-step') {
    //   return this.validateSelectTemplateStep();
    // }

    return true;
  }

  validateSelectTemplateStep(): boolean {

    let result = true;

    if (!this.f.selectedTemplate.value) {
      this.f.selectedTemplate.setErrors({ required: true });
      result = false;
    }

    return result;
  }

  validateGenerelInfoStep(): boolean {
    let result = true;

    if (!this.f.name.value) {
      this.f.name.setErrors({ required: true });
      result = false;
    }

    return result;
  }
}
