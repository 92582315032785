import { Component, ElementRef, OnInit, AfterViewInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

export interface Item {
  id: string;          
  displayName: string;
}

export interface MenuItem extends Item {
  active?: boolean; 
}

export interface SubheaderMenuItem extends MenuItem {
  subItems?: MenuItem[]; 
}

@Component({
  selector: 'app-youforce-navigation-bar',  
  template: ''
})
export class YouforceNavigationBarComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() subHeaderItems: SubheaderMenuItem[] = [];
  @Input() helpItems: Item[] = [];
  @Input() culture: string = 'nl-NL';
  @Input() activeItemId: string | null = null; 

  @Output() subHeaderItemClicked = new EventEmitter<string>();
  @Output() helpItemClicked = new EventEmitter<string>();
  @Output() logoutClicked = new EventEmitter<void>();

  private navigationBarComponent: any;

  constructor(private elementRef: ElementRef, private router: Router) {}

  ngOnInit(): void {
    console.info('YouforceNavigationBarComponent OnInit');
  }

  ngAfterViewInit(): void {    
    this.navigationBarComponent = document.createElement('youforce-navigation-bar');    
    this.elementRef.nativeElement.appendChild(this.navigationBarComponent);
    this.updateWebComponentProperties();
    this.bindNavigationBarEvents(this.navigationBarComponent);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.navigationBarComponent) {
      if (changes['activeItemId'] && this.activeItemId) {
        this.updateActiveItem(); 
      }
      this.updateWebComponentProperties();
    }
  }

  private updateWebComponentProperties(): void {
    if (this.culture) {
      this.culture = this.normalizeCulture(this.culture);        
      this.navigationBarComponent.setAttribute('culture', this.culture);
    }
    if (this.subHeaderItems) {
      this.navigationBarComponent.subHeaderItems = this.subHeaderItems;
    }
    if (this.helpItems) {
      this.navigationBarComponent.helpItems = this.helpItems;
    }
  }

  private bindNavigationBarEvents(navigationBarComponent: HTMLElement): void {
    navigationBarComponent.addEventListener('subHeaderItemClicked', (event: any) => {      
      this.subHeaderItemClicked.emit(event.detail);
    });

    navigationBarComponent.addEventListener('helpItemClicked', (event: any) => {
      this.helpItemClicked.emit(event.detail); 
    });
    
    navigationBarComponent.addEventListener('logoutClicked', (event: any) => {
      this.logoutClicked.emit(event.detail);
    });
  }

  // Update the active state of items based on activeItemId
  private updateActiveItem(): void {    
    this.subHeaderItems = this.subHeaderItems.map(item => {
      item.active = item.id === this.activeItemId; // Set parent item active if it matches activeItemId

      if (item.subItems) {
        item.subItems = item.subItems.map(subItem => {
          subItem.active = subItem.id === this.activeItemId; // Set subitem active if it matches activeItemId
          if (subItem.active) item.active = true; // Set parent active if any subitem is active
          return subItem;
        });
      }
      return item;
    });

    // Update the web component's subHeaderItems property
    this.navigationBarComponent.subHeaderItems = this.subHeaderItems;
  }

  // Navigation bar only supports 'nl-NL' and 'en-GB'. default is 'nl-NL'
  // Cultures starting with 'en' will be converted to 'en-GB'
  // Other cultures will be converted to 'nl-NL'
  private normalizeCulture(culture: string): string {
    if (culture.startsWith('en')) {
      return 'en-GB';
    } else {
      return 'nl-NL';
    }    
  }
}
