// busy.service.ts

import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BusyService {
  private renderer: Renderer2;
  private busyCount = 0;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  showBusy() {
    this.busyCount++;
    if (this.busyCount === 1) {
      this.renderer.addClass(document.body, 'busy-cursor');
    }
  }

  hideBusy() {
    if (this.busyCount > 0) {
      this.busyCount--;
      if (this.busyCount === 0) {
        this.renderer.removeClass(document.body, 'busy-cursor');
      }
    }
  }
}
