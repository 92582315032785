import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { RaetTemplatesApiClient } from "../api-clients/RaetTemplatesApiClient";
import { RaetTemplateOm } from "../types/RaetTemplateOm";

@Injectable({ providedIn: 'root' })
export class GetRaetTemplateMetaJob implements IJob<number, RaetTemplateOm> {

  constructor(private raetTemplatesApiClient: RaetTemplatesApiClient) { }

  async execute(id: number): Promise<RaetTemplateOm> {
    const raetTemplateOm = await this.raetTemplatesApiClient.getRaetTemplateMeta(id);
    console.log(raetTemplateOm);
    return raetTemplateOm;
  }
}
