import { Inject, Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { DataDefinitionDm } from '../types/DataDefinitionDm';
import { TemplatesApiClient } from '../api-clients/TemplatesApiClient';

@Injectable({ providedIn: 'root' })
export class GetMergeDataSpecificationJob implements IJob<number, DataDefinitionDm> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(id: number): Promise<DataDefinitionDm> {
    const dataDefinitionDm = await this.templatesApiClient.getMergeDataSpecification(id);
    console.log('datadefinition from GetMergeDataSpecificationJob: ')
    console.log(dataDefinitionDm);
    return dataDefinitionDm;
  }
}
