<div class="right-content-container">  
  <br>
  <div class=""><button class="redirectBtn" (click)="redirect()">{{templateName}}</button> > <button
      class="redirectBtn2">{{ 'headers.edit' | translate }}</button>
  </div>
  <br>
  <alert></alert>
  <br>
  <!-- <div class="page-toolbar">RaetTemplate bewerken</div> -->
  <!-- <br> -->
  <div class="table-header" id="editor-buttons">
    <button (click)="onCancelTemplateClicked($event)" class="editButton">{{ 'buttons.cancel' | translate }}</button>
    <button (click)="onClearTemplateClicked($event)" class="editButton">{{ 'buttons.undo_changes' | translate }}</button>
    <button (click)="onSaveTemplateClicked($event)" class="editButton3">{{ 'buttons.save' | translate }}</button>
    <button (click)="onCompleteTemplateClicked($event)" class="editButton3">{{ 'buttons.finish' | translate }}</button>
  </div>
  <!-- <br> -->
  <div class="editor-container">
    <div #documentEditorContainer class="editor-absolute-container hide-ribbonbar" [ngClass]="{'loaded': ribbonsLoaded}">
      <tx-document-editor webSocketURL="wss://_tcx.krabbl.nl:443" class="document-editor" [uiCulture]="myCulture" [culture]="myCulture"></tx-document-editor>
    </div>
  </div>
</div>