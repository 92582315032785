import { Injectable } from "@angular/core";

import { DisplayNameService } from "../../common/services/DisplayNameService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { ASourceFileUm4Meta } from "src/app/common/types/helpers/ASourceFileUm4Meta";
import { AlertService } from "src/app/services/AlertService";
import { ComponentService } from "src/app/services/ComponentService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { UpdateATemplateJob4Meta } from "../jobs/UpdateATemplateJob4Meta";

@Injectable({ providedIn: "root" })
export class UpdateATemplateCmdlet4Meta extends CmdletBase<ASourceFileUm4Meta, VoidResult> {

  constructor(private jobService: JobService,
    private job: UpdateATemplateJob4Meta,
    private displayNameService: DisplayNameService,
    private alertService: AlertService,
    componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: ASourceFileUm4Meta): Promise<VoidResult> {
    await this.jobService.runJob<UpdateATemplateJob4Meta, ASourceFileUm4Meta, VoidResult>(this.job, arg);
    //var itemName = this.displayNameService.getDisplayName(arg.fileType);
    this.alertService.success(`Template meta has been updated successfully`);
    return new VoidResult();
  }
}
