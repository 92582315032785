import { Injectable } from '@angular/core';
import { SetupEditorReq } from "../types/helpers/SetupEditorReq";
import { ITextControlJob } from '../interfaces/ITextControlJob';
import { TextControlJobService } from '../services/TextControlJobService';
import { ITextControl } from '../interfaces/ITextControl';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
export declare const TXTextControl: any;

@Injectable({ providedIn: 'root' })
class SetupEditorJob implements ITextControlJob<SetupEditorReq, VoidResult> {

  constructor() { }

  async execute(textControl: ITextControl, arg: SetupEditorReq): Promise<VoidResult> {

    TXTextControl.tableGridLines = false;

    return new Promise<VoidResult>((resolve, reject) => {

      if (arg.isReadMode) {
        textControl.instance.setEditMode(TXTextControl.EditMode.ReadOnly, d => resolve(d), e => reject(e));
      }
      else {
        textControl.instance.setEditMode(TXTextControl.EditMode.Edit, d => resolve(d), e => reject(e));
      }

    });
  }
}

@Injectable({ providedIn: 'root' })
export class SetupEditorCmdlet {

  constructor(private job: SetupEditorJob, private jobService: TextControlJobService) { }

  async run(arg: SetupEditorReq): Promise<VoidResult> {
    await this.jobService.runJob(this.job, arg);
    return new VoidResult()
  }
}
