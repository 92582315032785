import { Injectable } from '@angular/core';
import { ITextControlJob } from '../interfaces/ITextControlJob';
import { TextControlJobService } from '../services/TextControlJobService';
import { ITextControl } from '../interfaces/ITextControl';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
declare const TXTextControl: any;

@Injectable({ providedIn: 'root' })
class LoadEditorJob implements ITextControlJob<string, VoidResult> {

  constructor() { }

  async execute(textControl: ITextControl, fileContent: string): Promise<VoidResult> {
    return new Promise<VoidResult>((resolve, reject) => {
      textControl.instance.loadDocument(TXTextControl.StreamType.WordprocessingML, fileContent, () => resolve(new VoidResult()));
    });
  }
}

@Injectable({ providedIn: 'root' })
export class LoadEditorCmdlet {

  constructor(private job: LoadEditorJob, private jobService: TextControlJobService) { }

  async run(fileContent: string): Promise<VoidResult> {
    await this.jobService.runJob(this.job, fileContent);
    return new VoidResult();
  }
}
