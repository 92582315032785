import { Injectable } from '@angular/core';
import { ITableRow } from '../interfaces/ITableRow';
import { DataPage } from '../types/helpers/DataPage';

@Injectable({ providedIn: 'root' })
export class PageService<T extends ITableRow> {

  pageSize: number = 50;

  page(source: T[], pageIndex: number): DataPage<T> {

    let canPrev = pageIndex > 0;

    let x = Math.round(source.length / this.pageSize);
    let z = source.length - x * this.pageSize;
    let totalPages = x + (z > 0 ? 1 : 0);

    let canNext = pageIndex < totalPages - 1;

    let rows = source.slice(pageIndex * this.pageSize, pageIndex * this.pageSize + this.pageSize);

    var dataPage: DataPage<T> = new DataPage<T>();

    return Object.assign(dataPage, { canPrev: canPrev, canNext: canNext, totalPages: totalPages, rows: rows });
  }
}
