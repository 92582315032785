import { Injectable } from "@angular/core";
import { DisplayNameService } from "../../common/services/DisplayNameService";
import { DeleteTemplateReq } from "../types/helpers/DeleteTemplateReq";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { AlertService } from "src/app/services/AlertService";
import { ComponentService } from "src/app/services/ComponentService";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { DeleteATemplateJob } from "../jobs/DeleteATemplateJob";

@Injectable({ providedIn: "root" })
export class DeleteATemplateCmdlet extends CmdletBase<number, VoidResult> {

    constructor(private jobService: JobService, private alertService: AlertService,
        private displayNameService: DisplayNameService,
        private job: DeleteATemplateJob, componentService: ComponentService) {
        super(componentService);
    }

    protected async executeImpl(arg: number): Promise<VoidResult> {
        await this.jobService.runJob<DeleteATemplateJob, number, VoidResult>(this.job, arg);
        this.alertService.success(`Template with id ${arg} has been deleted`);
        return new VoidResult();
    }
}
