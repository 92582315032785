import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { MergeFunctionOvm } from "src/app/types/dataModels/MergeFunctionOvm";
import { GetRaetMergeFunctionsJob } from "../jobs/GetRaetMergeFunctionsJob";

@Injectable({ providedIn: 'root' })
export class GetRaetMergeFunctionsCmdlet extends CmdletBase<boolean, MergeFunctionOvm[]> {

  constructor(private jobService: JobService, private job: GetRaetMergeFunctionsJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(forceRefresh: boolean): Promise<MergeFunctionOvm[]> {
    console.info('GetRaetMergeFunctionsCmdlet...');
    let result = await this.jobService.runJob<GetRaetMergeFunctionsJob, boolean, MergeFunctionOvm[]>(this.job, forceRefresh);
    return result;
  }
}
