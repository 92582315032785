<div id="BackgroundDiv">
  <div class="">
    <div class="txui-dialog-titlebar txui-widget-header txui-corner-all txui-helper-clearfix">
      <span id="txui-id-1671" class="txui-dialog-title">Date Field Options</span>
      <button id="butt" (click)="onCloseClicked($event)"
        class="txui-button txui-widget txui-state-default txui-corner-all txui-button-icon-only txui-dialog-titlebar-close">
        <span class="txui-button-icon-primary txui-icon txui-icon-closethick" style="pointer-events: none"></span>
        <span class="txui-button-text" style="pointer-events: none">Close</span>
      </button>
    </div>

    <div class="txDialog txui-dialog-content txui-widget-content">
      <div id="OfficelinkModalContent">
        <div id="mergeFieldDialog" class="txDialog txui-dialog-content txui-widget-content">
          <fieldset id="MERGE_FIELD">
            <label id="DATEFIELD_LABEL_DATE_FORMATS" for="txtDateFormat" style="float: left">Date Formats:</label>
            <input type="text" id="txtDateFormat" style="float: left; width: 95%" value="dd.MM.yyyy"
              [(ngModel)]="customFormat" [disabled]="!disabledFormats">
            <input type="checkbox" id="chkCustomDateFormat" (click)="disabledFormats = !disabledFormats"
              style="float: left">
            <label id="DATEFIELD_LABEL_CUSTOM_DATE_FORMATS" for="chkCustomDateFormat" style="float: left">Custom Date
              Format</label>
            <select size="12" id="selCustomDateFormats" style="float: left; width: 100%"
              (change)="onDateFieldChange($event)" [disabled]="disabledFormats">
              <option value="dd.MM.yyyy">18.06.2020</option>
              <option value="dddd, d. MMMM yyyy">Friday, 18. September 2020</option>
              <option value="dddd d MMMM yyyy">Friday 18 September 2020</option>
              <option value="d. MMMM yyyy">18. September 2020</option>
              <option value="d MMMM yyyy">18 September 2020</option>
              <option value="dd.MM.yy">18.06.20</option>
              <option value="dd-MM-yy">18-06-20</option>
              <option value="yyyy-MM-dd">2020-06-18</option>
              <option value="dd-MM-yyyy">18-06-2020</option>
              <option value="yy-MM-dd">20-06-18</option>
              <option value="dd. MMM. yyyy">18. 09. 2020</option>
              <option value="dd MMM. yyyy">18 Jun. 2020</option>
              <option value="dd-MMM-yyyy">18-09-2020</option>
              <option value="dd/MM/yy">18/06/20</option>
              <option value="MMMM yy">September 20</option>
              <option value="MMM-yy">Jun-20</option>
              <option value="dd.MM.yyyy HH:mm">18.06.2020 09:15</option>
              <option value="dd-MM-yyyy HH:mm">18-06-2020 09:15</option>
              <option value="dd.MM.yyyy HH:mm:ss">18.06.2020 09:15:19</option>
              <option value="dd-MM-yyyy HH:mm:ss">18-06-2020 09:15:19</option>
              <option value="h:mm tt">9:15 AM</option>
              <option value="h:mm:ss tt">9:15:19 AM</option>
              <option value="HH:mm">09:15</option>
              <option value="HH:mm:ss">09:15:19</option>
            </select>
            <input type="checkbox" id="chkPresFmt" style="float: left" [checked]="preserveFormatting"
              (change)="preserveFormatting = !preserveFormatting">
            <label id="DATEFIELD_CHECK_BOX_PRESERVE_FORMAT" for="chkPresFmt" style="float: left">Preserve formatting
              during updates</label>
          </fieldset>
        </div>
        <div class="txui-dialog-buttonpane txui-widget-content txui-helper-clearfix">
          <div class="txui-dialog-buttonset">
            <button type="button" id="modalOkButton" (click)="insertExpressionField()"
              class="txui-button txui-widget txui-state-default txui-corner-all txui-button-text-only" role="button"
              aria-disabled="false">
              <span id="MODAL_OK" class="txui-button-text">Ok</span>
            </button>
            <button type="button" id="modalCancelButton" (click)="onCloseClicked($event)"
              class="txui-button txui-widget txui-state-default txui-corner-all txui-button-text-only" role="button"
              aria-disabled="false">
              <span id="MODAL_CANCEL" class="txui-button-text">Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>