import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogComponentBase } from 'src/app/common/components/DialogComponentBase';
import { DisplayNameService } from 'src/app/common/services/DisplayNameService';
import { FileTypeEnum } from 'src/app/common/types/enums/FileTypeEnum';
import { ASourceFileOm } from 'src/app/common/types/models/ASourceFileOm';
import { ComponentService } from 'src/app/services/ComponentService';
import { DeleteTemplateReq } from '../../types/helpers/DeleteTemplateReq';
import { DeleteATemplateCmdlet } from '../../cmdlets/DeleteATemplateCmdlet';
import { BaseTemplateOdm } from 'src/app/types/dataModels/BaseTemplateOdm';
import { BaseTemplateOvm } from 'src/app/types/viewModels/BaseTemplateOvm';

@Component({
  selector: 'app-base-template-delete',
  templateUrl: './base-template-delete.component.html',
  styleUrls: ['./base-template-delete.component.scss']
})
export class BaseTemplateDeleteComponent extends DialogComponentBase<BaseTemplateDeleteComponent, boolean> implements OnInit {

  fileType: FileTypeEnum;
  selectedFile: BaseTemplateOvm;
  selectedTemplate: BaseTemplateOdm;

  constructor(componentService: ComponentService,
    private deleteATemplateCmdlet: DeleteATemplateCmdlet,
    private displayNameService: DisplayNameService,
    dialogRef: MatDialogRef<BaseTemplateDeleteComponent, boolean>) {
    super(componentService, dialogRef);
  }

  async ngOnInit() {
    super.ngOnInit();
    this.isLoaded = true;
  }

  get templateTypeDisplayName() {
    return this.displayNameService.getDisplayName(this.fileType);
  }

  onSubmitClicked() {
    //let req: DeleteTemplateReq = new DeleteTemplateReq();
    let id = this.selectedFile.id;
    //req.fileType = this.fileType;
    this.deleteATemplateCmdlet.execute(id);
    this.result = true;
  }
}
