import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { TemplateOvm } from "src/app/types/viewModels/TemplateOvm";

@Injectable({ providedIn: 'root' })
export class GetMergeFunctionTemplatesByIdJob implements IJob<{ mergeFunctionId: number, global: boolean, forceRefresh?: boolean }, TemplateOvm[]> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: { mergeFunctionId: number, global: boolean, forceRefresh?: boolean }): Promise<TemplateOvm[]> {
    const forceRefresh = arg.forceRefresh || false;
    const templatesData = await this.mergeFunctionsApiClient.getMergeFunctionTemplates(arg.mergeFunctionId, arg.global, forceRefresh);

    const templatesOvm: TemplateOvm[] = templatesData.templates.map(data => {
      const template = new TemplateOvm();
      return Object.assign(template, data);
    });
    
    return templatesOvm;
  }
}
