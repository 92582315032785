import { Injectable } from "@angular/core";
import { ITextControlJob } from "../interfaces/ITextControlJob";
import { TextControlJobService } from "../services/TextControlJobService";
import { ITextControl } from "../interfaces/ITextControl";
import { VoidArg } from "src/app/types/helpers/VoidArg";
declare const TXTextControl: any;

@Injectable({ providedIn: 'root' })
class ExtractFileContentJobAsWord implements ITextControlJob<VoidArg, string> {

  execute(textControl: ITextControl, arg: VoidArg): Promise<string> {

    return new Promise<string>((resolve, reject) => {
      textControl.instance.saveDocument(
        TXTextControl.StreamType.WordprocessingML,
        (e) => {
          return resolve(e.data);
        }
      );
    });
  }
}


@Injectable({ providedIn: 'root' })
export class ExtractFileContentAsWordCmdlet {

  constructor(private job: ExtractFileContentJobAsWord, private jobService: TextControlJobService) {
  }

  async run(): Promise<string> {
    const result = await this.jobService.runJob<ExtractFileContentJobAsWord, VoidArg, string>(this.job, new VoidArg());
    return result;
  }
}
