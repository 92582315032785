import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { MergeFunctionOvm } from "src/app/types/dataModels/MergeFunctionOvm";
import { GetMergeFunctionsByModuleIdJob } from "../jobs/GetMergeFunctionsByModuleIdJob";

@Injectable({ providedIn: 'root' })
export class GetMergeFunctionsCmdlet extends CmdletBase<{ moduleId: number, global: boolean, forceRefresh?: boolean }, MergeFunctionOvm[]> {

  constructor(private jobService: JobService, private job: GetMergeFunctionsByModuleIdJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: { moduleId: number, global: boolean, forceRefresh?: boolean }): Promise<MergeFunctionOvm[]> {
    // Passing global argument
    let result = await this.jobService.runJob<GetMergeFunctionsByModuleIdJob, { moduleId: number, global: boolean, forceRefresh?: boolean }, MergeFunctionOvm[]>(this.job, arg);
    return result;
  }
}
