import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionsApiClient } from "../api-clients/MergeFunctionsApiClient";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { MergeFunctionUm4PdCategoryReq } from "src/app/types/dataModels/MergeFunctionUm4PdCategoryReq";

@Injectable({ providedIn: 'root' })
export class UpdateMergeFunctionPdCategoryJob implements IJob<MergeFunctionUm4PdCategoryReq, VoidResult> {

  constructor(private mergeFunctionsApiClient: MergeFunctionsApiClient) { }

  async execute(arg: MergeFunctionUm4PdCategoryReq): Promise<VoidResult> {
    await this.mergeFunctionsApiClient.UpdateMergeFunctionPdCategory(arg);
    return VoidResult;
  }
}
