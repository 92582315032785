import { Injectable } from "@angular/core";
import { ITextControlJob } from "../interfaces/ITextControlJob";
import { TextControl } from "./TextControl";
import { AppInsightsLoggerService } from "src/app/services/logging.service";

@Injectable({ providedIn: "root" })
export class TextControlJobService {

  constructor(private logService: AppInsightsLoggerService) { }

  async runJob<TJob extends ITextControlJob<TArg, TResult>, TArg, TResult>(job: TJob, arg: TArg): Promise<TResult> {

    const jobName = job.constructor.name;

    try {
      const textControl = new TextControl();
      let result = await job.execute(textControl, arg);
      return result;
    } catch (error) {
      this.logService.error(`Text control job '${jobName}' failed`, arg);
      throw error;
    }
  }
}
