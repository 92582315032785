import { Injectable } from '@angular/core';
import MarkdownIt from 'markdown-it';

@Injectable({
  providedIn: 'root'
})
export class MarkdownService {
  private md: MarkdownIt;

  constructor() {
    this.md = new MarkdownIt();
  }

  convert(markdown: string): string {
    return this.md.render(markdown);
  }
}
