import { Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { TemplatesApiClient } from '../api-clients/TemplatesApiClient';
import { TemplateVersionRevertIm } from '../types/TemplateVersionRevertIm';

@Injectable({ providedIn: 'root' })
export class RevertTemplateVersionCmdletJob implements IJob<TemplateVersionRevertIm, VoidResult> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(model: TemplateVersionRevertIm): Promise<VoidResult> {
    await this.templatesApiClient.revertTemplateVersion(model);
    return VoidResult;
  }
}
