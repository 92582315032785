import { Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { TemplatesApiClient } from '../api-clients/TemplatesApiClient';
import { TemplateCopyIm } from '../types/TemplateCopyIm';

@Injectable({ providedIn: 'root' })
export class CopyTemplateJob implements IJob<TemplateCopyIm, VoidResult> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(m: TemplateCopyIm): Promise<any> {
    let result = await this.templatesApiClient.copyTemplate(m);
    return result;
  }
}
