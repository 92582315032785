import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { TemplatesApiClient } from "../api-clients/TemplatesApiClient";
import { RaetFile } from "src/app/types/dataModels/RaetFile";
import { BaseTemplatesApiClient } from "src/app/base-templates/api-clients/BaseTemplatesApiClient";

@Injectable({ providedIn: 'root' })
export class GetTemplateBlobJob2 implements IJob<number, Blob> {

  constructor(private templatesApiClient: TemplatesApiClient,
    private sourceFilesClient: BaseTemplatesApiClient) { }

  async execute(id: number): Promise<Blob> {
    let res = await this.sourceFilesClient.getSourceFileContent(id);
    return res;
  }
}
