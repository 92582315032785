export class SelectOption {
    id: string;
    text: string;
    isSelected?: boolean;

    constructor() {
        this.id = undefined;
        this.text = '';
    }
}
