import { Injectable } from "@angular/core";
import { AnonimOwnHttpClient } from "src/app/common/api-clients/AnonimOwnHttpClient";
import { AuthOwnHttpClient } from "src/app/common/api-clients/AuthOwnHttpClient";
import { AuthContextModel } from "src/app/types/dataModels/AuthContextModel";
import { DocumentTokenModel } from "src/app/types/dataModels/DocumentTokenModel";
import UsernameNotFoundError from "src/app/types/exceptions/UsernameNotFoundError";


@Injectable({ providedIn: "root", })
export class AuthenticationApiClient {
  constructor(private authHttpClient: AuthOwnHttpClient, private anonimHttpClient: AnonimOwnHttpClient) { }

  async loginWithUserCredentials(userName: string, password: string, pingToken: string): Promise<AuthContextModel> {
    const relativeUrl = `/login`;
    const payload = { pingToken: pingToken };
    let response = await this.anonimHttpClient.send<AuthContextModel>("POST", relativeUrl, payload);
    return response;
  }

  async loginWithDocumentToken(token: string): Promise<DocumentTokenModel> {
    const relativeUrl = `/authentication/login-with-document-token`;
    const payload = { documentToken: token };
    let response = await this.anonimHttpClient.send<DocumentTokenModel>("POST", relativeUrl, payload);
    return response;
  }

  async getUserExists(username: string): Promise<boolean> {
    let z: string = window.encodeURI(username);
    const relativeUrl = `/authentication/queries/get-user-exists?username=${z}`;

    try {
      let r = await this.anonimHttpClient.send<boolean>("GET", relativeUrl);
      return r;
    }
    catch (error) {
      throw new UsernameNotFoundError();
    }
  }

  async switchUser(targetUserId: number): Promise<AuthContextModel> {
    let url = `/authentication/commands/switch-user?targetUserId=${targetUserId}`;
    return await this.authHttpClient.send<AuthContextModel>("POST", url);
  }

  async refreshUserToken(): Promise<AuthContextModel> {
    const relativeUrl = `/authentication/commands/refresh-token`;
    var response = await this.authHttpClient.send<AuthContextModel>("POST", relativeUrl, {});
    return response;
  }

  // async startResetPassword(username: string): Promise<string> {
  //     let z: string = window.encodeURI(username);
  //     const relativeUrl = `/authentication/commands/begin-reset-password/${z}`;

  //     try {
  //         let r = await this.anonimHttpClient.send<string>("GET", relativeUrl);
  //         return r;
  //     }
  //     catch (error) {
  //         throw new UnexpectedError();
  //     }
  // }

  // async finishResetPassword(username: string, newPassword: string, resetCode: string): Promise<string> {
  //     const relativeUrl = `/authentication/commands/finish-reset-password`;

  //     try {
  //         let payload = { username: username, password: newPassword, token: resetCode };
  //         let r = await this.anonimHttpClient.send<string>("POST", relativeUrl, payload);
  //         console.log("Password has been updated");
  //         return r;
  //     }
  //     catch (error) {

  //         if (error.status == 404) {
  //             console.log("Invalid username");
  //             throw new UsernameNotFoundError();
  //         }

  //         if (error.status == 401) {
  //             console.log("Reset code is invalid");
  //             throw new InvalidResetPasswordCodeError();
  //         }

  //         console.log("Unexpected exception...");
  //         throw new UnexpectedError();
  //     }
  // }
}
