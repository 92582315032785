import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { GetBaseTemplateMetaJob } from "../jobs/GetBaseTemplateMetaJob";
import { BaseTemplateOdm } from "src/app/types/dataModels/BaseTemplateOdm";

@Injectable({ providedIn: 'root' })
export class GetBaseTemplateMetaCmdlet extends CmdletBase<number, BaseTemplateOdm> {

  constructor(private jobService: JobService, private job: GetBaseTemplateMetaJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: number): Promise<BaseTemplateOdm> {
    let result = await this.jobService.runJob<GetBaseTemplateMetaJob, number, BaseTemplateOdm>(this.job, arg);
    return result;
  }
}
