import { Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { OwnFile } from 'src/app/types/helpers/OwnFile';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { RaetTemplatesApiClient } from '../api-clients/RaetTemplatesApiClient';
import { RaetTemplateIm } from '../types/RaetTemplateIm';

@Injectable({ providedIn: 'root' })
export class CreateRaetTemplateJob implements IJob<TemplateEditorFileModel, any> {

  constructor(private httpClient: RaetTemplatesApiClient) { }

  async execute(m: TemplateEditorFileModel): Promise<any> {
    const self = this;
    let model = new RaetTemplateIm();
    Object.assign(model, m);
    model.name = m.name;
    model.description = m.description;
    model.mergeFunctionId = m.mergeFunctionId;
    var ownFile = new OwnFile();
    ownFile.fileName = m.name;
    ownFile.content = m.content;
    ownFile.contentType = 'application/text-control';
    model.file = ownFile;
    let templateExtension = m.documentType;

    let result = await this.httpClient.createRaetTemplate(model, templateExtension);
    return result;
  }
}
