import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { OwnFile } from "src/app/types/helpers/OwnFile";
import { TemplateEditorFileModel } from "src/app/base-templates/types/helpers/TemplateEditorFileModel";
import { TemplatesApiClient } from "../api-clients/TemplatesApiClient";
import { TemplateDdm } from "src/app/types/dataModels/TemplateDdm";
import { TemplateVersionIm } from "../types/TemplateVersionIm";

@Injectable({ providedIn: "root" })
export class UpdateTemplateContentJob implements IJob<TemplateEditorFileModel, VoidResult> {

  constructor(private templatesApiClient: TemplatesApiClient) { }

  async execute(arg: TemplateEditorFileModel): Promise<VoidResult> {
    let templateVersionIm = new TemplateVersionIm();
    templateVersionIm.templateId = arg.id;
    templateVersionIm.name = arg.name;
    templateVersionIm.description = arg.description;

    let ownFile = new OwnFile();
    ownFile.fileName = arg.name;
    ownFile.contentType = 'application/text-control';
    ownFile.content = arg.content;
    templateVersionIm.file = ownFile;

    await this.templatesApiClient.createTemplateVersion222(templateVersionIm);
    return new VoidResult();
  }
}



