import { Injectable } from "@angular/core";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { ComponentService } from "src/app/services/ComponentService";
import { DownloadSourceFileReq } from "../types/helpers/DownloadSourceFileReq";
import { DownloadATemplateJob } from "../jobs/DownloadATemplateJob";

@Injectable({ providedIn: "root" })
export class DownloadATemplateCmdlet extends CmdletBase<DownloadSourceFileReq, Blob> {

    constructor(private jobService: JobService, private job: DownloadATemplateJob, componentService: ComponentService) {
        super(componentService);
    }
    
    protected async executeImpl(arg: DownloadSourceFileReq): Promise<Blob> {
        const blob = await this.jobService.runJob<DownloadATemplateJob, DownloadSourceFileReq, Blob>(this.job, arg);
        return blob;
    }
}