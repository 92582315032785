import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { HelpComponent } from '../help/help.component';
import { ComponentContext } from 'src/app/services/ComponentContext';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, OnDestroy {
  hasHelpText: boolean = false; // Tracks if there's a helpText configured
  routerEventsSubscription: Subscription;

  constructor(
    private dialog: MatDialog,
    public componentContext: ComponentContext,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    // Subscribe to Router events to track navigation changes
    this.routerEventsSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd)) // Only track when navigation is complete
      .subscribe(() => {
        this.checkForHelpText(); // Check for help text on each route change
      });

    // Initial check on app load
    this.checkForHelpText();
  }

  ngOnDestroy() {
    // Unsubscribe to prevent memory leaks
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }

  checkForHelpText() {
    // Navigate to the last active child route to check if helpText is defined
    let currentRoute = this.route;
    while (currentRoute.firstChild) {
      currentRoute = currentRoute.firstChild;
    }

    // Subscribe to route data to check if a helpText exists
    currentRoute.data.subscribe((data) => {
      this.hasHelpText = !!data['helpText']; // Check if 'helpText' is present in the route data
    });
  }

  onHelpClicked() {
    if (this.hasHelpText) {
      this.componentContext.isDiaglogShown = true;
      const config: MatDialogConfig = new MatDialogConfig();
      const dialogRef1 = this.dialog.open<HelpComponent, any, boolean>(HelpComponent, config);
      dialogRef1.afterClosed().subscribe(() => {
        this.componentContext.isDiaglogShown = false;
      });
    }
  }

  printPage() {
    window.print();
  }
}
