export default class CannotAuthenticateUserError extends Error {

    constructor() {
        super("Invalid username or password");
        this.name = "CannotAuthenticateUserError";
    }
}




