import { Injectable } from "@angular/core";
import { OwnHttpClient } from "./OwnHttpClient";
import { HttpContentBase, JsonHttpContent, BlobHttpContent } from "src/app/types/helpers/HttpContent";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { RaetFile } from "src/app/types/dataModels/RaetFile";

@Injectable({ providedIn: "root" })
export class AuthOwnHttpClient {
  constructor(private ownHttpClient: OwnHttpClient) { }

  async send2(method: string, relativeUrl: string, payload?: any): Promise<Blob> {
    return this.ownHttpClient.send2(method, relativeUrl, payload);
  }

  async send<T>(method: string, relativeUrl: string, payload?: any): Promise<T> {
    return this.ownHttpClient.send<T>(method, relativeUrl, payload);
  }

  async send3<T>(method: string, relativeUrl: string, model: any, file: Blob): Promise<T> {
    return this.ownHttpClient.send3<T>(method, relativeUrl, model, file);
  }

  async getMultipart(relativeUrl: string): Promise<HttpContentBase[]> {
    const result = await this.ownHttpClient.getMultipart(relativeUrl);
    // console.log('AuthOwnHttpClient getMultipart')
    // console.log(result)
    return result;
  }

  async getMultipart2(relativeUrl: string): Promise<RaetFile> {
    const result = await this.ownHttpClient.getMultipart2(relativeUrl);
    // console.log('AuthOwnHttpClient getMultipart')
    // console.log(result)
    return result;
  }

  async putMultipart(relativeUrl: string, jsonContent: JsonHttpContent, blobContent: BlobHttpContent): Promise<VoidResult> {
    await this.ownHttpClient.putMultipart(relativeUrl, jsonContent, blobContent);
    return new VoidResult();
  }

  async postMultipart(relativeUrl: string, jsonContent: JsonHttpContent, blobContent: BlobHttpContent): Promise<any> {
    let result = await this.ownHttpClient.postMultipart(relativeUrl, jsonContent, blobContent);
    return result;
  }
}
