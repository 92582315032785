import { Injectable } from '@angular/core';
import { PageService } from "./PageService";
import { FilterService } from "./FilterService";
import { ITableRow } from '../interfaces/ITableRow';
import { DataPage } from '../types/helpers/DataPage';
import { SortService } from './SortService';

export class FilterConfig {
    columns: string[];
    searchText: string;
}

export class SortConfig {
    sortColumn: string;
    sortDirection: string;
}

export class TableConfig {
    filterConfig: FilterConfig;
    sortConfig: SortConfig;
    pageConfig: PageConfig;
}

export class PageConfig {
    pageIndex: number;    
}

@Injectable({ providedIn: 'root' })
export class TableService<T extends ITableRow>  {

    constructor(private pageService: PageService<T>, private sortService: SortService<T>, private filterService: FilterService<T>) { 
    }

    getDataPage(source: T[], config: TableConfig): DataPage<T> {
        let filteredRows = this.filterService.filter(source, config.filterConfig.columns, config.filterConfig.searchText);
        let sortColumn = config.sortConfig.sortColumn;
        let sortDirection = config.sortConfig.sortDirection;
        let pageIndex = config.pageConfig.pageIndex;
        let r = this.sortService.sort(filteredRows, sortColumn, sortDirection);
        var z = this.pageService.page(r, pageIndex);

        return z;
    }

    filterRows(source: T[], filterConfig: FilterConfig): T[] {
        return this.filterService.filter(source, filterConfig.columns, filterConfig.searchText);
    }

    sortRows(source: T[], sortConfig: SortConfig): T[] {
        return this.sortService.sort(source, sortConfig.sortColumn, sortConfig.sortDirection);
    }
}
