import { Injectable } from '@angular/core';
import { CmdletBase } from 'src/app/common/services/CmdletBase';
import { JobService } from 'src/app/common/services/JobService';
import { ComponentService } from 'src/app/services/ComponentService';
import { TemplateEditorFileModel } from 'src/app/base-templates/types/helpers/TemplateEditorFileModel';
import { CreateRaetTemplateJob } from '../jobs/CreateRaetTemplateJob';

@Injectable({ providedIn: 'root' })
export default class CreateRaetTemplateCmdlet extends CmdletBase<TemplateEditorFileModel, any> {

  constructor(private jobService: JobService,
    componentService: ComponentService,
    private createTemplateJob: CreateRaetTemplateJob) {
    super(componentService);
  }

  async executeImpl(m: TemplateEditorFileModel): Promise<any> {
    let id = await this.jobService.runJob<CreateRaetTemplateJob, TemplateEditorFileModel, any>(this.createTemplateJob, m);
    return id;
  }
}
