<div class="dialog-layout" *ngIf="isLoaded">

  <div class="left-panel">

    <div class="main-text">
      <p>Wijzigen</p>
      <p>{{templateType}}</p>
    </div>

  </div>

  <div class="right-panel form">

    <form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="onFormSubmitted()" disabled="success">

      <div class="close-button">
        <button class="dialog-close-button" type="button" title="Sluiten" (click)="onCloseClicked($event)">x</button>
      </div>

      <div class="wizard">

        <div class="step" id="start-step" *ngIf="currentStepId == 'start-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <p>Update options</p>
            <p>Please select one of the following options</p>
          </div>

          <div class="body">

            <div class="form">

              <div class="form-row radio-row">
                <label for="update-content">Update content</label>
                <div class="form-control">
                  <input id="update-content" name="wizardType" formControlName="wizardType" type="radio"
                    value="update-content">
                </div>
              </div>

              <div class="form-row radio-row">
                <label for="update-meta">Update other information</label>
                <div class="form-control">
                  <input id="update-meta" name="wizardType" formControlName="wizardType" type="radio"
                    value="update-meta">
                </div>
              </div>

            </div>

          </div>

          <div class="footer">

            <button type="button" class="optional-button" [disabled]="isLoading"
              (click)='onCancelClicked($event)'>Annuleren</button>

            <button type="button" class="primary-button" [disabled]="isLoading"
              (click)="onNextClicked($event)">Volgende</button>

          </div>

        </div>

        <div class="step" id="update-meta-step" *ngIf="currentStepId  == 'update-meta-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="title">
            <h2>Algemeen info</h2>
          </div>

          <div class="body">
            <div class="form">

              <div class="form-row">
                <label for="name">Naam<span class="required-field-marker">*</span></label>
                <div class="form-control">
                  <input id="name" formControlName="name" type="text">
                </div>
                <div *ngIf="f.name.errors && f.name.errors.required" class="form-field-error-text">
                  Dit is een verplicht veld
                </div>
              </div>

              <div class="form-row">
                <label for="file-description">Omschrijving<span class="required-field-marker">*</span></label>
                <div class="form-control">
                  <input id="file-description" formControlName="fileDescription" type="text">
                </div>
                <div *ngIf="f.fileDescription.errors && f.fileDescription.errors.required"
                  class="form-field-error-text">
                  Dit is een verplicht veld
                </div>
              </div>

            </div>
          </div>

          <div class="footer">
            <button class="optional-button" [disabled]="isLoading" (click)='onCancelClicked($event)'>Annuleren</button>
            <button class="primary-button" [disabled]="isLoading" (click)="onNextClicked($event)">Volgende</button>
          </div>

        </div>

        <div class="confirmation-step" id="confirmation-step" *ngIf="currentStepId == 'confirmation-step'">

          <div class="messages">
            <app-user-messages></app-user-messages>
          </div>

          <div class="footer">
            <button class="primary-button" [disabled]="isLoading" (click)='onCancelClicked($event)'>Close</button>
          </div>

        </div>

      </div>

    </form>

  </div>

</div>
