<div class="right-content-container">  
  <app-bread-crumbs *ngIf="isLoaded" [breadcrumbs]="breadcrumbs"></app-bread-crumbs>  
 
  <div *ngIf="!isLoaded">
    <ngx-skeleton-loader appearance="line" count="4" [theme]="{height: '24px'}"></ngx-skeleton-loader>
  </div>

  <alert></alert>

  <div class="details-table mt-16" *ngIf="isLoaded">
    <table class="mergeFunctionTable">
      <tbody>
        <tr>
          <td class="label"><strong>{{ 'labels.module' | translate }}:</strong></td>
          <td class="value">{{ moduleName }}</td>
        </tr>
        <tr>
          <td class="label"><strong>{{ 'labels.merge_function' | translate }}:</strong></td>
          <td class="value">{{ mergeFunction.name }}</td>
        </tr>
        <tr>
          <td class="label"><strong>{{ 'labels.merge_function_description' | translate }}:</strong></td>
          <td class="value">{{ mergeFunction.description }}</td>
        </tr>
        <tr>
          <td class="label"><strong>{{ 'labels.document_type_personnellfile' | translate }}:</strong></td>
          <td class="value">
            <select *ngIf="!categoriesNotAvailable" matNativeControl (change)="onCategoryChange($event)" class="dropDown"
              [(ngModel)]="selectedCategory">
              <option *ngFor="let category of categories" [value]="category.name">
                {{ category.name }}
              </option>
            </select>
            <span *ngIf="categoriesNotAvailable">{{selectedCategory}}&nbsp;&nbsp;({{ 'messages.change_not_possible' | translate }})</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div> 

  <div class="table" style="padding-bottom: 5rem;">

    <div class="table-header">
      <ul *ngIf="isLoaded" class="nowrap">
        <li class="btnAndSlider">
          <button (click)="onNewTemplateClicked($event)" class="editButton3">{{'buttons.new_template' | translate}}</button>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <label class="switch">
            <input type="checkbox" [(ngModel)]="showFileFormats" (change)="onSliderChange($event)">
            <span class="slider round"></span>
          </label><label class="formatenLabel">{{'labels.file_types' | translate}}</label>
        </li>
        <li id="searchBox">
          <app-search-field [searchText]="searchText" (textChanged)="onSearchTextChanged($event)"></app-search-field>
        </li>
      </ul>
      <div *ngIf="!isLoaded">
        <ngx-skeleton-loader appearance="line" count="1" [theme]="{height: '24px'}"></ngx-skeleton-loader>
      </div>

      <div [class.displaynone]="!showFileFormats" class="table-body skeleton skeleton-text" id="mergeFunctionFormats">
        <p style="font-size: 1.4rem; font-weight: bold; margin-top: 2rem;">{{'labels.file_types' | translate}}</p>
        <br>
        <table mat-table [dataSource]="documentFormats" class="list-items skeleton">

          <ng-container matColumnDef="extension">
            <th mat-header-cell *matHeaderCellDef >{{'table_headers.file_type' | translate}}</th>
            <td mat-cell *matCellDef="let element2">{{element2.extension}}</td>
          </ng-container>

          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef>{{'table_headers.description' | translate}}</th>
            <td mat-cell *matCellDef="let element2">{{element2.description}}
          </ng-container>

          <ng-container matColumnDef="isActive">
            <th mat-header-cell *matHeaderCellDef>{{'table_headers.active' | translate}}</th>
            <td mat-cell *matCellDef="let element2">
              <mat-checkbox class="example-margin" [checked]="element2.isActive" [disabled]="element2.isDefault"
              (change)="setFileFormatActive(element2, $event.checked)"></mat-checkbox>              
          </ng-container>

          <ng-container matColumnDef="isDefault">
            <th mat-header-cell *matHeaderCellDef>{{'table_headers.default' | translate}}</th>
            <td mat-cell *matCellDef="let element2">             
              <mat-radio-button [checked]="element2.isDefault" class="example-radio-button"
                (click)="$event.stopPropagation()" (change)="onFileFormatDefaultChange(element2)">
              </mat-radio-button>              
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsForFileFormats"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsForFileFormats;"></tr>
        </table>

        <div class="empty-table" [class.hidden]="documentFormats?.length > 0" *ngIf="isLoaded">
        </div>

        <div class="empty-table" [class.hidden]="documentFormats?.length > 0" *ngIf="!isLoaded">
        </div>

      </div>
    </div>

    <div class="table-body" id="mergeFunctionDetails">
      <p class="panel-header">{{globalMode ? ('headers.global_templates' | translate) : ('headers.templates' | translate)}}</p>
      <div *ngIf="defaultTemplate" [class.hidden]="dataPage.rows.length == 0" class="default-template">
        <div class="default-template-item nowrap"><span class="item-label">{{'labels.default_template' | translate}}:</span>{{defaultTemplate.name}}</div>                
        <div class="default-template-last-item">
          <button (click)="onSelectRowClicked(defaultTemplate)" class="editButton">{{'buttons.open' | translate}}</button>&nbsp;
          <button (click)="resetDefaultTemplate()" class="editButton">{{'buttons.undo_default' | translate}}</button>
        </div>
      </div>
      <div *ngIf="!defaultTemplate" class="default-template" [class.hidden]="dataPage.rows.length == 0">
        <div class="default-template-item"><span class="item-label">{{'labels.default_template' | translate}}:</span>{{'messages.no_default_template' | translate}}</div>
      </div>      
      <table [class.displaynone]="dataPage.rows.length == 0 || !isLoaded" mat-table matSort [dataSource]="dataPage.rows" class="list-items">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'table_headers.title' | translate}}</th>
          <td class="text-max-100" mat-cell *matCellDef="let element">{{element.name}}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'table_headers.description' | translate}}</th>
          <td class="text-max-100" mat-cell *matCellDef="let element">{{element.description}}</td>
        </ng-container>

        <ng-container matColumnDef="isActive" >
          <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header>{{'table_headers.active' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="centered width-42">
            <mat-checkbox class="example-margin" [checked]="element.isActive" [disabled]="element.isDefault || (element.isGlobal && !myUser.isSuperUser)" (click)="$event.stopPropagation()"
              (change)="setTemplateActive($event.checked, element)"></mat-checkbox>
        </ng-container>

        <ng-container matColumnDef="isDefault">
          <th class="centered" mat-header-cell *matHeaderCellDef mat-sort-header>{{'table_headers.default' | translate}}</th>
          <td mat-cell *matCellDef="let element" class="centered width-200">
            <button *ngIf="!element.isDefault && (!element.isGlobal || myUser.isSuperUser)"  (click)="$event.stopPropagation()" (click)="setDefaultTemplate(element)" class="editButton">{{'buttons.make_default' | translate}}</button>
            <span *ngIf="element.isDefault">{{'labels.is_default' | translate}}</span>
        </ng-container>

        <ng-container matColumnDef="bewerken">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="buttons-cell centered width-130">
            <button *ngIf="!element.isGlobal || myUser.isSuperUser" (click)="$event.stopPropagation()" (click)="onEditTemplateClicked($event, element)" class="editButton">{{'buttons.edit' | translate}}</button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr #tableRow mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected]='row.isSelected' (click)="onSelectRowClicked(row)"></tr>
      </table>
      <div *ngIf="!isLoaded">        
        <ngx-skeleton-loader appearance="line" count="8" [theme]="{height: '32px'}"></ngx-skeleton-loader>
      </div>
      <div class="empty-table" [class.displaynone]="dataPage.rows.length > 0" *ngIf="isLoaded">
        {{'messages.no_templates_found' | translate}}
      </div>
      <app-paginator *ngIf="isLoaded" [totalPages]="dataPage.totalPages" [pageIndex]="pageIndex" (pageIndexChanged)="onPageIndexChanged($event)" class="paginator">
      </app-paginator>
    </div>
    <br>
  </div>
</div>