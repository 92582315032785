import { Injectable } from "@angular/core";
import { IJob } from "src/app/authentication/interfaces/IJob";
import { MergeFunctionOvm } from "src/app/types/dataModels/MergeFunctionOvm";
import { RaetTemplatesApiClient } from "../api-clients/RaetTemplatesApiClient";

@Injectable({ providedIn: 'root' })
export class GetRaetMergeFunctionsJob implements IJob<boolean, MergeFunctionOvm[]> {

  constructor(private raetTemplatesApiClient: RaetTemplatesApiClient) { }

  async execute(forceRefresh: boolean): Promise<MergeFunctionOvm[]> {
    console.info('GetRaetMergeFunctionsJob...');
    const mergeFunctionsOdm = await this.raetTemplatesApiClient.getRaetMergeFunctions(forceRefresh);
    
    const mergeFunctionsOvm: MergeFunctionOvm[] = mergeFunctionsOdm.mergeFunctions.map(data => {
      const mergeFunction = new MergeFunctionOvm();
      return Object.assign(mergeFunction, data);
    });
    
    return mergeFunctionsOvm;
  }
}
