import { EditorInfo } from '../types/helpers/EditorInfo';
import { ITextControlJob } from '../interfaces/ITextControlJob';
import { TextControlJobService } from '../services/TextControlJobService';
import { Injectable } from '@angular/core';
import { ITextControl } from '../interfaces/ITextControl';
import { VoidArg } from 'src/app/types/helpers/VoidArg';

@Injectable({ providedIn: 'root' })
class GetEditorInfoJob implements ITextControlJob<VoidArg, EditorInfo> {

  constructor() { }

  getCanPrint(textControl: ITextControl): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      textControl.instance.getCanPrint((d: boolean) => resolve(d), error => reject(error));
    });
  }

  async execute(textControl: ITextControl, arg: VoidArg): Promise<EditorInfo> {
    const result = new EditorInfo();
    result.canPrint = await this.getCanPrint(textControl);
    return result;
  }
}

@Injectable({ providedIn: 'root' })
export class GetEditorInfoCmdlet {

  constructor(private job: GetEditorInfoJob, private jobService: TextControlJobService) { }

  async run(): Promise<EditorInfo> {
    const result = await this.jobService.runJob<GetEditorInfoJob, VoidArg, EditorInfo>(this.job, new VoidArg());
    return result;
  }
}

