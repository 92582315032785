import { Injectable } from '@angular/core';
import { CmdletBase } from 'src/app/common/services/CmdletBase';
import { JobService } from 'src/app/common/services/JobService';
import { ComponentService } from 'src/app/services/ComponentService';
import { TemplateVersionFromTemplateIm } from '../types/TemplateVersionFromTemplateIm';
import { CreateTemplateVersionFromIdJob } from '../jobs/CreateTemplateVersionFromIdJob';

@Injectable({ providedIn: 'root' })
export default class CreateTemplateVersionFromIdCmdlet extends CmdletBase<TemplateVersionFromTemplateIm, any> {

  constructor(private jobService: JobService,
    componentService: ComponentService,
    private createTemplateVersionFromIdJob: CreateTemplateVersionFromIdJob) {
    super(componentService);
  }

  async executeImpl(m: TemplateVersionFromTemplateIm): Promise<any> {
    let result = await this.jobService.runJob<CreateTemplateVersionFromIdJob, TemplateVersionFromTemplateIm, any>(this.createTemplateVersionFromIdJob, m);
    return result;
  }
}
