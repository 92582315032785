import { Injectable } from '@angular/core';
import { IJob } from 'src/app/authentication/interfaces/IJob';
import { FileTypeEnum } from 'src/app/common/types/enums/FileTypeEnum';
import { VoidResult } from 'src/app/types/helpers/VoidResult';
import { TemplateEditorFileModel } from '../types/helpers/TemplateEditorFileModel';
import { TemplatePlaceholderModel } from '../types/helpers/TemplatePlaceholderModel';
import { BaseTemplatesApiClient } from '../api-clients/BaseTemplatesApiClient';
import { BaseTemplateIm } from 'src/app/types/dataModels/BaseTemplateIdm';
import { OwnFile } from 'src/app/types/helpers/OwnFile';


@Injectable({ providedIn: 'root' })
export class CreateBaseTemplateJob implements IJob<TemplateEditorFileModel, VoidResult> {

  constructor(private httpClient: BaseTemplatesApiClient) { }

  async execute(m: TemplateEditorFileModel): Promise<VoidResult> {
    let model = new BaseTemplateIm();
    Object.assign(model, m);
    model.name = m.name;
    var ownFile = new OwnFile();
    ownFile.fileName = m.name;
    ownFile.content = m.content;
    ownFile.contentType = 'application/text-control';
    model.file = ownFile;
    let templateExtension = m.documentType;

    return this.httpClient.createBaseTemplate(model, templateExtension);
  }
}
