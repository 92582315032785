import { Injectable } from "@angular/core";
import { ComponentService } from "src/app/services/ComponentService";
import { CmdletBase } from "src/app/common/services/CmdletBase";
import { JobService } from "src/app/common/services/JobService";
import { UpdateMergeFunctionDocumentFormatJob } from "../jobs/UpdateMergeFunctionDocumentFormatJob";
import { VoidResult } from "src/app/types/helpers/VoidResult";
import { DocumentFormatReq } from "src/app/types/dataModels/DocumentFormatReq";

@Injectable({ providedIn: 'root' })
export class UpdateMergeFunctionDocumentFormatCmdlet extends CmdletBase<DocumentFormatReq, VoidResult> {

  constructor(private jobService: JobService, private job: UpdateMergeFunctionDocumentFormatJob, componentService: ComponentService) {
    super(componentService);
  }

  protected async executeImpl(arg: DocumentFormatReq): Promise<VoidResult> {
    await this.jobService.runJob<UpdateMergeFunctionDocumentFormatJob, DocumentFormatReq, VoidResult>(this.job, arg);
    return new VoidResult();
  }
}
