import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-search-field',
  templateUrl: './search-field.component.html',
  styleUrls: ['./search-field.component.scss']
})
export class SearchFieldComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void { }

  @Input()
  searchText: string;

  @Output()
  readonly textChanged: EventEmitter<string> = new EventEmitter<string>();

  onSearchTextChanged() {
    this.textChanged.emit(this.searchText);
  }

  onSearchTextCleared() {
    this.searchText = null;
    this.onSearchTextChanged();
  }
}
