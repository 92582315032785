import { Component } from '@angular/core';

@Component({
  selector: 'app-merge-function-dropdown',
  templateUrl: './merge-function-dropdown.component.html',
  styleUrls: ['./merge-function-dropdown.component.scss']
})
export class MergeFunctionDropdownComponent {

}
